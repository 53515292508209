import React from 'react'
import { Input, Button } from 'reactstrap'
import {
	X,
	Phone,
	Video,
	MoreVertical,
	Paperclip,
	Mic,
	Send,
} from 'react-feather'

const phoneScreenStyle = {
	width: '280px',
	height: '500px',
	border: '10px solid black',
	borderRadius: '30px',
	overflow: 'hidden',
	backgroundColor: 'white',
	display: 'flex',
	flexDirection: 'column',
}

const chatHeaderStyle = {
	backgroundColor: '#8e44ad',
	color: 'white',
	padding: '10px',
}

const headerContentStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}

const avatarStyle = {
	width: '30px',
	height: '30px',
	borderRadius: '50%',
	backgroundColor: '#f1c40f',
	marginRight: '10px',
}

const nameIconsContainerStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '100%',
}

const iconContainerStyle = {
	display: 'flex',
	alignItems: 'center',
}

const messageBubbleStyle = {
	backgroundColor: '#f0e6fa',
	borderRadius: '20px',
	padding: '10px',
	margin: '10px',
	maxWidth: '80%',
	alignSelf: 'flex-start',
	marginLeft: 'auto',
}

const inputAreaStyle = {
	display: 'flex',
	alignItems: 'center',
	padding: '10px',
	borderTop: '1px solid #e0e0e0',
	marginTop: 'auto',
}

const DynamicMessageModal = ({ message }) => {
	return (
		<div className="mx-auto" style={phoneScreenStyle}>
			<div style={chatHeaderStyle}>
				<div style={headerContentStyle}>
					<X size={20} />
					<div style={nameIconsContainerStyle}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={avatarStyle}></div>
							<div>Quiche</div>
						</div>
						<div style={iconContainerStyle}>
							<Phone size={20} style={{ marginRight: '8px' }} />
							<Video size={20} style={{ marginRight: '8px' }} />
							<MoreVertical size={20} />
						</div>
					</div>
				</div>
			</div>
			<div style={{ flexGrow: 1 }}></div>
			<div>
				<div className="text-center text-muted">Just Now</div>
				<div style={messageBubbleStyle}>{message}</div>
			</div>
			<div style={inputAreaStyle}>
				<Paperclip size={20} style={{ marginRight: '8px' }} />
				<Input placeholder="Type a Message..." className="border-0" />
				<Mic size={20} style={{ margin: '0 8px' }} />
				<Button
					color="primary"
					className="rounded-circle p-0"
					style={{ width: '40px', height: '40px' }}
				>
					<Send size={20} />
				</Button>
			</div>
		</div>
	)
}

DynamicMessageModal.defaultProps = {
	message: 'Default message',
}

export default DynamicMessageModal

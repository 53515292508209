// /* eslint-disable react-hooks/rules-of-hooks */

// import React, { useContext, useMemo } from "react";
// import { Navigate, Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./App.css";
// import "./all.min.css";
// import RestrictLoginPage from "./RestrictLoginPage";
// import routes from "./pages/routes";
// import AuthLayout from "./AuthLayout";
// import Page from "./pages/[slug]";
// import { AuthContxt } from "./store/authContxt";
// import { USER_TYPE } from "./utils/enum";
// import withOnboarding from "./components/wrapper/withOnboarding";

// const checkUserAuthentication = () => {
//   const local = localStorage.getItem("user_details");
//   return JSON.parse(local);
// };

// function App() {
//   const { profile } = useContext(AuthContxt);
//   const isAuthenticated = checkUserAuthentication();

//   // Determine the routes based on the user's role
//   const ROUTES = useMemo(
//     () => (isAuthenticated?.isAdmin || profile?.isAdmin) ? routes["adminRoutes"] : routes["routes"],
//     [isAuthenticated, profile]
//   );

//   return (
//     <>
//       <ToastContainer />
//       <Routes>
//         <Route path="/:slug" element={<Page />} />
//         <Route path="divx.cc/:slug" element={<Page />} />

//         {/* No Auth Routes */}
//         <Route element={<RestrictLoginPage />}>
//           {routes["noAuthRoutes"].map((route, index) => (
//             <Route key={index} path={route.path} element={<route.component />} />
//           ))}
//         </Route>

//         {/* Authenticated Routes with Onboarding Logic */}
//         <Route element={<AuthLayout />}>
//           {ROUTES.map((route, index) => {
//             // Redirect user-settings based on user type
//             if (route.path === "/user-settings" && profile?.userType === USER_TYPE.USER) {
//               return (
//                 <Route key={index} path={route.path} element={<Navigate to="/dashboard" />} />
//               );
//             }

//             // Wrap each route component with onboarding logic
//             const ComponentWithOnboarding = withOnboarding(route.component);

//             return (
//               <Route
//                 key={index}
//                 path={route.path}
//                 element={
//                   <div className="grid">
//                     <ComponentWithOnboarding {...route?.props} />
//                   </div>
//                 }
//               />
//             );
//           })}
//         </Route>
//       </Routes>
//     </>
//   );
// }

// export default App;
import React, { useContext, useEffect } from 'react'

import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useNavigate,
} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import './all.min.css'
import RestrictLoginPage from './RestrictLoginPage'
import routes from './pages/routes'
import AuthLayout from './AuthLayout'
import Page from './pages/[slug]'
import { AuthContxt } from './store/authContxt'
import { USER_TYPE } from './utils/enum'

const checkUserAuthentication = () => {
	let localData = localStorage.getItem('user_details')
	let local = localData
	return JSON.parse(local)
}

function App() {
	const { profile } = useContext(AuthContxt)
	const isAuthenticated = checkUserAuthentication()
	const navigate = useNavigate()
	const ROUTES =
		isAuthenticated?.isAdmin || profile?.isAdmin
			? routes['adminRoutes']
			: routes['routes']

	return (
		<>
			<ToastContainer />
			<Routes>
				<Route path="/:slug" element={<Page />} />
				<Route path="divx.cc/:slug" element={<Page />} />
				<Route element={<RestrictLoginPage />}>
					{routes['noAuthRoutes'].map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								element={<route.component />}
							/>
						)
					})}
				</Route>
				{/* <Route element={<AuthLayout />}>
          {ROUTES.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <div className="grid">
                    <route.component />
                  </div>
                }
              />
            );
          })}
        </Route> */}

				<Route element={<AuthLayout />}>
					{ROUTES.map((route, index) => {
						// console.log(route.props)
						if (
							route.path === '/user-settings' &&
							profile?.userType === USER_TYPE.USER
						) {
							return (
								<Route
									key={index}
									path={route.path}
									element={<Navigate to="/dashboard" />}
								/>
							)
						}
						return (
							<Route
								key={index}
								path={route.path}
								element={
									<div className="grid">
										<route.component {...route?.props} />
									</div>
								}
							/>
						)
					})}
				</Route>
			</Routes>
		</>
	)
}

export default App

import React, { useContext, useState } from 'react'
import { FormGroup, Label, Input, Col, Button, ButtonGroup } from 'reactstrap'
import { ShortLinkContxt } from '../shortLinkContxt'

import Layout0 from '../../link-in-bio/create-page/layout0'
import Layout1 from '../../link-in-bio/create-page/layout1'
import Layout2 from '../../link-in-bio/create-page/layout2'
import Layout3 from '../../link-in-bio/create-page/layout3'
import Layout4 from '../../link-in-bio/create-page/layout4'
import dayjs from 'dayjs'

const NoBioPage = () => {
	return (
		<div
			className="mt-5  h-100 text-center w-100 me-0 ms-0 row  align-items-center rounded-2 p-5 py-2"
			style={{ background: '#edf5ff' }}
		>
			<div className="col-lg-4 col-12">
				<img
					className="rounded-2 w-100"
					src="https://divsly.com/assets/media/illustrations/link-in-bio.svg"
					alt="Link in bio page"
				/>
			</div>
			<div className="col-lg-8 col-12 text-start">
				<h3 className="pt-3 fs-6">Create a Link-in-bio Page</h3>
				<p>Display your most important links on one simple page.</p>
				<span className="text-danger">
					<i className="ki-duotone ki-information fs-6 text-danger">
						<i className="path1"></i>
						<i className="path2"></i>
						<i className="path3"></i>
					</i>
				</span>
			</div>
		</div>
	)
}

const BioPages = ({ form }) => {
	const { setValue } = form
	const { bioPages } = useContext(ShortLinkContxt)
	const [isLinkInBio, setIsLinkInBio] = useState(false)
	const [pageSelected, setPageSelected] = useState(null)

	const getTemplate = (template, values) => {
		const templateMapper = {
			0: <Layout0 values={values} />,
			1: <Layout1 values={values} />,
			2: <Layout2 values={values} />,
			3: <Layout3 values={values} />,
			4: <Layout4 values={values} />,
		}

		return templateMapper[template]
	}

	return (
		<Col md={6}>
			<FormGroup>
				<Label className="d-flex flex-stack mb-5 w-100 border border-dotted border-primary cursor-pointer rounded-1 p-5 min-h-95px">
					<span className="d-flex align-items-center me-2">
						<span className="symbol symbol-50px me-6">
							<span className="symbol-label bg-light-primary">
								<i class="fa-solid fa-square-poll-horizontal text-primary opacity-75 fs-1"></i>
							</span>
						</span>

						<span className="d-flex flex-column">
							<span className="fw-bold fs-5">Link-in-bio</span>
							<span className="fs-7 text-muted">
								Add this link to your Link-in-bio Page.
							</span>
						</span>
					</span>
					<span className="form-check form-check-custom form-check-info form-check-solid">
						<Input
							className="form-check-input"
							type="checkbox"
							id="qr-gen-checkbox"
							onChange={() => {
								setIsLinkInBio(!isLinkInBio)
								setValue('isLinkInBio', !isLinkInBio)
							}}
						/>
					</span>
				</Label>
				{isLinkInBio ? (
					bioPages?.length > 0 ? (
						<ButtonGroup
							className="d-flex flex-column border border-primary border-opacity-25"
							style={{
								overflow: 'scroll',
								maxHeight: '145px',
							}}
						>
							{bioPages?.map((page) => {
								return (
									<Button
										outline
										onClick={() => {
											setPageSelected(page.id)
											setValue('bioPage', page.id)
										}}
										active={pageSelected === page.id}
										style={{
											marginBottom: '5px',
										}}
									>
										<div className="d-flex align-items-center position-relative overflow-hidden">
											<div
												key={page?.id}
												style={{ maxHeight: '110px' }}
												className="preview-container display-block"
											>
												<div className="frame-preview">
													{getTemplate(page?.template, page)}
												</div>
											</div>
											<div
												className="d-flex flex-column justify-content-between ps-lg-8 ps-5 pt-0 pb-0 pe-0"
												style={{ height: '4rem' }}
											>
												<div>
													<div className="d-flex justify-content-between">
														<h3 className="card-title text-dark text-wrap-oneline autowidthmobile fs-lg-20px mb-3">{`${page.pageDomain}/${page.pageLink}`}</h3>
													</div>
													<div className="d-flex align-items-center">
														<i
															className="fal fa-calendar fs-5 me-2"
															style={{ color: '#c0c4db' }}
														></i>
														<span className="fs-8">
															{dayjs(page.createdAt).format('MMM DD, YYYY')}
														</span>
													</div>
												</div>
											</div>
										</div>
									</Button>
								)
							})}
						</ButtonGroup>
					) : (
						<NoBioPage />
					)
				) : null}
			</FormGroup>
		</Col>
	)
}

export default BioPages

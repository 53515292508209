import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { GoogleOAuthProvider } from '@react-oauth/google'
import {
	AuthContxtProvider,
	AuthProviderWithOnboarding,
} from './store/authContxt'
import UserSettingsContxtProvider from './components/user-settings/userSettingContxt'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Router>
		<GoogleOAuthProvider
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
		>
			<AuthContxtProvider>
				<AuthProviderWithOnboarding>
					<React.StrictMode>
						<UserSettingsContxtProvider>
							<CookiesProvider defaultSetOptions={{ path: '/' }}>
								<App />
							</CookiesProvider>
						</UserSettingsContxtProvider>
					</React.StrictMode>
				</AuthProviderWithOnboarding>
			</AuthContxtProvider>
		</GoogleOAuthProvider>
	</Router>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useEffect, useState } from 'react'
import DoughnutChart from './apex-charts/donutChart'
import DateRangeSelector from './dateRange'
import dayjs from 'dayjs'
import AdminAPI from '../../services/http/admin'
import convertToK from '../../utils/convertToK'

const Subscriptions = () => {
	const [subscriptions, setSubsscriptions] = useState({})

	const [openDatePicker, setOpenDatePicker] = useState(false)
	const [selectedDateRange, setSelectedDateRange] = useState({
		startDate: new Date().setDate(new Date().getDate() - 30),
		endDate: new Date(),
		key: 'selection',
	})

	const fetchSubscriptions = async (
		startDate = new Date().setDate(new Date().getDate() - 30),
		endDate = new Date()
	) => {
		try {
			const response = await AdminAPI.getSubscriptions({ startDate, endDate })
			if (!response?.data?.error) {
				setSubsscriptions(response?.data?.data)
			}
		} catch (e) {
			console.log('Error while fetching subscriptions: ', e)
		}
	}

	useEffect(() => {
		fetchSubscriptions()
	}, [])

	const values = Object.values(subscriptions)
	const totalSubscriptions = values?.reduce((total, val) => val + total, 0)

	return (
		<div
			className="card card-flush"
			style={{ borderRadius: '15px 15px 0px 0px' }}
		>
			<div className="card-header mt-6">
				<div className="card-title flex-column">
					<h3 className="fw-bold mb-1">Subscriptions</h3>
				</div>
				<div class="card-toolbar">
					<div className="mt-5 mt-lg-0">
						<div
							onClick={() => setOpenDatePicker(!openDatePicker)}
							class="form-control form-control-solid w-lg-250px justify-content-between"
							style={{
								position: 'relative',
							}}
						>
							<span>
								{dayjs(selectedDateRange.startDate).format('MM/DD/YYYY')}
							</span>
							<span>{` - `}</span>
							<span>
								{dayjs(selectedDateRange.endDate).format('MM/DD/YYYY')}
							</span>
						</div>
						{openDatePicker ? (
							<div
								style={{
									position: 'absolute',
									zIndex: 99999,
									right: 0,
								}}
							>
								<DateRangeSelector
									analyticsHandler={fetchSubscriptions}
									toggleHandler={setOpenDatePicker}
									selectedDateRange={selectedDateRange}
									setSelectedDateRange={setSelectedDateRange}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className="card-body p-9 pt-5">
				<div className="d-flex flex-wrap">
					<div className="position-relative d-flex flex-center h-175px w-175px me-15 mb-7">
						<div className="position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center">
							<span className="fs-2qx fw-bold">
								{convertToK(totalSubscriptions || 0)}
							</span>
							<span className="fs-6 fw-semibold text-gray-500">Total</span>
						</div>
						<DoughnutChart data={values || []} />
					</div>
					<div className="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">
						<div className="d-flex fs-6 fw-semibold align-items-center mb-3">
							<div className="bullet bg-primary me-3"></div>
							<div className="text-gray-500">Super</div>
							<div className="ms-auto fw-bold text-gray-700">
								{subscriptions?.super || 0}
							</div>
						</div>
						<div className="d-flex fs-6 fw-semibold align-items-center mb-3">
							<div className="bullet bg-success me-3"></div>
							<div className="text-gray-500">Standard</div>
							<div className="ms-auto fw-bold text-gray-700">
								{subscriptions?.standard || 0}
							</div>
						</div>
						<div className="d-flex fs-6 fw-semibold align-items-center">
							<div className="bullet bg-info me-3"></div>
							<div className="text-gray-500">Starter</div>
							<div className="ms-auto fw-bold text-gray-700">
								{subscriptions?.starter || 0}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Subscriptions

import { Nav, NavItem, NavLink } from 'reactstrap'

const Tabs = ({ handler, tab }) => {
	return (
		<div className="card-header px-0 mt-lg-5 w-100">
			<div className="card-title mt-0 me-0 mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0 w-100">
				<Nav
					tabs
					className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 w-100"
				>
					<NavItem className="w-50">
						<NavLink
							onClick={() => {
								handler(1)
							}}
							className={`nav-link w-100 me-lg-4 me-3 btn text-dark rounded-1 border border-dark border-opacity-10 fs-lg-3 fs-6 fw-bold qrcate ${
								tab === 1 ? 'activeqrtab' : ''
							}`}
							id="qrcategory"
						>
							<i className="far fa-qrcode fs-4 fw-bold me-4 mobile-hide"></i>
							QR Codes
						</NavLink>
					</NavItem>
					<NavItem className="w-50">
						<NavLink
							onClick={() => {
								handler(2)
							}}
							className={`nav-link w-100 ms-0 ms-lg-4 btn text-dark rounded-1 border border-dark border-opacity-10 fs-lg-3 fs-6 fw-bold active ${
								tab === 2 ? 'activeqrtab' : ''
							}`}
							id="custcategory"
						>
							<i className="far fa-palette fs-4 fw-bold me-4 mobile-hide"></i>
							Customization
						</NavLink>
					</NavItem>
				</Nav>
			</div>
		</div>
	)
}

export default Tabs

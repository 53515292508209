import httpMethods from '..'

const CustomLinksAPI = {
	getCustomLinks: async (token) => {
		return httpMethods
			.get(`/custom-domain`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	getConnectedCustomLinks: async (token) => {
		return httpMethods
			.get(`/connected-custom-domain`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	deleteCustomLink: async (token, id) => {
		return httpMethods
			.delete(`/custom-domain/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	createCustomLink: async (token, values) => {
		return httpMethods
			.post(`/custom-domain`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	verifyCustomLink: async (token) => {
		return httpMethods
			.get(`/check-custom-domain`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
}

export default CustomLinksAPI

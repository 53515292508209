import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Spinner,
	FormGroup,
	Label,
	Input,
} from 'reactstrap'
import { Controller, useForm } from 'react-hook-form'
import { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import UserSettingsApi from '../../../services/http/userSettings'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const CARD_ELEMENT_OPTIONS = {
	hidePostalCode: true,
	style: {
		base: {
			color: '#32325d',
			fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
}

const newUserValidation = yup.object().shape({
	cardName: yup.string().required('Card holder name is required!'),
})

const CardForm = ({ toggleHandler, cardHandler }) => {
	const [message, setMessage] = useState()
	const [loading, setLoading] = useState(false)

	const stripe = useStripe()
	const elements = useElements()

	const form = useForm({
		resolver: yupResolver(newUserValidation),
		defaultValues: {
			cardName: '',
		},
	})
	const {
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = form

	const resetErrorMessage = () => {
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const submitHandler = async (data) => {
		if (data) {
			try {
				setLoading(true)
				if (!stripe || !elements) {
					setLoading(false)
					return
				}
				const cardElement = elements.getElement(CardElement)
				const result = await stripe.createToken(cardElement, {
					name: data?.cardName,
				})

				if (result.error) {
					setLoading(false)
					setMessage(result.error.message)
					resetErrorMessage()
				} else {
					const response = await UserSettingsApi.createCard({
						token: result?.token?.id,
					})
					if (response?.data?.error) {
						setMessage(response.data?.message || 'Something went wrong')
						setLoading(false)
					} else {
						cardHandler('add', response?.data?.data)
						setLoading(false)
						reset()
						toggleHandler()
					}
				}
			} catch (e) {
				setMessage(e?.response?.data?.message || 'Something went wrong')
				setLoading(false)
				resetErrorMessage()
				console.log('Error while creating address: ', e)
			}
		}
	}

	const cardElementOptions = CARD_ELEMENT_OPTIONS

	return (
		<Modal
			className="modal-dialog-centered"
			isOpen={true}
			toggle={toggleHandler}
		>
			<Form onSubmit={handleSubmit(submitHandler)}>
				<ModalHeader toggle={toggleHandler}>
					<h2>{'Add New Card'}</h2>
					<div className="btn btn-sm btn-icon btn-active-color-info">
						<i className="fa-solid fa-x fs-1"></i>
					</div>
				</ModalHeader>
				<ModalBody className="px-10">
					<div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
						<FormGroup>
							<Label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2 required">
								Name on Card
							</Label>
							<Controller
								name={'cardName'}
								control={control}
								render={({ field: { onBlur, value, onChange } }) => (
									<Input
										value={value}
										type="text"
										className="form-control h-50px border border-gray-250"
										placeholder="Card Holder Name"
										onChange={onChange}
										onBlur={onBlur}
									/>
								)}
							/>
						</FormGroup>
						{errors.cardName ? (
							<div style={{ textAlign: 'end' }}>
								<span className="error-text text-danger">
									{errors.cardName.message}
								</span>
							</div>
						) : null}
					</div>
					<CardElement options={cardElementOptions} />
				</ModalBody>
				<ModalFooter className="px-10 pb-10">
					<div
						style={{
							textAlign: 'start',
						}}
					>
						{message ? (
							<span className="text-danger fs-6 fw-bold">{message}</span>
						) : null}
					</div>
					<div className="flex-end">
						<button
							type="reset"
							onClick={toggleHandler}
							className="btn btn-light w-lg-150px me-3 border rounded-1"
						>
							Discard
						</button>
						<button type="submit" className="btn btn-info w-lg-150px rounded-1">
							{loading ? <Spinner /> : 'Save'}
						</button>
					</div>
				</ModalFooter>
			</Form>
		</Modal>
	)
}

export default CardForm

import {
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
} from 'reactstrap'
import imageDownloadHandler from '../../../utils/imageDownLoadHandler'
import imageCopyHandler from '../../../utils/imageCopyHandler'
import { useContext, useEffect, useState } from 'react'
import Copy from '../../common/copy'
import { options } from '../../common/qrCode'
import QrCodesAPI from '../../../services/http/qrCodes'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import { LinkInBioContxt } from '../linkInBioContxt'

const QrCreatedPage = ({ toggleHandler, tabsHandler }) => {
	const { bioPageForm } = useContext(LinkInBioContxt)
	const { watch, setValue } = bioPageForm
	const { pageDomain, pageLink, qr = '' } = watch()
	const [qrImg, setQrImg] = useState('')

	const [isCopied, setIsCopied] = useState(false)

	const getPageLink = () => {
		return `${pageDomain}${
			convertToURLFriendly(pageLink) ? `/${convertToURLFriendly(pageLink)}` : ''
		}`
	}

	const copyHandler = () => {
		setIsCopied(true)
		imageCopyHandler({ qr })
		setTimeout(() => {
			setIsCopied(false)
		}, [1 * 1000])
	}

	const updatedValues = {
		...watch(),
		brandedDomain: pageDomain,
		slashTag: convertToURLFriendly(pageLink),
		title: convertToURLFriendly(pageLink),
		qrLogoId: process.env.REACT_APP_QR_LOGO_ID,
	}

	const createQrCodeHandler = () => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			QrCodesAPI.generateQrCode(auth_token, {
				payload: {
					...options,
					config: {
						...options?.config,
						logo: process.env.REACT_APP_QR_LOGO_ID,
					},
					data: `https://${pageDomain}/${convertToURLFriendly(pageLink)}`,
				},
			})
				.then(({ data }) => {
					setQrImg(data.data)
					setValue('qr', data.data)
				})
				.catch((error) => {
					console.log('Error while creating qr code: ', error)
				})
		} catch (e) {
			console.log('Error while creating qr code: ', e)
		}
	}

	useEffect(() => {
		createQrCodeHandler()
	}, [pageDomain, pageLink])

	return (
		<Modal
			isOpen={true}
			className="add_new_link_body w-lg-450px moblielinkbioqr mobile-modal-view"
			size="lg"
			toggle={toggleHandler}
		>
			<ModalHeader className="linkinbio-qr-title" closeButton>
				<h2 className="fw-bold px-lg-0 fs-3 mb-0">{getPageLink()}</h2>
				<i onClick={toggleHandler} className="fa-light fa-xmark fs-3"></i>
			</ModalHeader>
			<ModalBody
				className="p-0"
				style={{
					backgroundColor: '#F7F5FD',
					borderRadius: '0px 0px 15px 15px',
				}}
			>
				<div
					className="pb-lg-10 pb-5 p-lg-5"
					style={{
						top: '50px',
					}}
				>
					<div
						className="d-flex justify-content-between align-items-center border-bottom p-5 pb-lg-10"
						style={{ top: '0px' }}
					>
						<h5 className="fs-3 mb-0 fw-bold ">Your QR Code</h5>
						<Button
							disabled={isCopied}
							onClick={copyHandler}
							type="button"
							className="btn btn-light-info rounded-1 border px-3 py-1 border-info border-opacity-25"
						>
							{isCopied ? 'Copied!' : 'Copy'}
						</Button>
					</div>
					<div className="mx-auto">
						<div
							className="d-flex justify-content-center"
							style={{ position: 'relative' }}
						>
							<div style={{ textAlign: 'center' }} className="w-100 px-5">
								{qrImg ? (
									<img
										className="w-100 border border-gray-300"
										src={qrImg || '/default-qr.svg'}
										alt="Qr code"
									/>
								) : null}
							</div>
						</div>
					</div>
					<div className="pt-2 border-top">
						<p className="fs-4 fw-bold mt-3 mb-5 text-center">
							{getPageLink()}
							<span className="ms-1 cursor-pointer">
								<Copy copyData={getPageLink()} />
							</span>
						</p>
					</div>
					<div className="d-flex px-5 mx-auto justify-content-center">
						<UncontrolledDropdown
							className="dropdown-header flex-grow-1"
							direction="bottom"
						>
							<DropdownToggle className="rounded-1 btn btn-light-info border border-info border-opacity-25 w-100">
								Download<i className="fa-solid fa-chevron-down ms-3"></i>
							</DropdownToggle>
							<DropdownMenu className="w-100">
								<DropdownItem
									onClick={() =>
										imageDownloadHandler(updatedValues, 'png', false)
									}
								>
									Download PNG
								</DropdownItem>
								<DropdownItem
									onClick={() =>
										imageDownloadHandler(updatedValues, 'jpg', false)
									}
								>
									Download JPG
								</DropdownItem>
								<DropdownItem
									onClick={() =>
										imageDownloadHandler(updatedValues, 'svg', false)
									}
								>
									Download SVG
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default QrCreatedPage

import Subscription from '../../components/subscription'
import PageWrapper from '../../components/wrapper/pageWrapper'

const Page = () => {
	return (
		<PageWrapper>
			<div className="px-6 px-lg-10 py-lg-10 py-5 d-flex items-center justify-content-between w-100 align-items-center">
				<h2 className="mb-0 fs-2x">Subscription</h2>
			</div>
			<Subscription />
		</PageWrapper>
	)
}

export default Page

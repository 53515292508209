import SmsCampaignMain from '../components/sms-campaigns/index.jsx'
import SmsCampaignContxtProvider from '../components/sms-campaigns/smsCampaignContxt'

const SmsCampaigns = () => {
	return (
		<SmsCampaignContxtProvider>
			<SmsCampaignMain />
		</SmsCampaignContxtProvider>
	)
}

export default SmsCampaigns

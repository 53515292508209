export const APP_ROUTES = [
	'user-settings',
	'login',
	'signup',
	'forgotpassword',
	'reset-password',
	'verify',
	'admin-dashboard',
	'subscribers',
	'short-links',
	'custom-links',
	'link-in-bio',
	'create-link-in-bio',
	'qr-codes',
	'create-qr-code',
	'subscription',
	'dashboard',
	'contacts',
	'campaigns',
]
export const BLOCKED_KEYWORDS = [
	`xxx`,
	`sex`,
	`porn`,
	`xnxx`,
	`xvideosx`,
	`nude`,
]
export const bulk_upload_application_fields = [
	{
		label: 'First Name',
		name: 'firstName',
	},
	{
		label: 'Last Name',
		name: 'lastName',
	},
	{
		label: 'Email',
		name: 'email',
	},
	{
		label: 'Mobile',
		name: 'mobile',
	},
	{
		label: 'Whatsapp',
		name: 'whatsapp',
	},
	{
		label: 'Job',
		name: 'job',
	},
	{
		label: 'Department',
		name: 'department',
	},
	{
		label: 'City',
		name: 'city',
	},
	{
		label: 'State',
		name: 'state',
	},
	{
		label: 'Country',
		name: 'country',
	},
	{
		label: 'Tags',
		name: 'tags',
	},
] // Application fields

import { useContext, useRef, useState } from 'react'
import Share from './share'
import { Link } from 'react-router-dom'
import DeleteModal from '../../../modals/deleteModal'
import ShortLinksAPI from '../../../services/http/shortLinks'
import { AuthContxt } from '../../../store/authContxt'
import { LinkInBioContxt } from '../linkInBioContxt'

const Links = ({ edittedLinkHandler }) => {
	const { bioPageForm, isEdit } = useContext(LinkInBioContxt)
	const { watch, setValue } = bioPageForm
	const { fetchQuota } = useContext(AuthContxt)
	const { links } = watch()
	const dragItem = useRef()
	const dragOverItem = useRef()
	const [deleteLinkId, setDeleteLinkId] = useState(null)
	const [deleteModal, setDeleteModal] = useState(false)

	const dragStart = (e) => {
		dragItem.current = e.target.id
	}
	const dragEnter = (e) => {
		dragOverItem.current = e.currentTarget.id
	}
	const drop = () => {
		const copyListItems = links
		const dragItemContent = copyListItems[dragItem.current]
		copyListItems.splice(dragItem.current, 1)
		copyListItems.splice(dragOverItem.current, 0, dragItemContent)
		dragItem.current = null
		dragOverItem.current = null
		setValue('links', copyListItems)
	}

	const deleteLinkHandler = (id) => {
		if (id) {
			if (isEdit) {
				const auth_token = localStorage.getItem('auth_token')
				ShortLinksAPI.deleteShortLink(auth_token, id)
					.then((data) => {
						setValue(
							'links',
							links.filter((item) => item.id !== id)
						)
						fetchQuota()
						edittedLinkHandler(null)
						setDeleteLinkId(null)
					})
					.catch((error) => {
						console.log('Error while deleting button: ', error)
						setDeleteLinkId(null)
					})
			} else {
				setValue(
					'links',
					links.filter((item) => item.id !== id)
				)
			}
		}
	}

	const activeLinkHandler = async (id, status) => {
		if (id) {
			setValue(
				'links',
				links.map((item) =>
					item.id === id ? { ...item, isActive: !status } : item
				)
			)
		}
	}

	return (
		<>
			{links?.map((link, index) => {
				const { btnLabel, brandedDomain, slashTag, isActive, lbClicks, id } =
					link
				return (
					<div
						draggable
						onDragStart={(e) => dragStart(e)}
						onDragEnter={(e) => dragEnter(e)}
						onDragEnd={drop}
						className="mt-5"
						key={slashTag}
						id={index}
					>
						<div className="border rounded-1 border-dark border-opacity-10 w-100">
							<div className="d-flex justify-content-between w-100">
								<div className="d-flex w-100">
									<div
										className="d-flex justify-content-center align-items-center border-end"
										style={{ cursor: 'grab' }}
									>
										<i className="fat fa-grip-dots-vertical text-gray-400 p-5 fs-2"></i>
									</div>
									<div className="py-5 ps-5 d-flex align-items-center w-100 justify-content-between mobile-hide-f">
										<h4 className="fs-5 mb-0">{btnLabel}</h4>
										<Link
											to={`https://${brandedDomain}/${slashTag}?r=lb`}
											className="mb-0 fs-5 text-info fw-semibold"
											target="_blank"
										>
											{`${brandedDomain}/${slashTag}`}
										</Link>
										<div className="form-check form-switch form-check-custom form-check-n-dark form-check-success form-check-solid p-5">
											<input
												className="form-check-input border border-dark border-opacity-10"
												style={{ width: '35px', height: '19px' }}
												type="checkbox"
												value={isActive}
												onClick={() => activeLinkHandler(id, isActive)}
												id="flexSwitchChecked"
												checked={isActive && id}
											/>
										</div>
										<div>
											<span className="fs-5 fw-semibold cursor-pointer">
												{lbClicks}
											</span>
										</div>
										<ul className="list-style-none d-flex align-items-center justify-content-start ps-0 me-3 gap-0 mb-0">
											<button
												type="button"
												className="btn px-3 pe-5"
												onClick={() => edittedLinkHandler(link)}
											>
												<span data-bs-toggle="tooltip" title="Edit">
													<i className="fa-light fa-pen-to-square text-gray-700 fs-3"></i>
												</span>
											</button>
											<button type="button" className="btn px-4">
												<Share
													link={`https://${brandedDomain}/${encodeURIComponent(slashTag)}`}
												/>
											</button>
											<button
												type="button"
												className="btn px-3"
												disabled={deleteLinkId === id}
												onClick={() => {
													setDeleteLinkId(id)
													setDeleteModal(true)
												}}
											>
												<i
													className="far fa-trash-can text-danger fs-3"
													data-bs-toggle="tooltip"
													title="Delete"
												></i>
											</button>
										</ul>
									</div>
									<div className="pt-5 pb-2 ps-5 d-flex flex-column align-items-start w-100 desktop-hide-f">
										<h4 className="fs-5 mb-0">{btnLabel}</h4>
										<Link
											to={`https://${brandedDomain}/${slashTag}`}
											className="mb-0 fs-5 text-info fw-semibold"
										>
											{`${brandedDomain}/${slashTag}`}
										</Link>
										<div>
											<span className="fs-5 fw-semibold cursor-pointer d-none">
												{lbClicks}
											</span>
										</div>
										<ul className="list-style-none d-flex align-items-center justify-content-start ps-0 me-3 gap-0 mb-0">
											<div className="form-check form-switch form-check-custom form-check-n-dark form-check-success form-check-solid me-3">
												<input
													className="form-check-input border border-dark border-opacity-10"
													style={{ width: '35px', height: '19px' }}
													type="checkbox"
													value={isActive}
													onClick={() => activeLinkHandler(id, isActive)}
													id="flexSwitchChecked"
													checked={isActive}
												/>
											</div>
											<button
												type="button"
												className="btn px-3 pe-5"
												onClick={() => edittedLinkHandler(link)}
											>
												<span data-bs-toggle="tooltip" title="Edit">
													<i className="fa-light fa-pen-to-square text-gray-700 fs-3"></i>
												</span>
											</button>
											<button type="button" className="btn px-4">
												<Share
													link={`https://${brandedDomain}/${encodeURIComponent(slashTag)}`}
												/>
											</button>
											<button
												type="button"
												className="btn px-3"
												disabled={deleteLinkId === id}
												onClick={() => {
													setDeleteLinkId(id)
													setDeleteModal(true)
												}}
											>
												<i
													className="far fa-trash-can text-danger fs-3"
													data-bs-toggle="tooltip"
													title="Delete"
												></i>
											</button>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			})}

			{/* Modals */}
			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={() => {
					deleteLinkHandler(deleteLinkId)
					setDeleteModal(false)
				}}
				rightBtnHandler={() => {
					setDeleteLinkId(null)
					setDeleteModal(false)
				}}
				data={{
					heading: 'Delete short link',
					description: 'Are you sure you want to delete this short link?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default Links

import React, { useState, useEffect } from 'react'
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Input,
} from 'reactstrap'
import ShortLinksAPI from '../../services/http/shortLinks'
import LinkInBioAPI from '../../services/http/linkInBio'

const ShortlinksDropdown = ({ onSelect, loading }) => {
	const [shortlinks, setShortlinks] = useState([])
	const [biolinks, setBiolinks] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const handleSelect = (link) => {
		onSelect(link)
		setDropdownOpen(false)
	}

	useEffect(() => {
		const fetchShortlinks = async () => {
			try {
				const auth_token = localStorage.getItem('auth_token')
				const { data } = await ShortLinksAPI.getShortLinks(auth_token)
				setShortlinks(data.resultData)
			} catch (error) {
				console.error('Error fetching shortlinks:', error)
			}
		}
		const fetchBioPages = async () => {
			try {
				const auth_token = localStorage.getItem('auth_token')
				const { data } = await LinkInBioAPI.getLinkInBioPages(auth_token)
				setBiolinks(data.data)
			} catch (error) {
				console.error('Error fetching bio pages:', error)
			}
		}

		fetchShortlinks()
		fetchBioPages()
	}, [])

	const allLinks = [...shortlinks, ...biolinks]

	const filteredShortlinks = allLinks.filter(
		(link) =>
			(link.title &&
				link.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(link.name &&
				link.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(link.slashTag &&
				link.slashTag.toLowerCase().includes(searchTerm.toLowerCase())) ||
			(link.pageLink &&
				link.pageLink.toLowerCase().includes(searchTerm.toLowerCase()))
	)

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={() => setDropdownOpen(!dropdownOpen)}
		>
			<DropdownToggle caret disabled={loading}>
				Select Shortlink
			</DropdownToggle>
			<DropdownMenu>
				<Input
					type="text"
					placeholder="Search shortlinks..."
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					onClick={(e) => e.stopPropagation()}
					disabled={loading}
				/>
				{filteredShortlinks.map((link) => (
					<DropdownItem key={link.id} onClick={() => handleSelect(link)}>
						{link.title || link.name || link.slashTag}
						{link.qr ? ' (QR)' : ' (Link)'}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	)
}

export default ShortlinksDropdown

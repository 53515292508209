import React, { useState } from 'react'
import { Col } from 'reactstrap'
import CustomLinksAPI from '../../../services/http/customLinks'
import DnsConfiguration from '../modals/configuration'
import DeleteModal from '../../../modals/deleteModal'
import dateFormate from '../../../utils/dateFormat'

const CustomLink = ({ link, customLinksHandler }) => {
	const { domain, subDomain, status, spare, createdAt, id } = link
	const [isDeleting, setIsDeleting] = useState(false)
	const [dnsModal, setDnsModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const deleteCustomLinkHandler = () => {
		const auth_token = localStorage.getItem('auth_token')
		setIsDeleting(true)
		setDeleteModal(false)
		CustomLinksAPI.deleteCustomLink(auth_token, id)
			.then(({ data }) => {
				customLinksHandler((preState) => {
					return preState.filter((item) => item.id !== id)
				})
			})
			.catch(({ error }) => {
				console.log('handleDeleteLink:', error)
				setDeleteModal(false)
			})
			.finally(() => {
				setIsDeleting(false)
				setDeleteModal(false)
			})
	}

	const urlBuilder = () => {
		try {
			const url = new URL(domain)
			return spare === 'No' ? `${subDomain}.${url.host}` : url.host
		} catch (e) {
			console.log('Error while creating url : ', e)
		}
	}

	const StatusMapper = {
		Pending: 'pending-status',
		Connected: 'connected-status',
		Rejected: 'rejected-status',
	}
	return (
		<>
			<Col md={4}>
				<div className="card h-auto card-flush cursor-pointer py-lg-4 pt-4 mb-lg-7 mb-6 border border-gray-250">
					<div className="card-header min-h-50px px-lg-8 px-5 pt-lg-2">
						<div className="card-toolbar my-0 d-flex align-items-center">
							<div
								className={`alert py-2 mb-0 rounded-1 border-0 fw-bold 
            ${StatusMapper[status]}`}
							>
								{status}
							</div>
						</div>
						<button
							type="button"
							disabled={isDeleting}
							onClick={() => setDeleteModal(true)}
							className="btn p-0 d-flex align-items-center"
						>
							<i className="fa-light fa-trash-can fs-2 ms-5 text-danger"></i>
						</button>
					</div>
					<div className="card-body py-5 px-lg-8 px-5 pt-3">
						<h3 className="card-title fs-1 text-wrap-oneline">
							{urlBuilder()}
						</h3>
						<p
							className="fs-6 fw-bold text-primary mt-lg-3 mt-2"
							onClick={() => setDnsModal(true)}
						>
							DNS Configuration
						</p>
						<div className="d-flex">
							<i className="fa-regular fa-calendar fs-5 me-2"></i>
							<span className="fs-8">{dateFormate(createdAt)}</span>
						</div>
					</div>
				</div>
			</Col>

			<DnsConfiguration
				isOpen={dnsModal}
				customLink={link}
				modalHandler={() => setDnsModal(false)}
			/>

			{/* Modals */}
			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={deleteCustomLinkHandler}
				rightBtnHandler={() => setDeleteModal(false)}
				data={{
					heading: 'Delete Domain',
					description: 'Are you sure you want to delete this domain?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default CustomLink

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 1759}
				height={height || 2419}
				viewBox="0 0 1759 2419"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_175_40)">
					{/* Top polygon shape */}
					<path
						d="M879.5 1603L1661.09 1807V2215L879.5 2419L97.912 2215V1807L879.5 1603Z"
						fill={secondary || 'white'}
					/>
					{/* Bottom polygon shape */}
					<path
						d="M879.5 0L1661.09 204V612L879.5 816L97.912 612V204L879.5 0Z"
						fill={secondary || 'white'}
					/>

					{/* Main rectangle background */}
					<rect
						x={6}
						y={271}
						width={1748}
						height={1576}
						rx={77}
						fill={primary || '#0E1B21'}
					/>

					{/* Horizontal lines */}
					<path
						d="M294 587V473H408"
						stroke="white"
						strokeWidth={21}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M294 1531V1645H408"
						stroke="white"
						strokeWidth={21}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M1466 587V473H1352"
						stroke="white"
						strokeWidth={21}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M1466 1531V1645H1352"
						stroke="white"
						strokeWidth={21}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>

					{/* Text "SCAN ME" */}
					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 225}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="2145.5">
							{text}
						</tspan>
					</text>
				</g>

				{/* Clip path definition */}
				<defs>
					<clipPath id="clip0_175_40">
						<rect width="1759" height="2419" fill="white" />
					</clipPath>
				</defs>
				<image href={url} x="368" y="547" width="1024" height="1024" />
			</svg>
		</>
	)
}

export default IconFrame5

import React, { useContext, useEffect, useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Col,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/basic-info.png'
import clockicon from '../../../../assets/qr-icons/clock.png'
import locationicon from '../../../../assets/qr-icons/location.png'
import infocontacticon from '../../../../assets/qr-icons/info-contact.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import QrLink from '../QrLink'
import { AuthContxt } from '../../../../store/authContxt'
import businesspagebanner from '../../../../assets/qr-preview/business-page-banner.jpeg'

const BusinessPageQRForm = ({ formData, setFormData, qrType, isEdit, tab }) => {
	const [open, setOpen] = useState('0')
	const { errors, setErrors } = useContext(AuthContxt)
	useEffect(() => {
		tab && setOpen(tab)
	}, [tab])
	const toggleAccordion = (id) => {
		setOpen(open === id ? '' : id)
	}

	const handleChange = (e) => {
		const { name, value } = e.target

		setFormData((prevData) => {
			const updatedData = { ...prevData, [name]: value }

			if (
				name === 'street' ||
				name === 'postalCode' ||
				name === 'city' ||
				name === 'country'
			) {
				const generateMapUrl = () => {
					const query = `${updatedData.street || ''} ${updatedData.postalCode || ''} ${updatedData.city || ''} ${updatedData.country || ''}`
					const encodedQuery = encodeURIComponent(query.trim())
					const url = `https://www.google.com/maps/search/?api=1&query=${encodedQuery}`
					console.log(url)
					return url
				}

				updatedData.location = generateMapUrl()
			}

			return updatedData
		})
	}

	return (
		<div className="container px-0 mt-8 mb-5">
			<Accordion
				className="qr-accodian-form"
				open={open}
				toggle={toggleAccordion}
			>
				<AccordionItem>
					<AccordionHeader targetId="0">
						<img
							src={linkicon}
							className="w-30px me-4"
							alt="Design and customizations"
						/>{' '}
						Short Link
					</AccordionHeader>
					<AccordionBody accordionId="0">
						<QrLink
							formData={formData}
							setFormData={setFormData}
							isEdit={isEdit}
							qrType={qrType}
						/>
					</AccordionBody>
				</AccordionItem>
				{/* Design & Customizations */}
				<AccordionItem>
					<AccordionHeader targetId="1">
						<img
							src={designicon}
							className="w-30px me-4"
							alt="Design and customizations"
						/>{' '}
						Design & Customizations
					</AccordionHeader>
					<AccordionBody accordionId="1">
						<div>
							<p className="fs-5 text-gray-700 mb-10">
								Customize your business page with the following options.
							</p>
							<p className="fw-semibold fs-4 mb-4">Colors</p>
							<div className="d-flex flex-wrap row-gap-5">
								{/* Color Options */}
								{[
									'#de3121',
									'#ef8000',
									'#198639',
									'#229ce0',
									'#2a5bd7',
									'#6b52d1',
									'#d84280',
								].map((color, idx) => (
									<div
										key={idx}
										className="p-1 cursor-pointer rounded-1 on-hvr-border bdr-qr-btn me-5"
									>
										<div
											onClick={() => {
												setFormData((prevData) => ({
													...prevData,
													primaryColor: color,
												}))
											}}
											className="p-3 rounded-1 w-40px h-40px"
											style={{ backgroundColor: color }}
										></div>
									</div>
								))}
							</div>

							<div className="d-flex flex-wrap gap-lg-10 gap-5 mt-10">
								<div>
									<p className="fs-4 fw-semibold">Primary</p>
									<div className="color-picker-wrapper">
										<Input
											className="color-picker rounded-0"
											type="color"
											name="primaryColor"
											value={formData?.primaryColor || '#000000'}
											onChange={handleChange}
										/>
										<Input
											className="hex-input form-control rounded-0"
											type="text"
											name="primaryColor"
											placeholder="#000000"
											value={formData?.primaryColor}
											onChange={handleChange}
										/>
									</div>
								</div>
								<div>
									<p className="fs-4 fw-semibold">Button</p>
									<div className="color-picker-wrapper">
										<Input
											className="color-picker rounded-0"
											type="color"
											name="ctaColor"
											value={formData?.ctaColor}
											onChange={handleChange}
										/>
										<Input
											className="hex-input form-control rounded-0"
											type="text"
											name="ctaColor"
											placeholder="#5c47e"
											value={formData?.ctaColor}
											onChange={handleChange}
										/>
									</div>
								</div>
							</div>

							<div className="mt-10">
								<div>
									<p className="fs-4 fw-semibold">Image:</p>
								</div>
								<div className="mt-3 pe-8">
									<div
										className="image-input image-input-outline"
										style={{
											backgroundImage: `url(${formData?.image || businesspagebanner})`,
										}}
									>
										<div
											className="image-input-wrapper w-125px h-125px shadow-none border border-dark border-opacity-10"
											style={{
												backgroundImage: `url(${formData?.image || businesspagebanner})`,
											}}
										></div>
										<Label
											className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body position-absolute border border-dark border-opacity-10"
											style={{ right: '3px', top: '3px' }}
										>
											<i className="fas fa-pencil fs-6"></i>
											<Input
												className="d-none"
												type="file"
												name="image"
												accept=".png, .jpg, .jpeg"
												onChange={(e) =>
													setFormData({
														...formData,
														image: URL.createObjectURL(e.target.files[0]),
														file: e.target.files[0],
													})
												}
											/>
										</Label>
									</div>
								</div>
							</div>
						</div>
					</AccordionBody>
				</AccordionItem>

				{/* Basic Information */}
				<AccordionItem>
					<AccordionHeader targetId="2">
						<img
							src={infoicon}
							className="w-30px me-4"
							alt="basic information"
						/>{' '}
						Basic Information
					</AccordionHeader>
					<AccordionBody accordionId="2">
						<Form>
							<p className="fs-5 mb-5">
								Introduce your business or organization in a few words.
								Optionally, add a button to a website of your choice. Fields
								marked with a * are mandatory.
							</p>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="company">
									Company
								</Label>
								<Input
									type="text"
									className="h-50px"
									id="company"
									name="company"
									placeholder="Joy's Cafe"
									value={formData?.company}
									onChange={handleChange}
								/>
							</FormGroup>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="headline">
									Headline*
								</Label>
								<Input
									type="text"
									className="h-50px"
									id="headline"
									name="headline"
									placeholder="Add a headline or slogan"
									value={formData?.headline}
									onChange={handleChange}
									required
								/>
								{errors?.headline && (
									<p style={{ color: 'red' }}>{errors.headline}</p>
								)}
							</FormGroup>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="summary">
									Summary
								</Label>
								<Input
									type="textarea"
									id="summary"
									name="summary"
									rows="3"
									maxLength={200}
									value={formData?.summary}
									onChange={handleChange}
								/>
								<small className="form-text text-muted text-right">
									64 / 200
								</small>
							</FormGroup>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="button">
									Button
								</Label>
								<Row>
									<Col md={6}>
										<Input
											type="text"
											className="h-50px"
											name="buttonText"
											placeholder="Button Text"
											value={formData?.buttonText}
											onChange={handleChange}
										/>
									</Col>
									<Col md={6}>
										<Input
											type="url"
											className="h-50px"
											name="buttonUrl"
											placeholder="http://www."
											value={formData?.buttonUrl}
											onChange={handleChange}
										/>
									</Col>
								</Row>
							</FormGroup>
						</Form>
					</AccordionBody>
				</AccordionItem>

				{/* Opening Hours */}
				<AccordionItem>
					<AccordionHeader targetId="3">
						<img src={clockicon} className="w-30px me-4" alt="opening hours" />{' '}
						Opening Hours
					</AccordionHeader>
					<AccordionBody accordionId="3">
						<Form>
							<p className="fs-5 mb-5">
								Provide your opening hours if applicable.
							</p>
							<Row className="mw-700px">
								{[
									'Monday',
									'Tuesday',
									'Wednesday',
									'Thursday',
									'Friday',
									'Saturday',
									'Sunday',
								].map((day, idx) => (
									<FormGroup key={idx} className="row align-items-center">
										<Col md={1}>
											<div className="form-check form-check-sm form-check-custom form-check-info form-check-solid not-checked-white rounded-1">
												<Input
													type="checkbox"
													id={day.toLowerCase()}
													className="form-check-input cursor-pointer"
													name={`${day.toLowerCase()}Open`}
													checked={formData?.[`${day.toLowerCase()}Open`]}
													onChange={(e) =>
														setFormData((prevData) => ({
															...prevData,
															[`${day.toLowerCase()}Open`]: e.target.checked,
														}))
													}
												/>
											</div>
										</Col>
										<Label for={day.toLowerCase()} className="col-md-2 fw-bold">
											{day}
										</Label>
										<Col md={3}>
											<Input
												type="time"
												name={`${day.toLowerCase()}Start`}
												value={formData?.[`${day.toLowerCase()}Start`]}
												onChange={handleChange}
											/>
										</Col>
										<span className="col-md-1 text-center">-</span>
										<Col md={3}>
											<Input
												type="time"
												name={`${day.toLowerCase()}End`}
												value={formData?.[`${day.toLowerCase()}End`]}
												onChange={handleChange}
											/>
										</Col>
										<Col />
									</FormGroup>
								))}
							</Row>
						</Form>
					</AccordionBody>
				</AccordionItem>

				{/* Address & Location */}
				<AccordionItem>
					<AccordionHeader targetId="4">
						<img
							src={locationicon}
							className="w-30px me-4"
							alt="address and location"
						/>{' '}
						Address & Location
					</AccordionHeader>
					<AccordionBody accordionId="4">
						<Form>
							<FormGroup className="mb-7">
								<Label className="fs-5 fw-bold" for="street">
									Street
								</Label>
								<Input
									type="text"
									className="h-50px"
									name="street"
									id="street"
									value={formData?.street}
									onChange={handleChange}
								/>
							</FormGroup>
							<Row>
								<Col md={6}>
									<FormGroup className="mb-7">
										<Label className="fs-5 fw-bold" for="postalCode">
											Postal Code
										</Label>
										<Input
											type="text"
											className="h-50px"
											name="postalCode"
											id="postalCode"
											value={formData?.postalCode}
											onChange={handleChange}
										/>
									</FormGroup>
								</Col>
								<Col md={6}>
									<FormGroup className="mb-7">
										<Label className="fs-5 fw-bold" for="city">
											City
										</Label>
										<Input
											type="text"
											className="h-50px"
											name="city"
											id="city"
											value={formData?.city}
											onChange={handleChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<FormGroup className="mb-7">
								<Label className="fs-5 fw-bold" for="country">
									Country
								</Label>
								<Input
									type="text"
									className="h-50px"
									name="country"
									id="country"
									value={formData?.country}
									onChange={handleChange}
								/>
							</FormGroup>
						</Form>
					</AccordionBody>
				</AccordionItem>

				{/* About & Contact information*/}
				<AccordionItem>
					<AccordionHeader targetId="5">
						<img
							src={infocontacticon}
							className="w-30px me-4"
							alt="about and contact"
						/>{' '}
						About & Contact information
					</AccordionHeader>
					<AccordionBody accordionId="5">
						<Form>
							<p className="fs-5 mb-5">
								Add more detailed information about your buisness and provide
								contact data.
							</p>
							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="about">
									About
								</Label>
								<Input
									type="textarea"
									id="about"
									name="about"
									placeholder="about"
									value={formData?.about}
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="aboutName">
									Name
								</Label>
								<Input
									type="text"
									className="h-50px"
									id="aboutName"
									name="aboutName"
									placeholder="Joy"
									value={formData?.aboutName}
									onChange={handleChange}
								/>
							</FormGroup>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="aboutPhone">
									Phone
								</Label>
								<Input
									type="number"
									className="h-50px"
									id="aboutPhone"
									name="aboutPhone"
									placeholder="+1 0000 00000"
									value={formData?.aboutPhone}
									onChange={handleChange}
								/>
							</FormGroup>
							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="aboutEmail">
									Email
								</Label>
								<Input
									type="text"
									className="h-50px"
									id="aboutEmail"
									name="aboutEmail"
									placeholder="hello@joysbar.com"
									value={formData?.aboutEmail}
									onChange={handleChange}
								/>
							</FormGroup>

							<FormGroup className="mb-7">
								<Label className="fw-bold fs-5" for="aboutWebsite">
									Website
								</Label>
								<Input
									type="text"
									className="h-50px"
									id="aboutWebsite"
									name="aboutWebsite"
									placeholder="https://www."
									value={formData?.aboutWebsite}
									onChange={handleChange}
								/>
							</FormGroup>
						</Form>
					</AccordionBody>
				</AccordionItem>
			</Accordion>
		</div>
	)
}

export default BusinessPageQRForm

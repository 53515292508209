const extractName = (object) => {
	if (object) {
		const { email = '', firstName = null, lastName = null } = object || {}
		const emailUsername = email?.substring(0, email.indexOf('@'))
		const name = `${firstName !== null ? firstName : ''} ${lastName !== null ? lastName : emailUsername}`
		return name
	} else {
		return ''
	}
}

export const extractNameFromEmail = (email) => {
	const emailUsername = email?.substring(0, email.indexOf('@')) || ''
	return emailUsername
}

export default extractName

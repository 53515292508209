import { useContext, useEffect, useRef, useState } from 'react'
import BottomBackground from './bottomBackground'
import { LinkInBioContxt } from '../linkInBioContxt'

export const GRADIENT_DIRECTION = [
	'0',
	'45',
	'90',
	'135',
	'180',
	'225',
	'270',
	'315',
]
export const PRESET = [
	'#000000',
	'#de3121',
	'#ef8000',
	'#198639',
	'#229ce0',
	'#2a5bd7',
	'#6b52d1',
	'#d84280',
]

const Background = () => {
	const { bioPageForm } = useContext(LinkInBioContxt)
	const { setValue, watch } = bioPageForm
	const {
		bg,
		bgType,
		template,
		gradientDir,
		gradientClr1,
		gradientClr2,
		preset,
	} = watch()

	const [backgroundColor, setBackgroundColor] = useState('#000000')

	const [, setFile] = useState(null)
	const [, setVideoFile] = useState(null)
	const [backgroundImage, setBackgroundImage] = useState(
		bgType === 'image' ? bg : ''
	)
	const [backgroundVideo, setBackgroundVideo] = useState(
		bgType === 'video' ? bg : ''
	)
	const fileInputRef = useRef(null)
	const videoFileInputRef = useRef(null)

	const [errorMsg, setErrorMsg] = useState({})

	const imageHandler = () => {
		fileInputRef?.current?.click()
	}

	const videoHandler = () => {
		videoFileInputRef?.current?.click()
	}

	useEffect(() => {
		// setValue(
		//   "bg",
		//   `linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`
		// );
	}, [gradientClr1, gradientClr2, gradientDir, setValue])

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0]
		const maxAllowedSize = 2 * 1024 * 1024
		if (event.target.files[0].size > maxAllowedSize) {
			event.target.value = ''
			setErrorMsg((preState) => ({
				...preState,
				image: 'Max allowed side is 2 MB',
			}))
			setTimeout(() => resetErrorMsg('image'), 4 * 1000)
			return
		}

		resetErrorMsg('image')
		if (selectedFile) {
			const reader = new FileReader()
			reader.onload = () => {
				const base64String = reader.result
				setBackgroundImage(base64String)
				setValue('bg', base64String)
				setValue('bgType', 'image')
			}
			reader.readAsDataURL(selectedFile)
			setFile(selectedFile)
			event.target.value = ''
		}
	}

	const resetErrorMsg = (type) => {
		setErrorMsg((preState) => ({
			...preState,
			[type]: null,
		}))
	}

	const handleVideoFileChange = (event) => {
		const selectedFile = event.target.files[0]
		const maxAllowedSize = 2 * 1024 * 1024
		if (event.target.files[0].size > maxAllowedSize) {
			event.target.value = ''
			setErrorMsg((preState) => ({
				...preState,
				video: 'Max allowed side is 2 MB',
			}))
			setTimeout(() => resetErrorMsg('video'), 4 * 1000)
			return
		}

		resetErrorMsg('video')

		if (selectedFile) {
			const reader = new FileReader()
			reader.onload = () => {
				const base64String = reader.result
				setBackgroundVideo(base64String)
				setValue('bg', base64String)
				setValue('bgType', 'video')
			}
			reader.readAsDataURL(selectedFile)
			setVideoFile(selectedFile)
			event.target.value = ''
		}
	}

	// const backgroundHandler = (type) => {
	//   if (type === "image") {
	//     if (backgroundImage) {
	//       setValue("bg", backgroundImage);
	//       setValue("bgType", "image");
	//       return;
	//     } else {
	//       imageHandler();
	//     }
	//   }
	//   if (type === "video") {
	//     if (backgroundVideo) {
	//       setValue("bg", backgroundVideo);
	//       setValue("bgType", "video");
	//     } else {
	//       videoHandler();
	//     }
	//   }
	//   if (type === "gradient") {
	//     setValue(
	//       "bg",
	//       `linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`
	//     );
	//     setValue("bgType", type);
	//   }
	//   if (type === "flatColor") {
	//     setValue("bg", backgroundColor);
	//     setValue("bgType", type);
	//   }
	// };
	const backgroundHandler = (type) => {
		switch (type) {
			case 'image':
				if (backgroundImage) {
					setValue('bg', backgroundImage)
					setValue('bgType', 'image')
				} else {
					imageHandler()
				}
				break
			case 'video':
				if (backgroundVideo) {
					setValue('bg', backgroundVideo)
					setValue('bgType', 'video')
				} else {
					videoHandler()
				}
				break
			case 'gradient':
				setValue(
					'bg',
					`linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`
				)
				setValue('bgType', type)
				break
			case 'flatColor':
				setValue('bg', backgroundColor)
				setValue('bgType', type)
				break
			default:
				break
		}
	}

	return (
		<div id="customTheme" className="mt-10">
			<h3 className="fw-bolder fs-2 mb-lg-3 mb-7">Backgrounds</h3>
			<div className="border-lg p-lg-5 rounded-1">
				<div
					className="gap-10 w-100 theme-grids"
					style={{
						display: 'inline-grid',
					}}
				>
					<button
						type="button"
						onClick={() => backgroundHandler('flatColor')}
						className="rounded-1 border-0 bg-white"
					>
						<div className="h-100 d-flex flex-column">
							<div
								style={{ paddingTop: '150%', backgroundColor: backgroundColor }}
								className="position-relative h-100 rounded-1 w-100 mb-3 border-dark border-1 border-dashed border-opacity-50 d-flex flex-column justify-content-center align-items-center"
							></div>
							<p className="fs-5 fw-semibold mb-lg-1 mb-0">Flat Color</p>
						</div>
					</button>
					<button
						type="button"
						onClick={() => backgroundHandler('gradient')}
						className="rounded-1 border-0 bg-white selected"
					>
						<div className="h-100 d-flex flex-column">
							<div
								style={{
									paddingTop: '150%',
									background: `linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`,
								}}
								className="position-relative h-100 rounded-1 w-100 mb-3 border-dark border-1 border-opacity-50 d-flex flex-column justify-content-center align-items-center"
							></div>
							<p className="fs-5 fw-semibold mb-lg-1 mb-0">Gradient</p>
						</div>
					</button>

					<div
						style={{
							position: 'relative',
						}}
					>
						{backgroundImage !== '' ? (
							<span
								onClick={() => {
									setBackgroundImage('')
									setValue('bg', '')
									setValue('bgType', '')
								}}
								style={{
									position: 'absolute',
									zIndex: 9,
									top: '-8px',
									left: '-8px',
								}}
								className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
								data-kt-image-input-action="remove"
								data-bs-toggle="tooltip"
								data-bs-dismiss="click"
								title="Remove avatar"
							>
								<i className="fa fa-x opacity-50"></i>
							</span>
						) : null}
						<button
							type="button"
							onClick={() => backgroundHandler('image')}
							className="rounded-1 border-0 bg-white"
							style={{ width: '100%', height: '100%' }}
						>
							<div className="h-100 d-flex flex-column">
								<input
									ref={fileInputRef}
									type="file"
									name="backgroundImage"
									accept="image/png, image/svg, image/jpg, image/jpeg"
									style={{ display: 'none' }}
									onChange={handleFileChange}
								/>
								<div
									className="position-relative h-100 rounded-1 w-100 mb-3 border-dashed d-flex flex-column justify-content-center align-items-center"
									style={{
										border: '1px solid #e9e6f1',
										maxHeight: '300px',
										minHeight: '200px',
										overflow: 'hidden',
									}}
								>
									{backgroundImage !== '' ? (
										<img
											width={'100%'}
											height={'100%'}
											src={backgroundImage}
											alt="Background"
										/>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											viewBox="0 0 55 55"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M55 7.5C55 3.35788 51.6423 0 47.5 0H7.5C3.35788 0 0 3.35788 0 7.5V47.5C0 51.6423 3.35788 55 7.5 55H47.5C51.6423 55 55 51.6423 55 47.5V7.5ZM50 7.5C50 6.1193 48.8807 5 47.5 5H7.5C6.1193 5 5 6.1193 5 7.5V47.5C5 48.8807 6.1193 50 7.5 50H47.5C48.8807 50 50 48.8807 50 47.5V7.5Z"
												fill="#AFAFAF"
											/>
											<path
												d="M9.51662 41.3026L20.3052 21.5236C21.2528 19.7864 23.7472 19.7864 24.6947 21.5236L32.6162 36.0464L35.3295 31.2984C36.2892 29.6189 38.7107 29.6189 39.6705 31.2984L45.3627 41.2596C46.315 42.9261 45.1115 44.9999 43.192 44.9999H11.7113C9.8137 44.9999 8.60792 42.9686 9.51662 41.3026Z"
												fill="#AFAFAF"
											/>
											<path
												d="M42.5 17.5C42.5 20.2614 40.2615 22.5 37.5 22.5C34.7385 22.5 32.5 20.2614 32.5 17.5C32.5 14.7386 34.7385 12.5 37.5 12.5C40.2615 12.5 42.5 14.7386 42.5 17.5Z"
												fill="#AFAFAF"
											/>
										</svg>
									)}
								</div>
								<div>
									<p className="fs-5 fw-semibold mb-lg-1 mb-0">Image</p>
									{errorMsg?.image !== null ? (
										<span className="error-text text-danger">
											{errorMsg?.image}
										</span>
									) : null}
								</div>
							</div>
						</button>
					</div>

					<div style={{ position: 'relative' }}>
						{backgroundVideo !== '' ? (
							<span
								onClick={(e) => {
									setBackgroundVideo('')
									setValue('bg', '')
									setValue('bgType', '')
								}}
								style={{
									position: 'absolute',
									zIndex: 9,
									top: '-8px',
									left: '-8px',
								}}
								className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
								title="Remove avatar"
							>
								<i className="fa fa-x opacity-50"></i>
							</span>
						) : null}
						<button
							type="button"
							onClick={() => backgroundHandler('video')}
							className="rounded-1 border-0 bg-white"
							style={{ width: '100%', height: '100%' }}
						>
							<div className="h-100 d-flex flex-column">
								<div
									className="position-relative h-100 rounded-1 w-100 mb-3 border-dashed d-flex flex-column justify-content-center align-items-center"
									style={{
										border: '1px solid #e9e6f1',
										maxHeight: '300px',
										minHeight: '200px',
										overflow: 'hidden',
									}}
								>
									<input
										ref={videoFileInputRef}
										type="file"
										name="backgroundVideo"
										accept="video/mp4"
										style={{ display: 'none' }}
										onChange={handleVideoFileChange}
									/>
									{backgroundVideo !== '' ? (
										<video
											width={'100%'}
											height={'100%'}
											controls={false}
											autoPlay
											muted
											loop
										>
											<source src={backgroundVideo} type="video/mp4" />
										</video>
									) : (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="30"
											height="30"
											viewBox="0 0 50 50"
											fill="none"
										>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M33.319 13.855H16.681C8.24395 13.855 4.02543 13.855 1.6558 16.3221C-0.713824 18.7891 -0.156299 22.6008 0.958776 30.2238L2.0162 37.4528C2.89065 43.4308 3.32788 46.4198 5.57085 48.2098C7.81383 49.9998 11.122 49.9998 17.7384 49.9998H32.2615C38.878 49.9998 42.1862 49.9998 44.4292 48.2098C46.6722 46.4198 47.1093 43.4308 47.9838 37.4528L49.0413 30.2238C50.1563 22.6008 50.7137 18.7891 48.3442 16.3221C45.9745 13.855 41.756 13.855 33.319 13.855ZM31.453 34.4855C32.849 33.62 32.849 31.3798 31.453 30.5143L23.024 25.289C21.6673 24.448 20 25.5428 20 27.2745V37.7253C20 39.457 21.6673 40.5518 23.024 39.7108L31.453 34.4855Z"
												fill="#AFAFAF"
											/>
											<path
												opacity="0.4"
												d="M16.2739 1.82322e-05H33.7243C34.3053 -0.000131768 34.751 -0.000231625 35.1405 0.0378684C37.91 0.308793 40.177 1.97394 41.1383 4.21694H8.85988C9.82115 1.97394 12.088 0.308793 14.8576 0.0378684C15.2471 -0.000231625 15.6927 -0.000131768 16.2739 1.82322e-05Z"
												fill="#AFAFAF"
											/>
											<path
												opacity="0.7"
												d="M10.7755 6.80664C7.29896 6.80664 4.44828 8.90601 3.49696 11.6911C3.47713 11.7492 3.45813 11.8075 3.43996 11.8662C4.43533 11.5647 5.47123 11.3678 6.51991 11.2334C9.22083 10.8871 12.6342 10.8873 16.5993 10.8875H33.8295C37.7945 10.8873 41.208 10.8871 43.9088 11.2334C44.9575 11.3678 45.9935 11.5647 46.9888 11.8662C46.9708 11.8075 46.9515 11.7492 46.9318 11.6911C45.9805 8.90601 43.1298 6.80664 39.6533 6.80664H10.7755Z"
												fill="#AFAFAF"
											/>
										</svg>
									)}
								</div>
								<div>
									<p className="fs-5 fw-semibold mb-lg-1 mb-0">Video</p>
									{errorMsg?.video !== null ? (
										<span className="error-text text-danger">
											{errorMsg?.video}
										</span>
									) : null}
								</div>
							</div>
						</button>
					</div>
				</div>

				{bgType === 'gradient' ? (
					<div>
						<div className="separator my-lg-3 my-4 separator-dashed"></div>
						<div className="d-flex w-100 gap-3 mobile-hide-f">
							<p className="w-200px fs-4 fw-semibold">Select Gradient</p>
							<p className="fs-4 fw-semibold">Gradient Direction</p>
						</div>
						<div className="d-flex w-100 gap-10 mobile-hide-f">
							<div className="color-picker-container d-flex">
								<div className="color-picker-1">
									<input
										className="w-40px h-40px border-0"
										type="color"
										id="colorPickerLeft"
										onChange={(e) => {
											setValue('gradientClr1', e.target.value)
											setValue(
												'bg',
												`linear-gradient(${gradientDir}deg, ${e.target.value}, ${gradientClr2})`
											)
										}}
										value={gradientClr1}
									/>
								</div>
								<div
									style={{
										width: '100px',
										height: '40px',
										background: `linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`,
									}}
									className="gradient-rectangle"
								></div>
								<div className="color-picker-2">
									<input
										className="w-40px h-40px border-0"
										type="color"
										id="colorPickerRight"
										onChange={(e) => {
											setValue('gradientClr2', e.target.value)
											setValue(
												'bg',
												`linear-gradient(${gradientDir}deg, ${gradientClr1}, ${e.target.value})`
											)
										}}
										value={gradientClr2}
									/>
								</div>
							</div>
							<div>
								<ul
									className="direction-list"
									id="directionList"
									style={{
										listStyle: 'none',
										display: 'flex',
										flexWrap: 'wrap',
										padding: '0px',
										gap: '15px',
										margin: '10px 0px',
									}}
								>
									{GRADIENT_DIRECTION?.map((item) => {
										return (
											<li
												onClick={() => {
													setValue('gradientDir', item)
												}}
												className="direction-list-item"
											>
												<i
													style={{ transform: `rotate(${item}deg)` }}
													className="fas fa-circle-arrow-up fs-2 direction-icon"
												></i>
											</li>
										)
									})}
								</ul>
							</div>
						</div>

						<div className="d-flex flex-column w-100 gap-6 desktop-hide-f">
							<div>
								<p className="w-200px fs-4 fw-semibold">Select Gradient</p>
								<div className="color-picker-container d-flex">
									<div className="color-picker-1">
										<input
											className="w-40px h-40px border-0"
											type="color"
											id="colorPickerLeft"
											onChange={(e) => {
												setValue('gradientClr1', e.target.value)
											}}
											value={gradientClr1}
										/>
									</div>
									<div
										style={{
											width: '100px',
											height: '40px',
											background: `linear-gradient(${gradientDir}deg, ${gradientClr1}, ${gradientClr2})`,
										}}
										className="gradient-rectangle"
									></div>
									<div className="color-picker-2">
										<input
											className="w-40px h-40px border-0"
											type="color"
											id="colorPickerRight"
											onChange={(e) => {
												setValue('gradientClr2', e.target.value)
											}}
											value={gradientClr2}
										/>
									</div>
								</div>
							</div>

							<div>
								<p className="fs-4 fw-semibold">Gradient Direction</p>
								<div>
									<ul
										className="direction-list"
										id="directionList"
										style={{
											listStyle: 'none',
											display: 'flex',
											flexWrap: 'wrap',
											padding: '0px',
											gap: '15px',
											margin: '10px 0px',
										}}
									>
										{GRADIENT_DIRECTION?.map((item) => {
											return (
												<li
													onClick={(e) => {
														setValue('gradientDir', item)
													}}
													className="direction-list-item"
												>
													<i
														style={{ transform: `rotate(${item}deg)` }}
														className="fas fa-circle-arrow-up fs-3 direction-icon"
													></i>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				) : null}
				{bgType === 'flatColor' ? (
					<div>
						<div className="separator my-3 separator-dashed"></div>
						<div className="d-lg-flex gap-lg-10">
							<div className="mb-5">
								<p className="fw-semibold fs-4 mb-4">Preset</p>
								<div className="d-flex flex-wrap row-gap-5">
									{PRESET.map((item) => {
										return (
											<div
												onClick={() => {
													setBackgroundColor(item)
													setValue('bg', item)
													setValue('preset', item)
												}}
												className={`p-1 rounded-1 on-hvr-border bdr-qr-btn me-5 ${
													preset === item ? 'selected' : ''
												}`}
											>
												<div
													className="p-3 rounded-1 w-40px h-40px"
													style={{ backgroundColor: item }}
												></div>
											</div>
										)
									})}
								</div>
							</div>
							<div className="d-flex gap-10">
								<div>
									<p className="fw-semibold fs-4">Background Color</p>
									<div className=" bdr-qr-btn p-1 color-picker-wrapper">
										<input
											className="color-picker h-40px w-40px"
											type="color"
											value={bg}
											onChange={(e) => {
												setBackgroundColor(e.target.value)
												setValue('bg', e.target.value)
											}}
										/>
										<input
											className="hex-input form-control h-40px rounded-0"
											type="text"
											maxLength="7"
											value={bg}
											// readOnly
											placeholder="#ffffff"
											onChange={(e) => {
												let value = e.target.value
												if (value.length === 6 && !value.startsWith('#')) {
													value = `#${value}`
												}
												if (value.length <= 7) {
													setBackgroundColor(value)
													setValue('bg', value)
												}
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{[2, 3, 4].includes(+template) ? <BottomBackground /> : null}
			</div>
		</div>
	)
}

export default Background

import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { AuthContxt } from './store/authContxt'
import { useContext, useEffect, useState } from 'react'

const AuthLayout = () => {
	const { profile } = useContext(AuthContxt)
	// const [loading, setLoading] = useState(true);
	// const [redirectPath, setRedirectPath] = useState(null);

	if (profile) {
		return <Outlet />
	}

	return <Navigate to={'/'} replace />
}

export default AuthLayout

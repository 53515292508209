import React, { useState, useEffect } from 'react'
import { InputGroup, InputGroupText, Input, Card, Spinner } from 'reactstrap'
import DragAndDropEmailEditor from './emailEditor'
import Sidebar from '../../components/common/sidebar'
import EmailTemplateAPI from '../../services/http/emailTemplate'
import {
	Link,
	useNavigate,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom'
import EmailCampaignAPI from '../../services/http/emailCampaign'
import ShortLinksAPI from '../../services/http/shortLinks'

const AddEmailTemplate = () => {
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const emailCampaignId = searchParams.get('emailCampaignId')
	const navigate = useNavigate()
	const [shortLinks, setShortLinks] = useState([])
	const [loading, setLoading] = useState(true)
	const [title, setTitle] = useState('New Template 1')
	const [isEditingTitle, setIsEditingTitle] = useState(false)
	const [shouldExport, setShouldExport] = useState(false)
	const [editorMode, setEditorMode] = useState('modern')
	const previousLocation = location.state && location.state.from
	const previousPath = previousLocation
		? previousLocation.pathname + previousLocation.search
		: '/emailcampaigns'

	const updateEmailCampaign = async (id) => {
		try {
			if (emailCampaignId) {
				const auth_token = localStorage.getItem('auth_token')
				EmailCampaignAPI.updateEmailCampaign(auth_token, emailCampaignId, {
					emailTemplateId: id,
				})
					.then(({ data }) => {
						console.log('created data', data)
						setShouldExport(false)
						navigate(`/emailcampaignsettings/${emailCampaignId}`)
					})
					.catch((error) => {
						console.log('Error while fetching shortlinks', error)
						setShouldExport(false)
					})
					.finally(() => {})
			} else {
				navigate(previousPath)
			}
		} catch (e) {
			console.log('Error while creating email campaign : ', e.message)
			setShouldExport(false)
		}
	}

	const handleClick = (values) => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			values.name = title
			EmailTemplateAPI.createEmailTemplate(auth_token, values)
				.then(({ data }) => {
					updateEmailCampaign(data.resultData.id)
				})
				.catch((error) => {
					console.log('Error while fetching email templates', error)
					setShouldExport(false)
				})
		} catch (error) {
			console.log('Error while fetching email templates', error)
			setShouldExport(false)
		}
	}

	const handleTitleEdit = () => {
		setIsEditingTitle(true)
	}

	const handleTitleSave = () => {
		setIsEditingTitle(false)
	}

	const handleSave = () => {
		setShouldExport(true)
	}

	return (
		<div>
			<div className="w-full d-flex">
				<div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
					<Sidebar />
				</div>
				<div className="customDashboardSize">
					<div className="d-flex flex-column w-100 flex-root app-root">
						<div className="app-page flex-column flex-column-fluid">
							<div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
								<div className="app-main flex-column flex-row-fluid">
									<Card className="card card-flush">
										<div className="ps-10 pt-10 d-flex flex-row justify-content-between w-100">
											<h2 className="me-15 fs-2x mb-0">
												<Link to={previousPath}>
													<a>
														<i className="fal fa-circle-arrow-left fs-1 me-3 text-gray-600 text-hover-dark"></i>
													</a>
												</Link>
												{isEditingTitle ? (
													<input
														type="text"
														value={title}
														onChange={(e) => setTitle(e.target.value)}
														className="form-control d-inline-block w-auto"
													/>
												) : (
													title
												)}
												{isEditingTitle ? (
													<button
														onClick={handleTitleSave}
														className="btn btn-icon"
													>
														<i className="fas fa-check"></i>
													</button>
												) : (
													<button
														onClick={handleTitleEdit}
														className="btn btn-icon"
													>
														<i className="fas fa-pencil-alt"></i>
													</button>
												)}
											</h2>
											<div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
												<button
													type="button"
													className="btn text-nowrap rounded-1 btn-info text-white"
													style={{ width: '160px' }}
													onClick={handleSave}
												>
													{shouldExport ? (
														<Spinner animation="border" size="sm" />
													) : (
														'Add and Continue'
													)}
												</button>
											</div>
										</div>

										<div className="separator border-1 my-5"></div>
										<DragAndDropEmailEditor
											editorMode={editorMode}
											setEditorMode={setEditorMode}
											handleClick={handleClick}
											shouldExport={shouldExport}
											setShouldExport={setShouldExport}
										/>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddEmailTemplate

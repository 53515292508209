import dayjs from 'dayjs'
import { DateRangePicker } from 'react-date-range'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

const DateRangeSelector = ({
	analyticsHandler,
	selectedDateRange,
	setSelectedDateRange,
	toggleHandler,
}) => {
	const dateHandler = (ranges) => {
		setSelectedDateRange(ranges.selection)
	}

	return (
		<>
			<div className="shadow d-inline-block">
				<DateRangePicker
					onChange={dateHandler}
					showSelectionPreview={false}
					moveRangeOnFirstSelection={false}
					months={1}
					ranges={[selectedDateRange]}
					direction="horizontal"
					showDateDisplay={false}
				/>
				<div className="drp-buttons">
					<span className="drp-selected">
						<span>
							{dayjs(selectedDateRange.startDate).format('MM/DD/YYYY')}
						</span>
						<span>{` - `}</span>
						<span>{dayjs(selectedDateRange.endDate).format('MM/DD/YYYY')}</span>
					</span>
					<button
						onClick={() => toggleHandler(false)}
						className="cancelBtn btn btn-sm btn-default"
						type="button"
					>
						Cancel
					</button>

					<button
						onClick={() => {
							if (analyticsHandler) {
								const adjustedStartDate = new Date(
									selectedDateRange.startDate.setHours(0, 0, 0, 0)
								)
								const adjustedEndDate = new Date(
									selectedDateRange.endDate.setHours(23, 59, 59, 999)
								)
								analyticsHandler(adjustedStartDate, adjustedEndDate)
								toggleHandler(false)
							}
						}}
						className="applyBtn btn btn-sm btn-primary"
						type="button"
					>
						Apply
					</button>
				</div>
			</div>
		</>
	)
}

export default DateRangeSelector

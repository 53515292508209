import React from 'react'
import DrawerWrapper from '../../wrapper/drawerWrapper'
import EditQrCodeForm from './form'

const EditQrCode = ({
	edittedData,
	edittedDataHandler,
	editQrCode,
	setEditQrCode,
}) => {
	return (
		<DrawerWrapper open={editQrCode} toggle={() => setEditQrCode(false)}>
			<EditQrCodeForm
				edittedData={edittedData}
				edittedDataHandler={edittedDataHandler}
				setEditQrCode={setEditQrCode}
			/>
		</DrawerWrapper>
	)
}

export default EditQrCode

import axios from 'axios'
import routes from '../pages/routes'

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5000'

const axiosInstance = axios.create({
	baseURL,
	withCredentials: true, // This is important for CORS with credentials
	headers: {
		common: {
			Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
		},
	},
})

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		const noAuthRoutes = routes.noAuthRoutes.map((item) => item.path)
		if (
			error.response?.status === 401 &&
			!noAuthRoutes.includes(window.location.pathname)
		) {
			localStorage.clear()
			window.location.href = '/'
		}
		return Promise.reject(error)
	}
)

function setAuthToken(token = '') {
	axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
}

const httpMethods = {
	get: axiosInstance.get,
	post: axiosInstance.post,
	put: axiosInstance.put,
	delete: axiosInstance.delete,
	setAuthToken,
}

export default httpMethods

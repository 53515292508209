import httpMethods from '..'

export const fileUpload = async (url, formData, token) => {
	return httpMethods
		.post(url, formData, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => res)
		.catch((error) => {
			throw error
		})
}

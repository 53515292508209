import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2492}
				height={height || 3282}
				viewBox="0 0 2492 3282"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_3413)">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M2320.09 2838.36L2194.52 2846.54C2142.58 2846.54 2062.3 2871.32 2006.82 2964.55C1992.95 2987.86 1981.75 3015.57 1969.93 3044.78C1928.49 3147.27 1879.59 3268.23 1682.08 3282H391.606C229.093 3274.53 65.1627 3166.11 0 2792.26V1706.07C0 1592.38 51.9413 1338.58 259.706 1232.84V1153.78C259.706 1141.58 268.206 1117.19 302.204 1117.19H1761.28C1775.45 1114.83 1803.78 1119.55 1803.78 1157.32V1224.59C1875.79 1253.3 2030.67 1382.01 2074.11 1667.13H2318.47C2369.23 1675.78 2475 1725.66 2492 1855.94V2645.93C2486.49 2711.76 2445.55 2813.22 2320.09 2838.36ZM2095.8 1873.05H2249.98C2267.24 1873.05 2301.53 1894.83 2300.59 1981.93C2299.65 2069.04 2300.2 2412.6 2300.59 2573.49C2299.02 2594.62 2286.7 2638.18 2249.98 2643.38L2095.8 2654.75C2080.9 2648.25 2052.96 2620.62 2060.5 2562.12V1910.43C2060.5 1897.97 2067.56 1873.05 2095.8 1873.05Z"
						fill={primary || '#0E1B21'}
					/>
					<rect
						x="377"
						y="1502"
						width="1316"
						height="1316"
						rx="50"
						fill="white"
					/>

					<text
						fill={textColor || 'white'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 225}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="40%" textAnchor="middle" y="3109.55">
							{text}
						</tspan>
					</text>

					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M1161.75 232.399C1160.26 236.217 1158.72 239.98 1157.14 243.682C1133.25 299.823 1094.78 356.094 1052.83 407.605C1019.9 448.045 983.107 487.626 945.997 524.361H1054.33C1086.72 488.68 1153.93 414.465 1166.32 358.751C1187.95 364.158 1229.69 405.397 1231.2 524.361H1320.96C1297.33 392.492 1228.13 295.091 1161.75 232.399ZM1041.19 5.11237C1067 -3.19658 1095.08 -2.27997 1129.89 14.6047C1249.35 72.5412 1477.63 265.04 1489.51 594.772H1583.03C1704.94 594.772 1803.78 693.574 1803.78 815.452V1005.45H257.344V815.452C257.344 693.574 356.177 594.772 478.094 594.772H654.594C657.148 574.15 667.674 554.805 684.791 541.413C754.052 487.227 853.935 397.638 928.328 306.282C965.692 260.398 993.842 217.427 1009.42 180.84C1025.64 142.73 1025.84 114.47 1025.84 114.47C1027.11 104.582 1028.33 94.1154 1029.56 83.5473L1029.56 83.5455C1032.87 55.0152 1036.27 25.7443 1041.19 5.11237ZM478.094 764.707H1583.03C1611.06 764.707 1633.79 787.427 1633.79 815.452V835.514H427.333V815.452C427.333 787.427 450.06 764.707 478.094 764.707Z"
						fill={secondary || 'white'}
					/>
				</g>
				<defs>
					<clipPath id="clip0_175_3413">
						<rect width="2492" height="3282" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="463" y="1588" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

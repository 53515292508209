import { useContext } from 'react'
import { UserSettingsContxt } from './userSettingContxt'
import { AuthContxt } from '../../store/authContxt'
import { USER_TYPE } from '../../utils/enum'

const UserSettingsTabs = () => {
	const { tab, tabHandler } = useContext(UserSettingsContxt)
	const { profile } = useContext(AuthContxt)
	let TABS = ['User Profile', 'Billing', 'Users']
	if (profile.userType !== USER_TYPE.USER) {
		if (profile.userType === USER_TYPE.ADMIN) {
			TABS = ['Users']
		}
	} else {
		TABS = []
	}
	return (
		<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
			{TABS?.map((item, index) => {
				return (
					<li
						className={`nav-item mt-2 cursor-pointer`}
						onClick={() => tabHandler(index)}
					>
						<span
							className={`nav-link text-active-primary ms-0 me-5 me-lg-8 pt-2 pb-3 pt-lg-4 pb-lg-5 ${
								index === tab ? 'active' : ''
							}`}
						>
							{item}
						</span>
					</li>
				)
			})}
		</ul>
	)
}

export default UserSettingsTabs

import React, { useState } from 'react'
import {
	FaArrowLeft,
	FaUser,
	FaStrikethrough,
	FaSmile,
	FaBold,
	FaItalic,
	FaFileUpload,
} from 'react-icons/fa'
import { AiOutlineFileSearch } from 'react-icons/ai'

const NewWhatsappMessage = () => {
	const [activeTab, setActiveTab] = useState('text')
	const [textCount, setTextCount] = useState(0)
	const [fileList, setFileList] = useState([])
	const [activeTab1, setActiveTab1] = useState('kt_tab_pane_9')

	const handleTabClick = (tabId) => {
		setActiveTab1(tabId)
	}

	const handleTabChange = (tab) => {
		setActiveTab(tab)
	}

	const handleFileUpload = (event) => {
		const files = Array.from(event.target.files)
		setFileList(files.map((file) => file.name))
	}

	return (
		<div
			className="card card-flush position-absolute scroll-y"
			style={{
				right: 0,
				bottom: 0,
				height: '100%',
				left: 0,
				marginLeft: '27px',
			}}
		>
			<div className="ps-10 pt-10 d-flex justify-content-between w-100">
				<h2 className="me-15 fs-2x mb-0">
					<a href="setup-whatsapp-campaign.html">
						<FaArrowLeft className="fs-1 me-3 text-gray-600 text-hover-dark" />
					</a>
					(campaign name)
				</h2>
				<div>
					<div className="d-flex gap-5 me-10">
						<button className="btn btn-info rounded-1">Save</button>
					</div>
				</div>
			</div>

			<div className="separator border-1 my-5"></div>

			<div className="card-body d-flex pt-0 w-100">
				<div className="row w-100">
					<div className="col-6 h-100">
						<div className="mt-15">
							<h3 className="fw-bolder fs-2 mb-3">Header</h3>
							<div className="border p-5 rounded-1">
								<div className="card-header px-0 pb-8 w-100">
									<div className="card-title mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0 w-100">
										<ul
											className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 w-100"
											role="tablist"
										>
											<li
												className="nav-item"
												style={{ width: '33.33%' }}
												role="presentation"
											>
												<div
													onClick={() => handleTabChange('text')}
													className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 fs-3 fw-bold ${
														activeTab === 'text' ? 'active' : ''
													}`}
													role="tab"
													aria-selected={activeTab === 'text'}
												>
													<FaUser className="fs-4 fw-bold me-4" /> Text
												</div>
											</li>
											<li
												className="nav-item"
												style={{ width: '33.33%' }}
												role="presentation"
											>
												<div
													onClick={() => handleTabChange('media')}
													className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 ms-2 fs-3 fw-bold ${
														activeTab === 'media' ? 'active' : ''
													}`}
													role="tab"
													aria-selected={activeTab === 'media'}
												>
													<FaFileUpload className="fs-4 fw-bold me-4" /> Media
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div className="tab-content" id="myTabContent">
									<div
										className={`tab-pane fade ${
											activeTab === 'text' ? 'show active' : ''
										}`}
										role="tabpanel"
									>
										<div className="mt-8">
											<div className="input-group bg-white rounded-1">
												<textarea
													id="personaliseinput"
													type="text"
													className="form-control rounded-1 position-relative pb-20"
													rows="2"
													style={{ resize: 'none' }}
													placeholder="Enter Header Text"
													onChange={(e) => setTextCount(e.target.value.length)}
												/>
												<div
													className="position-absolute bottom-0 w-100 border-top"
													style={{ zIndex: 1000 }}
												>
													<div className="d-flex justify-content-between align-items-center w-100">
														<a className="input-group-text bg-transparent border-0">
															<FaUser className="text-gray-800" />
														</a>
														<span className="text-gray-800 pe-2">
															<span className="text-dark fw-bold">
																{textCount}
															</span>{' '}
															/45
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										className={`tab-pane fade ${
											activeTab === 'media' ? 'show active' : ''
										}`}
										role="tabpanel"
									>
										<div className="drop-container mt-10 bg-light-info border-info h-150px rounded-1 dropzone d-flex justify-content-center align-items-center">
											<div>
												<FaFileUpload className="text-info fs-3x mb-5" />
												<p className="fs-4">Drag & Drop CSV File Here</p>
												<input
													type="file"
													id="fileInput"
													style={{ display: 'none' }}
													multiple
													onChange={handleFileUpload}
												/>
												<label htmlFor="fileInput" className="fs-5">
													or
													<span
														id="browseButton"
														className="text-info fw-bold fs-5"
													>
														<AiOutlineFileSearch /> Browse File
													</span>
													from device
												</label>
											</div>
										</div>
										<div className="uploading-animation" id="uploadAnimation">
											Uploading...
										</div>
										<ul className="file-list mt-6" id="fileList">
											{fileList.map((fileName, index) => (
												<li key={index}>{fileName}</li>
											))}
										</ul>
										<a
											className="text-info"
											style={{ textDecoration: 'underline' }}
										>
											Supported file types and sizes
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-15">
							<h3 className="fw-bolder fs-2 mb-3">Body</h3>
							<div className="border p-5 rounded-1">
								<div className="input-group bg-white rounded-1">
									<textarea
										id="personaliseinput1"
										type="text"
										className="form-control rounded-1 position-relative pb-20"
										rows="2"
										style={{ resize: 'none' }}
										placeholder="Enter Message"
									/>
									<div
										className="position-absolute bottom-0 w-100 border-top"
										style={{ zIndex: 1000 }}
									>
										<div className="d-flex justify-content-between align-items-center w-100">
											<div className="d-flex">
												<a
													className="input-group-text bg-transparent border-0"
													data-bs-toggle="tooltip"
													data-bs-original-title="Variable"
												>
													<FaUser className="text-gray-800" />
												</a>
												<a
													className="input-group-text bg-transparent border-0"
													data-bs-toggle="tooltip"
													data-bs-original-title="Strikethrough"
												>
													<FaStrikethrough className="text-gray-800" />
												</a>
												<a
													className="input-group-text bg-transparent border-0"
													data-bs-toggle="tooltip"
													data-bs-original-title="Emoji"
												>
													<FaSmile className="text-gray-800" />
												</a>
												<a
													className="input-group-text bg-transparent border-0"
													data-bs-toggle="tooltip"
													data-bs-original-title="Bold"
												>
													<FaBold className="text-gray-800" />
												</a>
												<a
													className="input-group-text bg-transparent border-0"
													data-bs-toggle="tooltip"
													data-bs-original-title="Italic"
												>
													<FaItalic className="text-gray-800" />
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-15">
							<h3 className="fw-bolder fs-2 mb-3">Buttons</h3>
							<div className="border p-5 rounded-1">
								<div className="card-header px-0 pb-8 w-100">
									<div className="card-title mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0 w-100">
										<ul
											className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 w-100"
											role="tablist"
										>
											<li
												className="nav-item"
												style={{ width: '33.33%' }}
												role="presentation"
											>
												<div
													onClick={() => handleTabClick('kt_tab_pane_9')}
													className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 fs-3 fw-bold ${
														activeTab === 'kt_tab_pane_9'
															? 'activate-cat active'
															: ''
													}`}
													aria-selected={activeTab === 'kt_tab_pane_9'}
													role="tab"
												>
													<i className="far fa-message-dots fs-4 fw-bold me-4"></i>
													Quick Replies
												</div>
											</li>
											<li
												className="nav-item"
												style={{ width: '33.33%' }}
												role="presentation"
											>
												<div
													onClick={() => handleTabClick('kt_tab_pane_10')}
													className={`nav-link w-100 btn text-dark rounded-1 border border-dark border-opacity-10 ms-2 fs-3 fw-bold ${
														activeTab === 'kt_tab_pane_10'
															? 'activate-cat active'
															: ''
													}`}
													aria-selected={activeTab === 'kt_tab_pane_10'}
													role="tab"
												>
													<i className="far fa-paper-plane-top fs-4 fw-bold me-4"></i>
													Call-to-actions
												</div>
											</li>
										</ul>
									</div>
								</div>

								<div className="tab-content" id="myTabContent">
									<div
										className={`tab-pane fade ${
											activeTab1 === 'kt_tab_pane_9' ? 'show active' : ''
										}`}
										id="kt_tab_pane_9"
										role="tabpanel"
									>
										<div>
											<button className="btn border rounded-1 border-light-gray-info border-opacity-10 bg-light text-info w-lg-100 py-4 mx-auto fs-4 fw-bold h-50px">
												<i className="fa-solid fa-plus me-2"></i>
												Add Button
											</button>
											<div
												className="px-5 py-8 border bg-light rounded-1 mt-8"
												style={{ borderColor: '#f3f0fb !important' }}
											>
												<label className="fs-5 fw-bold mb-2 mt-5">
													<span>Select template</span>
												</label>
												<select
													id="wa-temp-select"
													className="form-select rounded-1"
													data-control="select2"
													data-hide-search="true"
													data-placeholder="Select Template"
													tabIndex="0"
												>
													<option></option>
													<option value="1">Marketing opt-out</option>
													<option value="2">Custom</option>
												</select>
												<div className="mt-6">
													<label className="fw-bold fs-5 mb-2">
														Campaign Name
													</label>
													<input
														type="text"
														id="url-input"
														name="url-input"
														className="form-control rounded-1 h-50px"
														placeholder="Footer Text"
													/>
												</div>
												<div className="mt-6">
													<label className="fw-bold fs-5 mb-2">
														Button text
													</label>
													<input
														type="text"
														id="url-input"
														name="url-input"
														className="form-control rounded-1 h-50px"
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										className={`tab-pane fade ${
											activeTab1 === 'kt_tab_pane_10' ? 'show active' : ''
										}`}
										id="kt_tab_pane_10"
										role="tabpanel"
									>
										<div>
											<button className="btn border rounded-1 border-light-gray-info border-opacity-10 bg-light text-info w-lg-100 py-4 mx-auto fs-4 fw-bold h-50px">
												<i className="fa-solid fa-plus me-2"></i>
												Add Button
											</button>
											<div
												className="px-5 py-8 border bg-light rounded-1 mt-8"
												style={{ borderColor: '#f3f0fb !important' }}
											>
												<label className="fs-5 fw-bold mb-2 mt-5">
													<span>Type of action</span>
												</label>
												<select
													id="wa-temp-select"
													className="form-select rounded-1"
													data-control="select2"
													data-hide-search="true"
													data-placeholder="Select Template"
													tabIndex="-1"
												>
													<option></option>
													<option value="1">Call number</option>
													<option value="2">Link</option>
												</select>
												<div className="mt-6">
													<label className="fw-bold fs-5 mb-2">
														Button text
													</label>
													<input
														type="text"
														name="url-input"
														className="form-control rounded-1 h-50px"
														placeholder="Footer Text"
													/>
												</div>
												<div className="mt-6">
													<label className="fw-bold fs-5 mb-2">
														Phone number
													</label>
													<div className="d-flex">
														<div className="w-95px">
															<select
																className="form-select cselect95 w-95px me-0 h-50px"
																data-control="select2"
																data-hide-search="false"
																data-placeholder="+91"
															>
																<option></option>
																<option value="1">+91</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
																<option value="5">5</option>
																<option value="6">6</option>
																<option value="7">7</option>
																<option value="8">8</option>
																<option value="9">9</option>
																<option value="10">10</option>
																<option value="11">11</option>
																<option value="12">12</option>
															</select>
														</div>
														<input
															type="text"
															placeholder="Enter Phone number"
															className="form-control rounded-1 h-50px w-100 ms-2"
														/>
													</div>
												</div>
												<div className="mt-6">
													<label className="fw-bold fs-5 mb-2">URL</label>
													<input
														type="text"
														name="url-input"
														className="form-control rounded-1 h-50px"
														placeholder="Enter URL here"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-6 pt-5 pe-0 position-relative">
						<div
							className="rounded-1 h-100 pt-15 position-sticky"
							style={{
								border: '1px solid #e9e6f1',
								top: '50px',
								backgroundColor: '#F7F5FD',
							}}
						>
							<div
								className="card border-0 card-flush d-flex justify-content-center bg-transparent position-sticky p-3 pt-0 mb-0"
								style={{ top: '60px' }}
							>
								<img
									id="mobile-content"
									src="../app/assets/media/whatsapp-campaign/chat.png"
									width="300"
									height="600"
									className="mx-auto mb-8"
									style={{ border: '10px solid black', borderRadius: '30px' }}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NewWhatsappMessage

import { useEffect, useState } from 'react'
import UserSettingsApi from '../../services/http/userSettings'
import { Spinner } from 'reactstrap'
import PaymentCard from './paymentCard'
import { Link } from 'react-router-dom'

const PaymentMethods = ({ setValue }) => {
	const [selectedCard, setSelectedCard] = useState(null)
	const [loading, setLoading] = useState(false)
	const [cards, setCards] = useState([])

	const fetchCards = async () => {
		try {
			setLoading(true)
			const response = await UserSettingsApi.paymentCards()
			const data = response?.data?.data
			if (data?.length > 0) {
				setCards(data)
				setValue('card', data[0]?.id)
				setSelectedCard(data[0]?.id)
			}
			setLoading(false)
		} catch (e) {
			console.log('Error while fetching cards: ', e)
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchCards()
	}, [])

	return (
		<div className="mb-lg-5">
			<div className="card-header min-h-50px p-0 mb-2 cursor-pointer">
				<div className="card-title">
					<h3>Payment Methods</h3>
				</div>
				<div class="card-toolbar m-0">
					<ul
						class="nav nav-stretch nav-line-tabs border-transparent"
						role="tablist"
					>
						<li class="nav-item" role="presentation">
							<a
								id="kt_billing_creditcard_tab"
								class="nav-link fs-5 fw-bold me-5 active"
								data-bs-toggle="tab"
								role="tab"
								href="#kt_billing_creditcard"
								aria-selected="true"
							>
								Credit / Debit Card
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div className="card-body py-lg-10 py-5 pb-3 px-0">
				{loading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							height: '200px',
						}}
					>
						<Spinner />
					</div>
				) : (
					<div className="row gx-lg-10 gx-5">
						{cards && cards?.length > 0 ? (
							cards?.map((card) => (
								<PaymentCard
									key={card?.id}
									cardHandler={() => setValue('card', card?.id)}
									card={card}
									selectedCard={selectedCard}
									setSelectedCard={setSelectedCard}
								/>
							))
						) : (
							<div>
								<span>{`Please add Card first. Go to `}</span>
								<Link to={'/user-settings'}>{`User settings`}</Link>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default PaymentMethods

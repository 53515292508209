import Frames from '../components/qr-codes/create-qr-code/frames-svg'
import SharedApi from '../services/http/shared'
import ReactDOMServer from 'react-dom/server'
import html2canvas from 'html2canvas' // Ensure this import is correct

const imageCopyHandler = (qr, callback = () => {}) => {
	const auth_token = localStorage.getItem('auth_token')
	SharedApi.disableCors(auth_token, { payload: `https:${qr?.qr}` })
		.then(async ({ data }) => {
			const arrayBuffer = Uint8Array.from(data?.data?.data).buffer
			const blob = new Blob([arrayBuffer], { type: 'image/png' })

			try {
				if (!qr.frame) {
					// Handle the case without a frame: directly copy the image to the clipboard
					const qrImg = [new ClipboardItem({ 'image/png': blob })]
					try {
						await navigator.clipboard.write(qrImg)
						callback()

						console.log('Image copied')
					} catch (error) {
						console.error('Unable to copy image to clipboard', error)
					}
				} else {
					// Handle the case with a frame: render the image with the frame and copy
					const uint8Array = Uint8Array.from(data?.data?.data)

					const toBase64 = (byteArray) => {
						let binaryString = ''
						const len = byteArray.byteLength
						for (let i = 0; i < len; i++) {
							binaryString += String.fromCharCode(byteArray[i])
						}
						return window.btoa(binaryString)
					}

					const base64data = toBase64(uint8Array)

					// Render the SVG string for the frame
					const svgString = ReactDOMServer.renderToStaticMarkup(
						<Frames
							type={qr?.frame.type}
							height={qr?.height || 400}
							width={qr?.width || 400}
							minFontSize={qr?.frame.minFontSize}
							maxFontSize={qr?.frame.maxFontSize}
							url={`data:image/png;base64,${base64data}`} // Use Base64 in the URL
							text={qr?.text}
							primary={qr?.primary}
							secondary={qr?.secondary}
							textColor={qr?.textColor}
						/>
					)

					// Create a temporary div to hold the rendered frame and QR code
					const tempDiv = document.createElement('div')
					tempDiv.style.position = 'absolute'
					tempDiv.style.left = '-9999px' // Hide it off-screen
					document.body.appendChild(tempDiv)
					tempDiv.innerHTML = svgString // Insert the SVG into the temporary div

					setTimeout(() => {
						html2canvas(tempDiv, { backgroundColor: null })
							.then((canvas) => {
								canvas.toBlob(async (canvasBlob) => {
									if (canvasBlob) {
										try {
											// Copy the image to the clipboard
											await navigator.clipboard.write([
												new ClipboardItem({ 'image/png': canvasBlob }),
											])
											callback()
										} catch (err) {
											console.error('Failed to copy image to clipboard:', err)
										}
									}
								}, 'image/png')

								// Clean up by removing the temporary div
								document.body.removeChild(tempDiv)
							})
							.catch((error) => {
								console.error('Error generating canvas for QR code:', error)
								// Ensure the temporary div is removed even on error
								document.body.removeChild(tempDiv)
							})
					}, 100) // Delay to allow for HTML rendering
				}
			} catch (error) {
				console.error('Error processing image copy:', error)
			}
		})
		.catch((error) => {
			console.log('Error while creating qr code:', error)
		})
}

export default imageCopyHandler

import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	PinterestShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from 'react-share'

const mediaUrl =
	'https://divsly.com/assets/img/home-banner/short-links-sms-whatsapp.png'

const ShareDropdown = ({ list }) => {
	const shortlink = `https://${list.brandedDomain}/${encodeURIComponent(list.slashTag)}`
	return (
		<Dropdown className="d-inline nospce">
			<Dropdown.Toggle
				id="dropdown-autoclose-true"
				className="p-0"
				variant="bg-none"
			>
				<i
					title="Share"
					className="fa-regular fa-share-from-square fs-2 ms-5 text-gray-700 cursor-pointer"
				>
					{' '}
				</i>
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item>
					<EmailShareButton url={shortlink}>
						<i className="fas fa-envelope me-2"></i>
						Email
					</EmailShareButton>
				</Dropdown.Item>
				<Dropdown.Item>
					<FacebookShareButton url={shortlink}>
						<i className="fab fa-facebook me-2"></i> Facebook
					</FacebookShareButton>
				</Dropdown.Item>
				<Dropdown.Item>
					<LinkedinShareButton url={shortlink}>
						{(shareCount) => (
							<span className="myShareCountWrapper">{shareCount}</span>
						)}
						<i className="fab fa-linkedin me-2"></i> Linkedin
					</LinkedinShareButton>
				</Dropdown.Item>
				{/* <Dropdown.Item>
          <PinterestShareButton
            description={list?.title}
            url={shortlink}
            media={mediaUrl}
          >
            <i className="fab fa-pinterest me-2"></i> Pinterest
          </PinterestShareButton>
        </Dropdown.Item> */}
				<Dropdown.Item>
					<TwitterShareButton url={shortlink}>
						<i className="fab fa-twitter me-2"></i> Twitter
					</TwitterShareButton>
				</Dropdown.Item>
				<Dropdown.Item>
					<WhatsappShareButton url={shortlink}>
						<i className="fab fa-whatsapp me-2"></i>
						Whatsapp
					</WhatsappShareButton>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default ShareDropdown

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Input } from 'reactstrap'
import Sidebar from '../../components/common/sidebar'

const createWhatsappCampaign = () => {
	return (
		<div>
			<div className="w-full d-flex">
				<div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
					<Sidebar />
				</div>
				<div className="customDashboardSize">
					<div className="d-flex flex-column w-100 flex-root app-root">
						<div className="app-page flex-column flex-column-fluid">
							<div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
								<div className="app-main flex-column flex-row-fluid">
									<Card className="card card-flush"></Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default createWhatsappCampaign

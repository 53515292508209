import httpMethods from '..'

const WhatsappTemplateAPI = {
	createWhatsappTemplate: async (payload) => {
		return httpMethods.post(`/whatsapp-templates`, payload).then((res) => res)
	},

	createGalleryWhatsappTemplate: async (token, values) => {
		return httpMethods
			.post(`/whatsapp-templates/gallery`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getAllWhatsappTemplates: async (params) => {
		return httpMethods
			.get(`/whatsapp-templates`, {
				params,
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	updateWhatsappTemplates: async (id, values) => {
		return httpMethods.put(`/whatsapp-templates/${id}`, values)
	},

	deleteWhatsappTemplates: async (token, id) => {
		return httpMethods
			.delete(`/whatsapp-templates/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getWhatsappTemplateById: async (token, id) => {
		return httpMethods
			.get(`/whatsapp-templates/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	fileUpload: async (token, body) => {
		return httpMethods
			.post(`/whatsapp-templates/file/upload`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},
}

export default WhatsappTemplateAPI

import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import AuthApi from '../../services/http/auth'
import { Spinner } from 'reactstrap'

const VerifyAccount = () => {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token') || ''
	const id = searchParams.get('id') || ''

	useEffect(() => {
		const verifyAccountHandler = async (data) => {
			try {
				const payload = {
					token,
					id: +id,
				}
				await AuthApi.verifyAccount(payload)
				navigate('/login')
			} catch (e) {
				console.log(e?.response?.data?.message || 'Something went wrong' || e)
			}
		}

		verifyAccountHandler()
	}, [id, token, navigate])
	return (
		<>
			<Spinner />
		</>
	)
}

export default VerifyAccount

import { Spinner } from 'reactstrap'
import AnalyticsNotFound from './analyticsNotFound'
import ApexUsersChart from './apex-charts/usersChart'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import AdminAPI from '../../services/http/admin'
import convertToK from '../../utils/convertToK'

const UsersChart = () => {
	const [loading, setLoading] = useState(false)
	const [userStats, setUserStats] = useState({})

	const fetchUserStats = async () => {
		try {
			setLoading(true)
			const response = await AdminAPI.getCurrentWeekUsers()
			if (!response?.data?.error) {
				setUserStats(response?.data?.data)
			}
			setLoading(false)
		} catch (e) {
			console.log('Error while fetching user stats: ', e)
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchUserStats()
	}, [])

	const data = userStats?.clicks?.map((item) => item?._count?.date)
	const categories = userStats?.clicks?.map((item) =>
		dayjs(item?.date).format('MMM DD')
	)

	return (
		<div class="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
			<div class="card card-flush overflow-hidden h-md-100">
				<div class="card-header py-5">
					<h3 class="card-title align-items-start flex-column">
						<span class="card-label fs-1 fw-bold text-gray-900">
							Users this week
						</span>
						<span class="fs-6 fw-semibold text-gray-500 me-1">
							From all plan
						</span>
					</h3>

					<div class="card-toolbar"></div>
				</div>
				<div class="card-body border-top d-flex justify-content-between flex-column pb-1 px-0">
					<div class="px-9 mb-5">
						<div class="d-flex mb-2" style={{ alignItems: 'flex-end' }}>
							<span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
								{convertToK(userStats?.users || 0)}
							</span>
							<span class="fs-6 fw-semibold text-gray-500 me-1">Users</span>
						</div>
					</div>

					<div
						class="min-h-auto ps-2"
						style={{ height: '450px', minHeight: '465px' }}
					>
						{loading ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
									height: '250px',
								}}
							>
								<Spinner />
							</div>
						) : userStats?.clicks?.length > 0 ? (
							<ApexUsersChart
								stats={{ data: [0, ...data], categories: ['', ...categories] }}
							/>
						) : (
							<AnalyticsNotFound />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default UsersChart

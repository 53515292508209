import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
	FormGroup,
	Input,
	InputGroup,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
} from 'reactstrap'
import WhatsappTemplateAPI from '../../services/http/whatsappTemplate'

const WhatsappMessageModal = (props) => {
	const [selectedTemplateId, setSelectedTemplateId] = useState()
	const [templates, setTemplates] = useState([])
	const [messageText, setMessageText] = useState('')

	const handleSave = () => {
		const payload = {
			name: 'Template 1',
			json: {
				message: messageText,
			},
		}

		if (selectedTemplateId === undefined) {
			WhatsappTemplateAPI.createWhatsappTemplate(payload)
		} else {
			WhatsappTemplateAPI.updateWhatsappTemplates(selectedTemplateId, payload)
		}

		props.onSave(selectedTemplateId)
	}

	const handleTemplateChange = (event) => {
		setSelectedTemplateId(event.target.value)
	}

	const handleMessageChange = (event) => {
		setMessageText(event.target.value)
	}

	const fetchWhatsappTemplates = async () => {
		try {
			const response = await WhatsappTemplateAPI.getAllWhatsappTemplates()
			setTemplates(response.data)
		} catch (error) {
			console.error('Failed to fetch whatsapp templates', error)
		}
	}

	useEffect(() => {
		fetchWhatsappTemplates()
	}, [])

	useEffect(() => {
		setMessageText(
			templates.find((template) => template.id == selectedTemplateId)?.json
				?.message
		)
	}, [selectedTemplateId, templates])

	useEffect(() => {
		setSelectedTemplateId(props.templateId)
	}, [props.templateId])

	return (
		<Modal isOpen={props.isOpen} toggle={props.toggle}>
			<ModalHeader toggle={props.toggle}>Select Template</ModalHeader>
			<ModalBody>
				<FormGroup>
					<Label for="select-template">Select Whatsapp Template</Label>
					<InputGroup>
						<Input
							type="select"
							name="selectSender"
							id="select-template"
							value={selectedTemplateId}
							onChange={handleTemplateChange}
						>
							<option value="">Choose...</option>
							{templates?.map((template) => (
								<option
									key={`whatsapp-template-${template.id}`}
									value={template.id}
								>
									{template.name}
								</option>
							))}
						</Input>
					</InputGroup>
				</FormGroup>

				<FormGroup>
					<Label for="whatsapp-message">Enter message</Label>
					<Input
						id="whatsapp-message"
						type="textarea"
						value={messageText}
						placeholder="Enter message content"
						onChange={handleMessageChange}
					/>
				</FormGroup>
				<div className="d-flex justify-content-end mt-3">
					<button
						type="button"
						className="btn text-nowrap rounded-1 btn-secondary"
						onClick={props.toggle}
					>
						Cancel
					</button>
					<button
						type="button"
						className="btn text-nowrap rounded-1 btn-info text-white"
						color="danger"
						onClick={handleSave}
						style={{ marginLeft: '10px' }}
					>
						Save
					</button>
				</div>
			</ModalBody>
		</Modal>
	)
}

WhatsappMessageModal.propTypes = {
	userId: PropTypes.number,
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
	onSave: PropTypes.func,
	templateId: PropTypes.number,
}

export default WhatsappMessageModal

import React, { useState } from 'react'
import { FormGroup, Label, Input, Col, Row } from 'reactstrap'
import QRCode from 'qrcode.react'
import { getUtmQuery } from '../../../utils'
import BioPages from './bioPages'

const AddNewShortLinkQR = ({ form }) => {
	const { getValues, setValue } = form
	const [isGenerateQR, setIsGenerateQR] = useState(false)
	const [generatedQRCode, setGeneratedQRCode] = useState('http://divsly.com')

	const downloadQR = () => {
		const title = getValues('title')
		const filename = title || 'QR'
		const canvas = document.getElementById('QR')
		const pngUrl = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream')
		let downloadLink = document.createElement('a')
		downloadLink.href = pngUrl
		downloadLink.download = `${filename}.png`
		document.body.appendChild(downloadLink)
		downloadLink.click()
		document.body.removeChild(downloadLink)
	}

	const generateQRCode = (url) => {
		if (url.trim() === '') {
			return
		}
		const qrCodeData = url
		setGeneratedQRCode(qrCodeData)
	}

	const qrHandler = () => {
		setIsGenerateQR(!isGenerateQR)
		setValue('isQrGenerated', !isGenerateQR)
		const {
			destinationUrl,
			utm_id,
			utm_campaign,
			utm_content,
			utm_medium,
			utm_source,
			utm_term,
		} = getValues()
		const utmQuery = getUtmQuery({
			utm_id,
			utm_campaign,
			utm_content,
			utm_medium,
			utm_source,
			utm_term,
		})
		generateQRCode(destinationUrl + utmQuery)
	}

	return (
		<Row>
			<Col md={6}>
				<FormGroup>
					<Label className="d-flex flex-stack mb-5 w-100 border border-dotted border-info cursor-pointer rounded-1 p-5 min-h-95px">
						<span className="d-flex align-items-center me-2">
							<span className="symbol symbol-50px me-6">
								<span className="symbol-label bg-light-info rounded-1">
									<i className="fa-solid fa-qrcode fs-1 text-info opacity-75"></i>
								</span>
							</span>

							<span className="d-flex flex-column">
								<span className="fw-bold fs-5">Generate QR Code </span>
								<span className="fs-7 text-muted">
									Create free QR for this link
								</span>
							</span>
						</span>
						<span className="form-check form-check-custom form-check-info form-check-solid">
							<Input
								className="form-check-input"
								type="checkbox"
								id="qr-gen-checkbox"
								onChange={qrHandler}
							/>
						</span>
					</Label>
					{isGenerateQR ? (
						<div className="mt-5 border-info  h-100 text-center w-100 me-0 ms-0 row bg-light-info align-items-center rounded-1 p-5 py-7">
							<div className="col-lg-8 col-12 text-start">
								<h3 className="pt-3 fs-6">Free QR For your Link</h3>
								<p>Generate a QR Code to share anywhere. </p>
								<span className="text-danger">
									<i className="ki-duotone ki-information fs-6 text-danger">
										<i className="path1"></i>
										<i className="path2"></i>
										<i className="path3"></i>
									</i>
									<span className="opacity-50 text-danger">
										This is Temporary QR Code
									</span>
								</span>
							</div>
							<div className="col-lg-4 col-12">
								<div
									onClick={downloadQR}
									className="mt-lg-0 mt-5"
									id="qr-bg"
									style={{ backgroundColor: '#ffffff', cursor: 'pointer' }}
								>
									<QRCode
										id="QR"
										value={generatedQRCode}
										className="w-100 h-auto p-5 p-lg-1 tempqrgen"
									/>
								</div>
							</div>
						</div>
					) : null}
				</FormGroup>
			</Col>
			<BioPages form={form} />
		</Row>
	)
}

export default AddNewShortLinkQR

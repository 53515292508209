import PageWrapper from '../../components/wrapper/pageWrapper'
import Subscribers from '../../admin-side/subscribers'
const Page = () => {
	return (
		<PageWrapper>
			<Subscribers />
		</PageWrapper>
	)
}

export default Page

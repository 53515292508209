import { useState } from 'react'
import { MESSAGE } from '../utils/enum/responseMessage'

const useCustomMessage = () => {
	const [msg, setMsg] = useState('')
	const [classType, setClassType] = useState(null)

	const classMapper = {
		info: 'link-info',
		warning: 'link-warning',
		error: 'link-danger',
	}

	const messageHandler = (msg, success = null) => {
		const customMessage = MESSAGE[msg] || msg
		setMsg(customMessage)

		if (success === null) {
			setClassType('warning')
		} else if (success) {
			setClassType('info')
		} else {
			setClassType('error')
		}

		setTimeout(() => {
			setMsg(null)
			setClassType(null)
		}, 5 * 1000)
	}

	const message = msg ? (
		<span className={`${classMapper[classType]} fs-6 fw-bold`}>
			{msg?.label || msg}
		</span>
	) : null

	return {
		message,
		messageHandler,
	}
}

export default useCustomMessage

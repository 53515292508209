import PaymentMethods from './paymentMethods'
import Plan from './plan'
import Summary from './summary'
import ProfileDetails from './profileDetails'

import { useForm } from 'react-hook-form'
import { CardBody } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useContext, useEffect, useState } from 'react'
import SubscriptionApi from '../../services/http/subscription'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { MONTHLY_STARTER } from '../../utils/planLookUpKeys'
import { AuthContxt } from '../../store/authContxt'
import BillingAddress from '../user-settings/billing-info/billing/addess'
import { EMAIL_REGEX } from '../../utils/enum'
import { UserSettingsContxt } from '../user-settings/userSettingContxt'

const newUserValidation = yup.object().shape({
	firstName: yup.string().required('Please fill this field!'),
	lastName: yup.string().required('Please fill this field!'),
	email: yup
		.string()
		.required('Please fill this field!')
		.matches(EMAIL_REGEX, 'Please enter a valid email'),
	mobile: yup.number().required('Please fill this field!'),
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const Index = () => {
	const { profile } = useContext(AuthContxt)

	const [stripeCustomer, setStripeCustomer] = useState(null)
	const [planPrices, setPlanPrices] = useState([])
	const form = useForm({
		resolver: yupResolver(newUserValidation),
		defaultValues: {
			plan: MONTHLY_STARTER,
		},
	})
	const { setValue } = form

	const planHandler = (data) => {
		setValue('plan', data)
	}

	const profileDetailsHandler = (data) => {
		const { company, firstName, lastName, mobile, email, vatId } = data || {}
		setValue('company', company)
		setValue('firstName', firstName)
		setValue('lastName', lastName)
		setValue('mobile', mobile)
		setValue('email', email)
		setValue('vatId', vatId)
	}

	useEffect(() => {
		const fetchCustomer = async () => {
			try {
				const response = await SubscriptionApi.getCustomer()

				setValue('firstName', profile?.firstName || '')
				setValue('lastName', profile?.lastName || '')
				setValue('mobile', profile?.mobile || '')
				setValue('email', profile?.email)
				setValue('vatId', profile?.vatId)
				setValue('company', profile?.company)

				const data = response?.data?.data

				setStripeCustomer(data)
				if (data?.metadata?.vatId) {
					setValue('vatId', data?.metadata?.vatId)
				}
				if (data?.metadata?.company) {
					setValue('company', data?.metadata?.company)
				}
			} catch (e) {}
		}
		fetchCustomer()
	}, [profile, setValue])

	useEffect(() => {
		const fetchPlansPrices = async () => {
			try {
				const response = await SubscriptionApi.getPlansPrices()
				if (!response.data.error) {
					setPlanPrices(response?.data?.data)
				}
			} catch (e) {}
		}
		fetchPlansPrices()
	}, [])

	return (
		<>
			<Elements stripe={stripePromise}>
				<CardBody class="card-body border-top pt-0">
					<div class="d-flex flex-column flex-xl-row">
						<div class="flex-lg-row-fluid me-lg-10">
							<Plan
								planHandler={planHandler}
								planPrices={planPrices}
								form={form}
							/>

							<ProfileDetails
								profileDetailsHandler={profileDetailsHandler}
								stripeCustomer={stripeCustomer}
							/>
							<PaymentMethods setValue={setValue} />
							<BillingAddress page={'subscription'} />
						</div>
						<Summary form={form} planPrices={planPrices} />
					</div>
				</CardBody>
			</Elements>
		</>
	)
}

export default Index

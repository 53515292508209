import React from 'react'

const IconFrame1 = ({
	primary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 1392}
				height={height || 1900}
				viewBox="0 0 1392 1900"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_1787_1789)">
					<rect width={1392} height={1900} fill={primary || '#0E1B21'} />
					<rect x={56} y={56} width={1280} height={1280} rx={61} fill="white" />
					<path
						d="M695.5 1463L561.699 1325.75L829.301 1325.75L695.5 1467Z"
						fill="white"
					></path>
					<text
						fill={textColor || 'white'}
						fontFamily="Arial"
						fontSize={fontSize || 225}
						fontWeight="bold"
						x="50%"
						y="1742.45"
						textAnchor="middle"
					>
						{text}
					</text>
				</g>
				<defs>
					<clipPath id="clip0_1787_1789">
						<rect width="1392" height="1900" rx="115" fill="white" />
					</clipPath>
				</defs>
				<image href={url} x="125" y="125" width="1142" height="1142" />
			</svg>
		</>
	)
}

export default IconFrame1

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { FaPencilAlt } from 'react-icons/fa'
import globe from '../../../assets/qr-logos/globe.png'
import scanme from '../../../assets/qr-logos/scan-me.png'
import facebook from '../../../assets/qr-logos/facebook.png'
import instagram from '../../../assets/qr-logos/instagram.png'
import linkedin from '../../../assets/qr-logos/linkedin.png'
import twitter from '../../../assets/qr-logos/twitter.png'
import youtube from '../../../assets/qr-logos/youtube.png'
import pintrest from '../../../assets/qr-logos/pintrest.png'
import whatsapp from '../../../assets/qr-logos/whatsapp.png'
import behance from '../../../assets/qr-logos/behance.png'
import dribble from '../../../assets/qr-logos/dribble.png'
import github from '../../../assets/qr-logos/github.png'
import medium from '../../../assets/qr-logos/medium.png'
import reddit from '../../../assets/qr-logos/reddit.png'
import snapchat from '../../../assets/qr-logos/snapchat.png'
import telegram from '../../../assets/qr-logos/telegram.png'
import tiktok from '../../../assets/qr-logos/tiktok.png'
import scanmeoutline from '../../../assets/qr-logos/scan-me-outline.png'
import usePlan from '../../../hooks/usePlan'
import { Pencil } from 'lucide-react'

const CustomLogos = ({ handleDefaultLogos, errorMsg }) => {
	const { frame, preset, pattern, corner, plan, imagesUrl, logoToShow } =
		usePlan()

	const [uploadStatuses, setUploadStatuses] = useState({})
	const [logos, setLogos] = useState(imagesUrl)

	useEffect(() => {
		setLogos(imagesUrl)
	}, [imagesUrl])

	const handleFileUpdate = async (file, oldFilePath, index) => {
		const token = localStorage?.getItem('auth_token')

		if (!file) {
			setUploadStatuses((prev) => ({
				...prev,
				[oldFilePath]: 'No image selected',
			}))
			return
		}

		console.log('Old File Path:', oldFilePath)

		const formData = new FormData()
		formData.append('qrCode', file)
		formData.append('oldFilePath', oldFilePath)

		try {
			setUploadStatuses((prev) => ({
				...prev,
				[oldFilePath]: 'Updating...',
			}))

			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}/update-qr`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			const newFilePath = response.data.qrCodes
			console.log('New File Path:', newFilePath)

			// Update imagesUrl state immutably
			setLogos((prevLogos) => {
				const updatedLogos = [...prevLogos]
				updatedLogos[index] = newFilePath
				return updatedLogos
			})

			setUploadStatuses((prev) => ({
				...prev,
				[oldFilePath]: 'Update Successful!',
			}))

			setTimeout(() => {
				setUploadStatuses((prev) => {
					const newStatuses = { ...prev }
					delete newStatuses[oldFilePath]
					return newStatuses
				})
			}, 3000)
		} catch (error) {
			setUploadStatuses((prev) => ({
				...prev,
				[oldFilePath]: 'Update Failed.',
			}))
			console.error('Error updating file:', error)

			setTimeout(() => {
				setUploadStatuses((prev) => {
					const newStatuses = { ...prev }
					delete newStatuses[oldFilePath]
					return newStatuses
				})
			}, 3000)
		}
	}

	const predefinedLogos = [
		globe,
		scanme,
		scanmeoutline,
		facebook,
		instagram,
		linkedin,
		twitter,
		youtube,
		pintrest,
		whatsapp,
		snapchat,
		tiktok,
		reddit,
		telegram,
		behance,
		dribble,
		github,
		medium,
	]

	return (
		<div className="d-flex flex-wrap">
			{errorMsg?.image && (
				<div className="w-full mb-4">
					<span className="error-text text-danger">{errorMsg.image}</span>
				</div>
			)}

			{logos.map((logo, index) => {
				const fullImageUrl = logo.includes('http')
					? logo
					: `${process.env.REACT_APP_IMAGE_URL}${logo}`
				return (
					<div
						key={`custom-${index}`}
						className="bg-white p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn"
						style={{ position: 'relative' }}
					>
						<div
							onClick={() => handleDefaultLogos(fullImageUrl)}
							className="w-full h-full d-flex justify-content-center align-items-center"
						>
							<img
								className="w-100"
								src={fullImageUrl}
								alt={`Custom Logo ${index + 1}`}
							/>
						</div>
						<div
							className="position-absolute top-0 right-0 p-1 cursor-pointer"
							onClick={() =>
								document.getElementById(`edit-custom-${index}`).click()
							}
						>
							<Pencil
								style={{
									width: '25px',
									height: '25px',
									color: 'gray',
									backgroundColor: 'white',
									padding: '5px',
									borderRadius: '50%',
									boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
									position: 'absolute',
									top: '-5',
									left: '30',
								}}
							/>
						</div>
						<input
							id={`edit-custom-${index}`}
							type="file"
							className="d-none"
							accept="image/*"
							onChange={(e) => {
								const file = e.target.files[0]
								if (file) {
									handleFileUpdate(file, logo, index)
								}
							}}
						/>
						{uploadStatuses[logo] && (
							<div
								className="position-absolute bottom-0 start-0 w-100 text-center text-white bg-dark bg-opacity-50 rounded-bottom"
								style={{ fontSize: '0.7rem', padding: '2px 0' }}
							>
								{uploadStatuses[logo]}
							</div>
						)}
					</div>
				)
			})}

			{predefinedLogos.map((logo, index) => {
				const isDisabled = index >= logoToShow
				return (
					<div
						key={`predefined-${index}`}
						onClick={() => !isDisabled && handleDefaultLogos(logo)}
						className={`bg-white p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn ${
							isDisabled ? 'opacity-50' : ''
						}`}
						style={{
							pointerEvents: isDisabled ? 'none' : 'auto',
						}}
					>
						<img className="w-100" src={logo} alt="Logo" />
					</div>
				)
			})}
		</div>
	)
}

export default CustomLogos

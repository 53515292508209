import { he } from 'date-fns/locale'
import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2506}
				height={height || 2597}
				viewBox="0 0 2506 2597"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_1740)">
					<rect
						x="953"
						y="1003"
						width="1316"
						height="1315.96"
						rx="50"
						fill="white"
					/>
					<path
						d="M1195.74 0H221.645C187.418 0 159.674 27.7435 159.674 61.969V106.818H1257.71V61.969C1257.71 27.7435 1229.97 0 1195.74 0Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M125.737 669.046L270.946 2506.78H667.696C667.696 2506.78 638.828 2453.19 632.477 2421.43C623.817 2378.13 629.013 2309.04 629.013 2309.04H909.609V1010.41H625.549C625.549 1010.41 641.604 879.529 679.82 820.469C724.277 751.766 793.56 669.046 793.56 669.046H125.737Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M1379.01 230.76H38.3778L0 545.096H1417.39L1379.01 230.76Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M2017.12 575.831H1146.1C907.418 575.831 708.268 748.769 665.735 976.559H2497.49C2454.95 748.774 2255.79 575.831 2017.12 575.831Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M2499.82 2345.01H663.408C661.331 2345.01 659.285 2344.91 657.219 2344.85V2365.65C657.219 2493.21 760.422 2597 887.275 2597H2275.94C2402.8 2597 2506 2493.22 2506 2365.66V2344.86C2503.94 2344.91 2501.9 2345.01 2499.82 2345.01Z"
						fill={primary || '#0E1B21'}
					/>
					<text
						fill={textColor || 'white'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 224}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="63%" textAnchor="middle" y="830">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_1740">
						<rect width="2506" height="2597" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="1039" y="1089" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

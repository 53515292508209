import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 1874}
				height={height || 1999}
				viewBox="0 0 1874 1999"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_10792)">
					<path
						d="M3.5892 1535C-11.8384 1486.49 24.3985 1437 75.3417 1437H1798.66C1849.34 1437 1885.54 1486.01 1870.6 1534.39L1843.05 1623.66C1838.58 1638.13 1838.59 1653.6 1843.07 1668.07L1870.48 1756.54C1885.47 1804.94 1849.26 1854 1798.56 1854H76.4571C25.1899 1854 -11.0838 1803.92 4.94214 1755.27L32.2828 1672.26C37.231 1657.24 37.3141 1641.04 32.5204 1625.97L3.5892 1535Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M481 35.5H1393C1497.66 35.5 1582.5 120.342 1582.5 225V1774C1582.5 1878.66 1497.66 1963.5 1393 1963.5H1122H925.5H743.5H481C376.342 1963.5 291.5 1878.66 291.5 1774V225C291.5 120.342 376.342 35.5 481 35.5Z"
						fill={primary || '#0E1B21'}
						stroke={secondary || 'white'}
						stroke-width="71"
					/>
					<rect
						x="1760"
						y="1801"
						width="1646"
						height="500"
						rx="97"
						transform="rotate(-180 1760 1801)"
						fill={secondary || 'white'}
					/>

					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 224}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="1628.66">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_10792">
						<rect width="1874" height="1999" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="425" y="170" width="1024" height="1024" />
			</svg>
		</>
	)
}

export default IconFrame5

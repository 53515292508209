import React from 'react'
import BusinessPreview from './businesspreview'
import BcardPreview from './bcardpreview'
import BarcodePreview from './barcodepreview'

const MainPreview = ({ formData }) => {
	console.log(formData)
	return (
		<>
			{formData?.qrType === 'business' ? (
				<BusinessPreview formData={formData} />
			) : formData?.qrType === 'vcard' ? (
				<BcardPreview formData={formData} />
			) : (
				formData?.qrType === 'barcode' && <BarcodePreview />
			)}
		</>
	)
}

export default MainPreview

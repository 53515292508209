import httpMethods from '..'

const LinkInBioApi = {
	createLinkInBio: async (values) => {
		const token = localStorage.getItem('auth_token')
		return httpMethods
			.post(`/link-in-bio`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	validLinkInBio: async (token, values) => {
		return httpMethods
			.post(`/valid-link-in-bio`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	getLinkInBioPages: async (token) => {
		return httpMethods
			.get(`/link-in-bio`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	getLinkInBioPage: async (token, param) => {
		return httpMethods
			.get(`/link-in-bio/${param}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	getLinkInBioPageButtons: async (values) => {
		const token = localStorage.getItem('auth_token')
		return httpMethods
			.post(`/bulk-short-link`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	updateLinkInBioPage: async (id, values) => {
		const token = localStorage.getItem('auth_token')
		return httpMethods
			.put(`/update-link-in-bio/${id}`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	updateLinkInBioPageUrl: async (token, id, values) => {
		return httpMethods
			.put(`/update-link-in-bio-url/${id}`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
	deleteLinkInBioPage: async (token, id) => {
		return httpMethods
			.delete(`/link-in-bio/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
}

export default LinkInBioApi

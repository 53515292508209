import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select/creatable'
import { FormGroup, Label, Spinner } from 'reactstrap'
import TagsApi from '../../services/http/tags'
import { AuthContxt } from '../../store/authContxt'
import PropTypes from 'prop-types'

const To = (props) => {
	const { profile } = useContext(AuthContxt)
	const id = profile?.id

	const [selectedOptions, setSelectedOptions] = useState([])
	const [userTags, setUserTags] = useState([])
	const [loading, setLoading] = useState(false)

	const fetchUserTags = async (userId) => {
		try {
			const payload = {
				userId,
				search: '',
				order: 'id',
			}

			const response = await TagsApi.getTags(payload)

			if (response.status === 200) {
				const tags = response?.data || []
				const tagOptions = tags.map((tag) => ({
					value: tag.name,
					label: tag.name,
					count: tag.count + '' || 0 + '',
				}))

				setUserTags(tagOptions)
			} else {
				throw new Error('Something went wrong while fetching user tags')
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		if (id) {
			fetchUserTags(id)
		}
	}, [id])

	useEffect(() => {
		if (props.toAddress && Array.isArray(props.toAddress)) {
			const initialSelectedOptions = props.toAddress.map((value) => {
				const tag = userTags.find((tag) => tag.value === value)
				return {
					value: value,
					label: value,
					count: tag ? tag.count + '' : '0',
				}
			})
			setSelectedOptions(initialSelectedOptions)
		}
	}, [props.toAddress, userTags])

	const formatOptionLabel = ({ label, count }) => (
		<div>
			<div>{label}</div>
			{count != '0' && (
				<div
					style={{ fontSize: '12px', color: '#888' }}
				>{`${count} contacts`}</div>
			)}
		</div>
	)

	const handleChange = (selected) => {
		setSelectedOptions(selected, () => {
			props.setToAddress(selectedOptions.map((option) => option.value))
		})
	}

	const handleSubmit = async () => {
		setLoading(true)
		try {
			const selectedAndCreatedValues = selectedOptions.map(
				(option) => option.value
			)
			await props.handleTo(selectedAndCreatedValues)
		} finally {
			setLoading(false)
		}
	}

	return (
		<div>
			<FormGroup>
				<Label for="multiSelect">Select or Add Options</Label>

				<Select
					id="multiSelect"
					options={userTags} // Use userTags for the options
					isMulti
					value={selectedOptions}
					onChange={handleChange}
					placeholder="Select or add options..."
					formatOptionLabel={formatOptionLabel}
					onCreateOption={(inputValue) => {
						const newOption = {
							value: inputValue.toLowerCase(),
							label: inputValue,
							count: '0',
						}
						setSelectedOptions([...selectedOptions, newOption])
					}}
				/>
			</FormGroup>
			<button
				type="button"
				className="btn text-nowrap rounded-1 btn-info text-white"
				onClick={handleSubmit}
				disabled={loading}
			>
				{loading ? <Spinner size="sm" /> : 'Submit'}
			</button>
		</div>
	)
}

To.propTypes = {
	toAddress: PropTypes.string,
	setToAddress: PropTypes.func,
	handleTo: PropTypes.func,
}

export default To

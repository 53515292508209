import React from 'react'

const ImagesShower = ({ formData, setFormData }) => {
	const handleImageRemove = (index) => {
		const updatedList = formData?.imageList?.filter((ele, i) => i !== index)
		setFormData((prev) => {
			return { ...prev, imageList: updatedList }
		})
	}
	return (
		<>
			<div className="d-flex flex-wrap gap-5">
				{formData?.imageList?.map((e, key) => {
					return (
						<div
							className="position-relative"
							key={e}
							style={{
								height: '150px',
								width: '150px',
								border: '1px solid #dedede',
								borderRadius: '5px',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
								backgroundImage: `url(${e})`,
							}}
						>
							<i
								onClick={() => {
									handleImageRemove(key)
								}}
								style={{ height: '32px', top: '10px', right: '10px' }}
								className="fa-solid fa-circle-xmark fs-3 text-danger position-absolute"
							></i>
						</div>
					)
				})}
			</div>
		</>
	)
}

export default ImagesShower

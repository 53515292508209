import PageWrapper from '../../components/wrapper/pageWrapper'
import AdminDashboard from '../../admin-side/dashboard'
const Page = () => {
	return (
		<PageWrapper>
			<AdminDashboard />
		</PageWrapper>
	)
}

export default Page

import { toPng } from 'html-to-image'
import Frames, {
	SvgIcons,
} from '../components/qr-codes/create-qr-code/frames-svg'
import QrCodesAPI from '../services/http/qrCodes'
import convertToURLFriendly from './convertToURLFriendly'
import ReactDOMServer from 'react-dom/server'
import html2canvas from 'html2canvas'
import { el } from 'date-fns/locale'

const BASE_URL = process.env.REACT_APP_QR_BASE_URL
const options = {
	data: `https://${BASE_URL}`,
	config: {
		erf1: [],
		erf2: ['fh'],
		erf3: ['fv'],
		brf1: [],
		brf2: ['fh'],
		brf3: ['fv'],
		gradientType: 'radial',
		gradientOnEyes: false,
		logo: '',
		eye: 'frame0',
		eyeBall: 'ball0',
		body: 'round',
		bodyColor: '#000000',
		bgColor: '#FFFFFF',
	},
	size: 300,
	download: 'imageUrl',
	file: 'png',
}

const patternMapper = {
	classy: 'round',
	rounded: 'square',
	'extra-rounded': 'circle-zebra',
	'classy-rounded': 'circular',
	square: 'mosaic',
	dots: 'circle',
}

const cornerMapper = {
	square: {
		eye: 'frame0',
		eyeBall: 'ball0',
	},
	'extra-rounded': {
		eye: 'frame13',
		eyeBall: 'ball15',
	},
	none: {
		eye: 'frame10',
		eyeBall: 'ball15',
	},
	8: {
		eye: 'frame12',
		eyeBall: 'ball14',
	},
	4: {
		eye: 'frame2',
		eyeBall: 'ball2',
		erf1: ['fh'],
		brf1: ['fh'],
	},
	5: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fh'],
		erf3: ['fh'],
		brf1: ['fh'],
		brf3: ['fh'],
	},
	dot: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fv'],
		erf2: ['fv'],
		brf1: ['fv'],
		brf2: ['fv'],
	},
	9: {
		eye: 'frame0',
		eyeBall: 'ball18',
	},
}

const imageDownloadHandler = (values, format, isQrCategory = true) => {
	const {
		color,
		bgColor,
		pattern,
		qrLogoId,
		brandedDomain,
		slashTag,
		frame,
		primary,
		secondary,
		text,
		textColor,
		type,
		corner,
		isBgTransparent,
		title,
	} = values

	try {
		const auth_token = localStorage.getItem('auth_token')
		QrCodesAPI.downloadQrCode(auth_token, {
			payload: {
				...options,
				config: {
					...options?.config,
					bodyColor: color,
					bgColor: isBgTransparent ? 'transparent' : bgColor,
					eye1Color: color,
					eye2Color: color,
					eye3Color: color,
					eyeBall1Color: color,
					eyeBall2Color: color,
					eyeBall3Color: color,
					body: patternMapper[pattern],
					...cornerMapper[corner],
					logo: qrLogoId,
				},
				data:
					`https://${brandedDomain}/${convertToURLFriendly(slashTag)}${isQrCategory ? '?r=qr' : ''}` ||
					`https://${BASE_URL}/`,
				file: !frame ? format : format === 'svg' ? 'png' : format,
			},
		})
			.then(({ data }) => {
				if (!frame) {
					const arrayBuffer = Uint8Array.from(data?.data?.data).buffer
					const blob = new Blob([arrayBuffer], {
						type: `image/${format}`,
					})
					const url = window.URL.createObjectURL(blob)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `${title}.${format}`)
					document.body.appendChild(link)
					link.click()
				} else {
					const uint8Array = Uint8Array.from(data?.data?.data)
					const base64data = toBase64(uint8Array)
					const svgString = ReactDOMServer.renderToStaticMarkup(
						<Frames
							type={frame?.type}
							height={400}
							width={400}
							url={`data:image/${format};base64,${base64data}`}
							text={text}
							minFontSize={frame.minFontSize}
							maxFontSize={frame.maxFontSize}
							primary={primary}
							secondary={secondary}
							textColor={textColor}
						/>
					)

					if (format === 'svg') {
						// If SVG format, trigger download directly
						const blob = new Blob([svgString], { type: 'image/svg+xml' })
						const url = window.URL.createObjectURL(blob)
						const downloadLink = document.createElement('a')
						downloadLink.href = url
						downloadLink.download = `${title}.svg`
						document.body.appendChild(downloadLink)
						downloadLink.click()
						document.body.removeChild(downloadLink)
					} else {
						// Convert to PNG or other formats using canvas
						svgStringToPngBlob(svgString).then((blob) => {
							const url = window.URL.createObjectURL(blob)
							const downloadLink = document.createElement('a')
							downloadLink.href = url
							downloadLink.download = `${title}.${format}`
							document.body.appendChild(downloadLink)
							downloadLink.click()
							document.body.removeChild(downloadLink)
						})
					}
				}
			})
			.catch((error) => {
				console.log('Error while downloading qr code: ', error)
			})
	} catch (e) {
		console.log('Error while download qr code: ', e)
	}
}

// Helper function for converting SVG string to PNG Blob
function svgStringToPngBlob(svgString) {
	return new Promise((resolve, reject) => {
		const svgBlob = new Blob([svgString], {
			type: 'image/svg+xml;charset=utf-8',
		})
		const url = URL.createObjectURL(svgBlob)

		const img = new Image()
		img.onload = function () {
			const canvas = document.createElement('canvas')
			canvas.width = img.width
			canvas.height = img.height
			const ctx = canvas.getContext('2d')
			ctx.drawImage(img, 0, 0)

			canvas.toBlob((blob) => {
				URL.revokeObjectURL(url)
				resolve(blob)
			}, 'image/png')
		}

		img.onerror = function () {
			reject(new Error('Failed to load SVG as image'))
		}

		img.src = url
	})
}

const toBase64 = (byteArray) => {
	let binaryString = ''
	const len = byteArray.byteLength
	for (let i = 0; i < len; i++) {
		binaryString += String.fromCharCode(byteArray[i])
	}
	return window.btoa(binaryString)
}

export default imageDownloadHandler

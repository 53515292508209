const contentMapper = {
	clicks: {
		icon: 'fa-computer-mouse',
		heading: 'Total Clicks',
		text: 'text-info',
	},
	scans: { icon: 'fa-expand', heading: 'Total Scans', text: 'text-warning' },
	links: {
		icon: 'fa-link',
		heading: 'Total Links Created',
		text: 'text-primary',
	},
	qr: { icon: 'fa-qrcode', heading: 'Total QR Codes', text: 'text-success' },
	linkInBio: {
		icon: 'fa-clipboard-list',
		heading: 'Total Link-in-bio Views',
		text: 'text-danger',
	},
}
const CountCard = ({ stats }) => {
	const { type, count } = stats
	const { icon, heading, text } = contentMapper[type]
	return (
		<div class="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3">
			<div class="card card-xxl-stretch-50 h-100 mb-5 mb-xl-8">
				<div class="card-body d-flex flex-column position-relative justify-content-between p-0">
					<div class="d-flex flex-column gap-3 px-lg-9 px-5 pt-lg-9 pt-5">
						<div class="pb-lg-5 d-flex border-lg-bottom">
							<span>
								<i class={`fal ${icon} ${text} fs-1 me-2 ms-1`}></i>
							</span>
							<span class="text-gray-500 fw-semibold fs-4">{heading}</span>
						</div>
						<div class={`${text} fw-bolder mt-lg-4 mt-2 fs-2hx`}>{count}</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CountCard

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2243}
				height={height || 2537}
				viewBox="0 0 2243 2537"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_177_23116)">
					<path
						d="M10.6692 577.222C-42.8128 845.288 117.742 822.623 204.704 777.782C217.749 780.391 218.292 799.523 216.934 808.763H2047.22C2053.75 787.24 2080.38 793.273 2092.88 798.98C2204.41 838.113 2239.36 773.978 2242.89 737.018V647.337C2244.19 609.507 2233.65 578.853 2228.21 568.254L1998.31 154.904C1974.18 131.424 1996.95 110.335 2011.35 102.726C2049.83 23.8063 1998.58 1.35881 1968.14 0H256.882C198.182 46.9604 225.902 92.9425 247.099 110.064C266.665 119.847 261.774 144.034 256.882 154.904L10.6692 577.222Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M1195.48 112.509H1053.87C1046.33 112.509 1040.15 118.476 1039.88 126.009L1020.2 677.123C1018.74 717.869 1051.38 751.693 1092.15 751.693H1151.57C1192.05 751.693 1224.58 718.327 1223.55 677.857L1209.47 126.152C1209.28 118.561 1203.07 112.509 1195.48 112.509Z"
						fill={secondary || 'white'}
					/>
					<rect
						x="225.127"
						y="804.919"
						width="1819.22"
						height="1622.92"
						fill={primary || '#0E1B21'}
					/>
					<rect
						x="52.248"
						y="2426.12"
						width="2165.37"
						height="110.879"
						rx="38"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M570.176 113.323H442.264C436.575 113.323 431.376 116.542 428.839 121.635L216.019 548.829C211.258 558.386 208.779 568.919 208.779 579.596V679.692C208.779 719.456 241.015 751.692 280.779 751.692H347.935C387.7 751.692 419.935 719.456 419.935 679.692V576.099C419.935 567.678 421.477 559.328 424.483 551.463L584.177 133.679C587.931 123.858 580.689 113.323 570.176 113.323Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M1681.75 113.323H1809.66C1815.35 113.323 1820.55 116.542 1823.08 121.635L2035.9 548.829C2040.67 558.386 2043.14 568.919 2043.14 579.596V679.692C2043.14 719.456 2010.91 751.692 1971.14 751.692H1903.99C1864.22 751.692 1831.99 719.456 1831.99 679.692V576.099C1831.99 567.678 1830.45 559.328 1827.44 551.463L1667.75 133.679C1663.99 123.858 1671.23 113.323 1681.75 113.323Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M877.755 114.14H744.7C738.298 114.14 732.712 118.482 731.133 124.687L618.863 565.808L612.609 685.159C610.691 721.771 639.86 752.508 676.522 752.508H749.714C785.06 752.508 813.714 723.854 813.714 688.508V682.394C804.311 567.045 859.429 277.258 891.475 131.129C893.396 122.37 886.723 114.14 877.755 114.14Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M1366.02 114.14H1499.08C1505.48 114.14 1511.06 118.482 1512.64 124.687L1624.91 565.808L1631.17 685.159C1633.08 721.771 1603.92 752.508 1567.25 752.508H1494.06C1458.72 752.508 1430.06 723.854 1430.06 688.508V682.394C1439.46 567.045 1384.35 277.258 1352.3 131.129C1350.38 122.37 1357.05 114.14 1366.02 114.14Z"
						fill={secondary || 'white'}
					/>
					<rect
						x="464"
						y="874.446"
						width="1316"
						height="1316"
						rx="50"
						fill="white"
					/>

					<text
						fill={textColor || 'white'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 225}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="2398.95">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_177_23116">
						<rect width="2243" height="2537" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="550" y="960.45" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

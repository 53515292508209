import { createContext, useContext, useEffect, useState } from 'react'
import ShortLinksAPI from '../../services/http/shortLinks'
import { AuthContxt } from '../../store/authContxt'
import LinkInBioApi from '../../services/http/linkInBio'

const filtersDefaultState = {
	starred: false,
	sort: '',
	tags: [],
}

export const ShortLinkContxt = createContext(null)

const ShortLinkContxtProvider = (props) => {
	const { fetchQuota } = useContext(AuthContxt)
	const [tags, setTags] = useState([])
	const [loading, setLoading] = useState(true)
	const [shortLinks, setShortLinks] = useState([])
	const [notLinksScreen, setNotLinksScreen] = useState('')
	const [bioPages, setBioPages] = useState([])

	const [filters, setFilters] = useState({
		starred: false,
		sort: '',
		tags: [],
	})
	const [search, setSearch] = useState()

	const tagsHandler = (links) => {
		const allTags =
			links &&
			links?.length > 0 &&
			links?.reduce((tags, item) => {
				if (item.tags) {
					const itemTags = item.tags
						.split(',')
						.map((tag) => tag.trim())
						.filter((tag) => tag !== '')
					tags.push(...itemTags)
				}
				let uniqueTagsSet = new Set(tags)
				let uniqueTagsArray = Array.from(uniqueTagsSet)
				return uniqueTagsArray
			}, [])

		setTags(allTags)
	}

	const starredLinkHandler = async (id, status) => {
		const auth_token = localStorage.getItem('auth_token')
		const requestData = {
			isStarred: !status,
		}
		try {
			const response = await ShortLinksAPI.changeStarred(
				auth_token,
				id,
				requestData
			)
			if (response?.data?.success) {
				setShortLinks((preState) =>
					preState?.map((item) =>
						item?.id === id ? { ...item, isStarred: !item.isStarred } : item
					)
				)
			}
		} catch (e) {
			console.log('Error while mark link as starred: ', e.message)
		}
	}

	const deleteLinkHandler = async (id) => {
		const auth_token = localStorage.getItem('auth_token')
		try {
			const response = await ShortLinksAPI.deleteShortLink(auth_token, id)
			if (response?.data?.success) {
				if (
					filters?.tags?.length > 0 ||
					filters?.sort !== '' ||
					search !== ''
				) {
					await getShortLinks()
					setSearch('')
					setFilters(filtersDefaultState)
				} else {
					const data = response?.data?.result
					const filteredLinks = shortLinks?.filter(
						(item) => item?.id !== data?.id
					)
					setShortLinks(filteredLinks)
					tagsHandler(filteredLinks)
				}
				await fetchQuota()
				getBioPages()
			}
		} catch (e) {
			console.log('Error while deleting shortlink: ', e.message)
		}
	}

	const getShortLinks = async () => {
		setLoading(true)
		try {
			const { data } = await ShortLinksAPI.getShortLinks()
			setShortLinks(data.resultData)
			if (data.resultData.length < 1) {
				setNotLinksScreen('notFound')
			}
			tagsHandler(data.resultData)
		} catch (error) {
			console.log('Error while fetching shortlinks', error)
		} finally {
			setLoading(false)
		}
	}

	const getBioPages = async () => {
		try {
			const token = localStorage.getItem('auth_token')
			const response = await LinkInBioApi.getLinkInBioPages(token)
			if (response?.data?.success) {
				setBioPages(response?.data?.data)
			}
		} catch (e) {
			console.log('Error while fetching bio pages: ', e)
		}
	}

	useEffect(() => {
		getShortLinks()
	}, [])

	useEffect(() => {
		getBioPages()
	}, [])

	return (
		<ShortLinkContxt.Provider
			value={{
				tags,
				tagsHandler,
				shortLinks,
				setShortLinks,
				getShortLinks,
				loading,
				notLinksScreen,
				setNotLinksScreen,

				deleteLinkHandler,
				starredLinkHandler,

				filters,
				setFilters,
				search,
				setSearch,
				bioPages,
				getBioPages,
			}}
		>
			{props.children}
		</ShortLinkContxt.Provider>
	)
}

export default ShortLinkContxtProvider

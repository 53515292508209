import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 3565}
				height={height || 1506}
				viewBox="0 0 3565 1506"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_7456)">
					<rect
						x="1064.5"
						y="251.5"
						width="2458"
						height="1003"
						rx="205.5"
						fill="white"
						stroke={secondary || 'white'}
						stroke-width="85"
					/>
					<path
						d="M45 315C45 165.883 165.883 45 315 45H1191C1340.12 45 1461 165.883 1461 315V1191C1461 1340.12 1340.12 1461 1191 1461H315C165.883 1461 45 1340.12 45 1191V315Z"
						fill="white"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M315 0H1191C1364.97 0 1506 141.03 1506 315V1191C1506 1364.97 1364.97 1506 1191 1506H315C141.03 1506 0 1364.97 0 1191V315C0 141.03 141.03 0 315 0ZM315 45C165.883 45 45 165.883 45 315V1191C45 1340.12 165.883 1461 315 1461H1191C1340.12 1461 1461 1340.12 1461 1191V315C1461 165.883 1340.12 45 1191 45H315Z"
						fill={primary || '#0E1B21'}
					/>
					<rect
						x="75"
						y="75"
						width="1356"
						height="1356"
						rx="250"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M1702 752.5L1505.5 961.645L1505.5 543.355L1702 752.5Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M1561 379H3375.5"
						stroke={primary || '#0E1B21'}
						stroke-width="34"
						stroke-dasharray="63 63"
					/>
					<path
						d="M1561 1123H3375.5"
						stroke={primary || '#0E1B21'}
						stroke-width="34"
						stroke-dasharray="63 63"
					/>

					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 224}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="72%" textAnchor="middle" y="841.006">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_7456">
						<rect width="3565" height="1506" fill="white" />
					</clipPath>
				</defs>
				<image href={url} x="211" y="211" width="1084" height="1084" />
			</svg>
		</>
	)
}

export default IconFrame5

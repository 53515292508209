import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isColorDark } from './color-checker/isColorDark'

const AudioPreview = ({ formData }) => {
	return (
		<div className=" bg-light h-100">
			<div
				className="qr-audio-preview-container h-100"
				style={{
					background: formData?.audioPrimary,
					paddingTop: '40px',
					paddingBottom: '20px',
				}}
			>
				<div
					style={{
						height: '250px',
						width: '250px',
						borderRadius: '15px',
						backgroundImage: `url(${
							formData?.banner ||
							'https://cdns-images.dzcdn.net/images/cover/9fa9f145d44669cc421a4e41388c5ed0/0x1900-000000-80-0-0.jpg'
						})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
					className={`mx-auto mt-5 d-flex justify-content-center align-items-start py-0 flex-column`}
				>
					<div
						className="d-flex px-5 justify-content-end h-100 w-100 py-5 align-items-center flex-column text-center"
						style={{
							background: 'rgb(255,255,255)',
							background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.41780462184873945) 30%, ${formData?.audioPrimary} 80%,  ${formData?.audioPrimary} 100%)`,
						}}
					>
						<div style={{ marginBottom: '-20px' }}>
							<h4 className="fw-bold fs-1 text-white">
								{formData?.audioTitle || 'Audio Title'}
							</h4>
							<p className="fs-5 text-white">
								{formData?.description || 'Description'}
							</p>
						</div>
					</div>
				</div>

				<div className="audio-player-container px-3">
					<audio
						className="audio-player"
						controls
						src={formData?.audio}
					></audio>
				</div>

				<div className="py-5">
					<a
						className={`text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'} mx-auto d-block btn text-dark rounded-pill fs-4`}
						style={{
							background: formData?.ctaColor || 'white',
							width: 'fit-content',
						}}
						href={formData?.buttonLink}
					>
						{formData?.buttonText || 'Buy Now'}
					</a>
				</div>

				<div className="text-center mt-5 text-dark">
					<p>{formData?.websiteLink}</p>
				</div>
				<div className="d-flex gap-6 mb-3 py-4 justify-content-center align-items-center">
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#3F51B5' }}
					>
						<Link to={formData?.facebook || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-facebook-f text-white"></i>
						</Link>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{
							background:
								'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
						}}
					>
						<a href={formData?.instagram || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-instagram text-white"></i>
						</a>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#e84d40' }}
					>
						<Link to={formData?.google || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-google text-white"></i>
						</Link>
					</span>
					<span
						className="h-40px w-40px rounded-circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: '#0078D4' }}
					>
						<Link to={formData?.linkedin || '#profile'} className="d-flex">
							<i class="fa-brands fs-2 fa-linkedin-in text-white"></i>
						</Link>
					</span>
				</div>
			</div>
		</div>
	)
}

// Usage Example
// const App = () => {
//   const title = "Still Here";
//   const artist = "KR$NA";
//   const audioSrc = "/media/cc0-audio/t-rex-roar.mp3";
//   const imageUrl = "https://theindianmusicnerd.wordpress.com/wp-content/uploads/2021/03/ef66817710a1fb895f7949ef684e6304326959208243373787.jpg?w=820";
//   const websiteLink = "www.lsklsdflsdflksdf.com";

//   return (
//     <AudioPreview
//       title={title}
//       artist={artist}
//       audioSrc={audioSrc}
//       imageUrl={imageUrl}
//       websiteLink={websiteLink}
//     />
//   );
// };

export default AudioPreview

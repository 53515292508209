import Visa from '../../assets/media/card-logos/visa.svg'
import Mastercard from '../../assets/media/card-logos/mastercard.svg'
import AmericanExpress from '../../assets/media/card-logos/american-express.svg'

const cardsMapper = {
	Visa: Visa,
	MasterCard: Mastercard,
	'American Express': AmericanExpress,
}
const PaymentCard = ({
	card,
	cardHandler = () => {},
	selectedCard = false,
	setSelectedCard = () => {},
}) => {
	const { name, last4, exp_month, exp_year, brand, id } = card || {}

	return (
		<>
			<div className="col-xl-6">
				<label
					onClick={() => {
						setSelectedCard(id)
						cardHandler()
					}}
					className={`btn btn-outline btn-outline-dashed btn-active-light-info outline-info text-dark p-7 d-flex align-items-center mb-10 ${
						selectedCard === id ? 'active' : ''
					}`}
				>
					<div className="d-flex flex-column py-2">
						<div className="d-flex align-items-center fs-4 fw-bold mb-5">
							{name}
						</div>

						<div className="d-flex align-items-center">
							<img src={cardsMapper[brand]} alt={brand} className="me-4" />

							<div>
								<div className="fs-4 fw-bold">{`${brand} **** ${last4}`}</div>
								<div className="fs-6 fw-semibold text-gray-500">
									{`Card expires at ${exp_month}/${exp_year}`}
								</div>
							</div>
						</div>
					</div>
				</label>
			</div>
		</>
	)
}

export default PaymentCard

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2201}
				height={height || 2860}
				viewBox="0 0 2201 2860"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_10807)">
					<path
						d="M278 288.703C278 238.128 315.76 195.515 365.967 189.429L1824.97 12.5798C1884.53 5.3599 1937 51.853 1937 111.853V1860.3C1937 1910.87 1899.24 1953.48 1849.03 1959.57L390.033 2136.42C330.469 2143.64 278 2097.15 278 2037.15V288.703Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M994 2860L284 2786.47L734.912 2612L994 2860Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M284.501 2787C280.35 2698.31 274.538 2508.35 284.501 2458L1226 2529.66L284.501 2787Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M1226 2532L40 2460.39L1226 2263V2532Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M471 374.134L1740 246V1682.79L471 1848V374.134Z"
						fill="white"
					/>
					<path
						d="M0 1729.83C0 1704.34 19.1756 1682.93 44.512 1680.13L2145.51 1448.13C2175.12 1444.86 2201 1468.04 2201 1497.83V2196.17C2201 2221.66 2181.82 2243.07 2156.49 2245.87L55.4878 2477.87C25.8807 2481.14 0 2457.96 0 2428.17V1729.83Z"
						fill={secondary || 'white'}
					/>

					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 300}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="2074">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_10807">
						<rect width="2201" height="2860" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="569" y="430" width="1064" height="1064" />
			</svg>
		</>
	)
}

export default IconFrame5

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import UserSettingsApi from '../../../services/http/userSettings'
import { FormGroup, Label, Input } from 'reactstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect, useState } from 'react'
import useCustomMessage from '../../../hooks/useCustomMessage'
import { EMAIL_REGEX } from '../../../utils/enum'

const newUserValidation = yup.object().shape({
	email: yup
		.string()
		.required('Please fill this field!')
		.matches(EMAIL_REGEX, 'Please enter a valid email'),
})

const NewUser = ({ edittedUser = null, toggleHandler, usersHandler }) => {
	const isEdit = edittedUser !== null

	const { message, messageHandler } = useCustomMessage()
	const [loading, setLoading] = useState(false)
	const form = useForm({
		resolver: yupResolver(newUserValidation),
		defaultValues: {
			email: '',
			userType: 'admin',
		},
	})
	const {
		handleSubmit,
		watch,
		setValue,
		reset,
		control,
		formState: { errors },
	} = form
	const { userType } = watch()

	const addUserHandler = async (data) => {
		try {
			const response = await UserSettingsApi.createUser(data)
			if (response?.data?.success) {
				messageHandler(response?.data?.message, true)
				usersHandler('add', response?.data?.data)
			} else {
				messageHandler(response?.data?.message, false)
			}
			setLoading(false)
			setTimeout(() => {
				toggleHandler()
			}, 3 * 1000)
			reset()
		} catch (e) {
			messageHandler(e.response?.data?.message, false)
			setLoading(false)
		}
	}

	const editUserHandler = async (data) => {
		try {
			const response = await UserSettingsApi.updateUser(edittedUser?.id, data)
			if (response?.data?.success) {
				messageHandler(response?.data?.message, true)
				usersHandler('update', response?.data?.data)
			} else {
				messageHandler(response?.data?.message, false)
			}
			setLoading(false)
			setTimeout(() => {
				toggleHandler()
			}, 3 * 1000)
			reset()
		} catch (e) {
			messageHandler(e.response?.data?.message, false)
			setLoading(false)
		}
	}

	const submitHandler = async (data) => {
		if (data) {
			setLoading(true)
			if (!isEdit) {
				await addUserHandler(data)
			} else {
				await editUserHandler(data)
			}
		}
	}

	useEffect(() => {
		if (edittedUser) {
			reset({ email: edittedUser?.email, userType: edittedUser?.userType })
		}
	}, [edittedUser, reset])

	return (
		<div>
			<Modal
				className="modal-dialog-centered"
				isOpen={true}
				toggle={toggleHandler}
			>
				<ModalHeader toggle={toggleHandler}>
					{isEdit ? 'Edit User' : 'Add New User'}
				</ModalHeader>
				<form
					class="form fv-plugins-bootstrap5 fv-plugins-framework"
					onSubmit={handleSubmit(submitHandler)}
				>
					<ModalBody>
						<div
							class="d-flex flex-column scroll-y px-lg-5"
							style={{ maxHeight: '645px' }}
						>
							<div class="fv-row mb-7 fv-plugins-icon-container">
								<FormGroup>
									<Label className="fs-5 fw-bold mb-2">Email</Label>
									<Controller
										name={'email'}
										control={control}
										render={({ field: { onBlur, value } }) => (
											<Input
												value={value}
												type="email"
												className="form-control mb-3 mb-lg-0 rounded-1 h-50px border border-gray-250"
												placeholder="Email Address"
												onChange={(e) => {
													setValue('email', e.target.value)
												}}
												onBlur={onBlur}
											/>
										)}
									/>
								</FormGroup>
								{errors.email ? (
									<div style={{ textAlign: 'end' }}>
										<span className="error-text text-danger">
											{errors.email.message}
										</span>
									</div>
								) : null}
							</div>

							<div class="mb-5">
								<label class="required fw-semibold fs-6 mb-5">Role</label>

								<div class="d-flex fv-row">
									<div
										onClick={() => setValue('userType', 'admin')}
										style={{ cursor: 'pointer' }}
										class="form-check form-check-custom form-check-info form-check-solid"
									>
										<input
											class="form-check-input me-3"
											name="userType"
											type="radio"
											checked={userType === 'admin'}
										/>

										<label class="form-check-label">
											<div class="fw-bold text-gray-800">Admin</div>
										</label>
									</div>
								</div>

								<div class="d-flex mt-5 fv-row">
									<div
										onClick={() => setValue('userType', 'user')}
										style={{ cursor: 'pointer' }}
										class="form-check form-check-custom form-check-info form-check-solid"
									>
										<input
											class="form-check-input me-3"
											name="userType"
											type="radio"
											checked={userType === 'user'}
										/>

										<label class="form-check-label">
											<div class="fw-bold text-gray-800">User</div>
										</label>
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<div
							style={{
								textAlign: 'start',
							}}
						>
							{message ? message : null}
						</div>
						<div class="d-flex flex-end">
							<button
								type="reset"
								class="btn btn-light rounded-1 border w-lg-150px me-3 cursor-pointer"
								onClick={toggleHandler}
							>
								Discard
							</button>

							<button
								disabled={loading}
								type="submit"
								class="btn btn-info rounded-1 w-lg-150px cursor-pointer"
							>
								{loading ? (
									<span style={{ display: 'flex' }}>Please wait...</span>
								) : (
									<span class="indicator-label">
										{isEdit ? 'Update' : 'Submit'}
									</span>
								)}
							</button>
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</div>
	)
}

export default NewUser

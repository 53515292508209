import React, { useState } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { Form, FormGroup, Input, Spinner, Button } from 'reactstrap'
import * as yup from 'yup'
import AuthPageWrapper from '../components/wrapper/authPageWrapper'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import AuthApi from '../services/http/auth'
import useCustomMessage from '../hooks/useCustomMessage'

const forgetPasswordValidation = yup.object().shape({
	password: yup
		.string()
		.required('Please enter your password')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
			'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
		),
	confirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const ResetPassword = () => {
	const { message, messageHandler } = useCustomMessage()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token') || ''
	const id = searchParams.get('id') || ''

	const [loading, setLoading] = useState(false)

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(forgetPasswordValidation),
	})

	const submitHandler = async (data) => {
		try {
			setLoading(true)
			const payload = {
				password: data.password,
				token,
				id: +id,
			}
			const response = await AuthApi.changePassword(payload)
			messageHandler(response.data?.data?.type, response?.data?.success)
			response?.data?.success && navigate('/login')
			setLoading(false)
		} catch (e) {
			messageHandler(
				e?.response?.data?.data?.type || 'Something went wrong',
				false
			)
			setLoading(false)
		}
	}
	return (
		<AuthPageWrapper>
			<Form
				onSubmit={handleSubmit(submitHandler)}
				class="form px-12 fv-plugins-bootstrap5 fv-plugins-framework"
				novalidate="novalidate"
				id="kt_sign_in_form"
				data-kt-redirect-url="/links-list.php"
				action="#"
			>
				<div class="text-center mb-10">
					<h1 class="text-dark mb-3 fs-3x fw-bolder">Reset Password</h1>

					<div class="text-gray-400 fw-semibold fs-4">
						Enter your new password.
					</div>
				</div>

				<div className="fv-row mb-7 fv-plugins-icon-container">
					<FormGroup>
						<div className="position-relative">
							<Controller
								name={'password'}
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<Input
										value={value}
										className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
										type="password"
										placeholder="New Password"
										onChange={(e) => {
											onChange(e)
										}}
										onBlur={onBlur}
									/>
								)}
							/>
							<span
								className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
								data-kt-password-meter-control="visibility"
							>
								<i className="fa-regular fa-envelope fs-2"></i>
							</span>
						</div>
						{errors?.password && (
							<span className="error-text text-danger">
								{errors?.password?.message}
							</span>
						)}
					</FormGroup>
				</div>

				<div className="fv-row mb-7 fv-plugins-icon-container">
					<FormGroup>
						<div className="position-relative">
							<Controller
								name={'confirmPassword'}
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<Input
										value={value}
										className="form-control bg-white h-55px border border-gray-300 form-control-lg form-control-solid pe-15 rounded-55rem"
										type="password"
										placeholder="Confirm New Password"
										onChange={(e) => {
											onChange(e)
										}}
										onBlur={onBlur}
									/>
								)}
							/>
							<span
								className="btn btn-sm btn-icon  position-absolute translate-middle top-50 end-0 me-n2 opacity-25"
								data-kt-password-meter-control="visibility"
							>
								<i className="fa-regular fa-envelope fs-2"></i>
							</span>
						</div>
						{errors?.confirmPassword && (
							<span className="error-text text-danger">
								{errors?.confirmPassword?.message}
							</span>
						)}
					</FormGroup>
				</div>

				<div
					style={{
						textAlign: 'end',
					}}
				>
					{message ? message : null}
				</div>

				<div class="fv-row mb-10">
					<div class="text-center">
						<Button
							color="info"
							className="btn btn-lg btn-info bg-brand w-100 h-55px mt-5 mb-5 rounded-55rem btn-hover-dark"
							disabled={loading}
							type="submit"
						>
							{loading ? <Spinner /> : 'Submit'}
						</Button>
						<Link
							to="/"
							class="btn btn-lg btn-light bg-white border border-gray-300 w-100 h-55px rounded-55rem d-flex justify-content-center align-items-center fw-bold"
						>
							<span class="indicator-label">Cencel</span>
						</Link>
					</div>
				</div>
			</Form>
		</AuthPageWrapper>
	)
}

export default ResetPassword

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2113}
				height={height || 2484}
				viewBox="0 0 2113 2484"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<text
					fill={textColor || 'black'}
					xmlSpace="preserve"
					style={{ whiteSpace: 'pre' }}
					fontFamily="Arial"
					fontSize={fontSize || 226}
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="35%" textAnchor="middle" y="2464.85">
						{text}
					</tspan>
				</text>

				<path
					d="M80.0039 879.708C80.0039 854.194 99.2122 832.771 124.574 829.999L1229.52 709.263C1243.54 707.731 1257.55 712.19 1268.11 721.541L1396.04 834.87L1515.59 955.304C1526.3 966.097 1531.52 981.18 1529.77 996.287L1401.16 2106.74C1398.24 2131.96 1376.88 2150.99 1351.49 2150.99H130.005C102.39 2150.99 80.0039 2128.6 80.0039 2100.99V879.708Z"
					fill="white"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M1451.01 776.682L1572.36 898.938C1600.22 927 1613.79 966.214 1609.24 1005.49L1480.63 2115.95C1473.04 2181.52 1417.5 2231 1351.49 2231H130.003C58.2045 2231 0 2172.79 0 2100.99V879.709C0 813.371 49.9416 757.671 115.883 750.466L1220.83 629.729C1257.28 625.747 1293.71 637.34 1321.16 661.651L1451.01 776.682ZM1396.04 834.87L1268.11 721.541C1257.55 712.19 1243.54 707.732 1229.52 709.263L124.573 830C99.2104 832.771 80.0021 854.194 80.0021 879.709V2100.99C80.0021 2128.6 102.388 2150.99 130.003 2150.99H1351.49C1376.88 2150.99 1398.24 2131.96 1401.16 2106.74L1529.77 996.288C1531.52 981.18 1526.3 966.098 1515.59 955.305L1396.04 834.87Z"
					fill="black"
				/>
				<path
					d="M1691.02 970.9L1093.29 543.131C1051.45 602.506 1008.65 668.75 1014.9 705.342C1022.19 748.013 1062.45 776.828 1142.98 834.456L1399.21 1017.83C1479.73 1075.46 1520 1104.27 1562.74 1097.41C1599.4 1091.52 1648.31 1029.65 1691.02 970.9Z"
					fill={secondary || 'white'}
				/>
				<path
					d="M1575.73 49.1461C1457.71 -35.3094 1293.64 -8.21236 1209.26 109.669C1146.61 197.193 1145.5 310.159 1197.39 397.106L1093.01 542.93L1691.31 971.107L1795.69 825.283C1894.74 846.352 2001.32 808.875 2063.98 721.35C2148.36 603.469 2121.1 439.442 2003.08 354.987C1992.38 347.325 1981.29 340.582 1969.92 334.739C1998.45 240.509 1965.67 134.539 1881.08 74.0006C1796.49 13.4625 1685.59 16.6096 1605.59 74.0023C1596.39 65.1244 1586.43 56.808 1575.73 49.1461Z"
					fill={primary || '#0E1B21'}
				/>
				<image href={url} x="168" y="919" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

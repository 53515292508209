const convertToK = (argument) => {
	const value = +argument
	if (value > 1000) {
		return value / 1000 + 'k'
	} else {
		return value.toString()
	}
}

export default convertToK

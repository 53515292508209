import React, { useCallback, useContext, useRef, useState } from 'react'
import {
	Col,
	Row,
	TabPane,
	Input,
	FormGroup,
	Label,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'
import { Controller } from 'react-hook-form'

import _debounce from 'lodash/debounce'
import useCustomLinks from '../../../../hooks/useCustomLinks'
import { AuthContxt } from '../../../../store/authContxt'
import titleFetcher from '../../../../utils/titleFetcher'
import { generateRandomString } from '../../../../utils/generateRandomString'
import { PLAN } from '../../../../utils/enum'

const WebsiteForm = ({ activeId, formData, setFormData, isEdit }) => {
	const { quota } = useContext(AuthContxt)
	const customLinks = useCustomLinks()
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL
	const timeoutRef = useRef(null)
	const getCustomDomainHost = (customLink) => {
		const { domain, subDomain, spare } = customLink
		try {
			const url = new URL(domain)
			return spare === 'No' ? `${subDomain}.${url.host}` : url.host
		} catch (e) {
			console.log('Error while creating url : ', e)
			return domain
		}
	}

	const customLinkHandler = (customLink) => {
		const brandedDomain = getCustomDomainHost(customLink)
		setFormData((prev) => ({ ...prev, brandedDomain }))
	}

	const handleTitle = async (url) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
		}
		timeoutRef.current = setTimeout(async () => {
			try {
				const title = await titleFetcher(url)
				setFormData((prev) => ({
					...prev,
					title: title?.title || '',
					metaDescription: title?.metaDescription || '',
				}))
			} catch (e) {
				console.log('Error while fetching title: ', e.message)
			}
		}, 500)
	}
	const destinationUrlHandler = async (e) => {
		const url = e.target.value

		handleTitle(url)
		setFormData((prev) => ({ ...prev, destinationUrl: url }))
	}

	const isPlanStarter = isEdit && quota?.plan === PLAN.STARTER
	const isPlanStandard = quota?.plan === PLAN.STANDARD

	return (
		<div className=" px-0 mw-1000px">
			<Row className="">
				<Col sm="12">
					<div className="rounded-1">
						<div className=" mb-lg-8 mb-5">
							<h3 className="fs-2">Enter Your Destination URL</h3>
						</div>
						<div>
							<FormGroup>
								<div>
									<Label className="fw-bold fs-5 mb-2">Destination URL</Label>
									{isPlanStarter ||
									(isPlanStandard && isEdit && isEdit >= quota?.linkEdit) ? (
										<i className="fas ms-2 fa-lock"></i>
									) : null}
								</div>

								<div className="position-relative">
									<Input
										type="destinationUrl"
										disabled={
											isPlanStarter ||
											(isPlanStandard && isEdit && isEdit >= quota?.linkEdit)
										}
										placeholder="https://www.example.com/enter-long-url"
										value={formData?.destinationUrl}
										onChange={(e) => {
											if (!isPlanStarter) {
												// field.onChange(e);
												destinationUrlHandler(e)
											}
										}}
										className="w-100 h-50px"
									/>
									{formData?.destinationUrl && (
										<i
											className={`fa ${
												!formData?.title ? 'fa-times' : 'fa-check'
											}`}
											style={{
												position: 'absolute',
												right: '10px',
												top: '50%',
												transform: 'translateY(-50%)',
												fontSize: '16px',
												color: !formData?.title ? 'red' : 'green',
											}}
										></i>
									)}
								</div>
								{/* <Controller
                    className="w-100"
                    name="destinationUrl"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div className="w-100">
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                        </div>
                        {fieldState.error ? (
                          <span className="error-text text-danger mt-1">
                            {fieldState.error.message}
                          </span>
                        ) : null}
                      </div>
                    )}
                  /> */}
								<p className="text-gray-400 mt-2">
									{'Example ( https://www.example.com/enter-long-url )'}
								</p>

								{isPlanStandard ? (
									<div className="d-flex justify-content-end mb-3 link-info">
										{`Remaining edit limit for destination url is ${
											quota?.linkEdit - isEdit < 0
												? 0
												: quota?.linkEdit - isEdit
										}`}
									</div>
								) : null}
							</FormGroup>
						</div>

						{/* {errors.slashTag ? (
                <div style={{ textAlign: "end" }}>
                  <span className="error-text text-danger">
                    {errors.slashTag.message}
                  </span>
                </div>
              ) : null} */}
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default WebsiteForm

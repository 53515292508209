import { Input, Label, Col, Row, FormGroup } from 'reactstrap'
import { Controller } from 'react-hook-form'

const SudDomain = ({ form }) => {
	const { control, setValue, watch } = form

	const spareHandler = (value) => {
		setValue('spare', value)
	}

	const { spare, domain } = watch()

	const getDomain = () => {
		try {
			const url = new URL(domain)
			return url.host
		} catch (e) {
			console.log('Error while creating url', e)
			return domain
		}
	}

	return (
		<div className="w-100 py-lg-10 py-6">
			<div className="w-100">
				<div className="pb-6 pb-lg-15">
					<h2 className="fw-bold d-flex align-items-center text-dark">
						{' '}
						Is domainname a spare domain name?{' '}
					</h2>

					<div className="text-muted fw-semibold fs-6 ps-0">
						{' '}
						A domain is "spare" when it is not associated with a website, a blog
						or anything else{' '}
					</div>
				</div>
				<Row>
					<Col md={6}>
						<FormGroup
							className="mb-lg-10 mb-6"
							onClick={() => spareHandler('Yes')}
						>
							<Label
								className={`d-flex flex-stack mb-5 w-100 cursor-pointer rounded-2 p-lg-5 ps-0 p-4 border border-1 border-gray-400 border-dotted bg-hover-light-info min-h-lg-110px ${
									spare === 'Yes'
										? 'border border-info border-dotted bg-light-info'
										: ''
								}`}
							>
								<span className="d-flex align-items-center me-lg-2">
									<span className="symbol me-lg-6">
										<span className="symbol-label bg-transparent">
											<i
												className={`fa-regular fa-check-square fs-1 ${
													spare === 'Yes' ? 'text-info' : ''
												}`}
											/>
										</span>
									</span>
									<span className="d-flex flex-column">
										<span className="text-dark fw-bold d-block fs-4 mb-lg-2">
											Yes
										</span>
										<span className="text-muted fw-semibold fs-6">
											Yes, This is a spare domain.
										</span>
									</span>
								</span>
							</Label>
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup
							className="mb-lg-10 mb-6"
							onClick={() => spareHandler('No')}
						>
							<Label
								className={`d-flex flex-stack mb-5 w-100 cursor-pointer rounded-2 p-lg-5 ps-0 p-4 border border-1 border-gray-400 border-dotted bg-hover-light-info min-h-lg-110px ${
									spare === 'No'
										? 'border border-info border-dotted bg-light-info'
										: ''
								}`}
							>
								<span className="d-flex align-items-center me-lg-2">
									<span className="symbol rounded-1 me-lg-6">
										<span className="symbol-label bg-transparent">
											<i
												className={`fa-regular fa-times-square fs-1 ${
													spare === 'No' ? 'text-info' : ''
												}`}
											/>
										</span>
									</span>
									<span className="d-flex flex-column">
										<span className="text-dark fw-bold d-block fs-4 mb-lg-2">
											No
										</span>
										<span className="text-muted fw-semibold fs-6">
											No, it's being used for my website, blog, etc...
										</span>
									</span>
								</span>
							</Label>
						</FormGroup>
					</Col>
				</Row>

				{spare === 'Yes' ? (
					<Label className="form-label d-flex align-items-center ps-0">
						<span className="fs-5 text-lg-dark text-gray-400 fw-lg-bold">
							👍 Super! You will be able to use this domain to create branded
							links.
						</span>
					</Label>
				) : (
					<>
						<Label className="form-label d-flex align-items-center ps-0">
							<span className="required fs-5 fw-bold">Use a Subdomain</span>
						</Label>
						<p>
							{' '}
							Choose a name for your Subdomain Example: shop, go, click. It will
							look something like: shop.domainname.com{' '}
						</p>
						<Controller
							name="subDomain"
							control={control}
							render={({ field, fieldState }) => (
								<>
									<div
										className="d-flex rounded-1"
										style={{
											border: '1px #e1dcdc solid',
										}}
									>
										<Input
											{...field}
											type="text"
											id="subDomain"
											name="subDomain"
											placeholder="Subdomain"
											className="form-control border-top-0 border-bottom-0 border-left-0 h-50px"
											style={{ borderRadius: '5px 0px 0px 5px' }}
											onChange={(e) => {
												field.onChange(e)
											}}
										/>
										<span
											style={{
												fontSize: '15px',
												alignSelf: 'center',
												padding: '0px 18px',
												fontWeight: '500',
											}}
										>
											.{getDomain()}
										</span>
									</div>
									{fieldState.error && (
										<span className="error-text text-danger">
											{fieldState.error.message}
										</span>
									)}
								</>
							)}
						/>
					</>
				)}
			</div>
		</div>
	)
}

export default SudDomain

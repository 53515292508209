import React from 'react'

import {
	Input,
	InputGroup,
	InputGroupText,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'

const SearchFilters = (props) => {
	const {
		searchText,
		handleSearchChange,
		searchHide,
		toggleSearch,

		tags,
		handleTagChange,
		selectedTags,

		sortFilters,
		handleSortChange,
	} = props

	return (
		<>
			{/* Search bar */}
			<InputGroup>
				<Input
					placeholder="Search..."
					value={searchText}
					onChange={handleSearchChange}
					className={`headerinputsearch fw-normal form-control ${searchHide ? '' : 'd-none'}`}
				/>
				<InputGroupText
					className="btn btn-icon rounded-1 bg-light-info me-2"
					onClick={toggleSearch}
				>
					<i className="fa-solid fa-magnifying-glass fs-3"></i>
				</InputGroupText>
			</InputGroup>

			{/* Tags dropdown */}
			<UncontrolledDropdown className="d-inline nospce">
				<DropdownToggle
					id="dropdown-autoclose-false"
					className="btn btn-icon bg-light-info rounded-1"
					title="Tags"
				>
					<i className="fa-solid fa-tags text-info fs-3"></i>
				</DropdownToggle>
				<DropdownMenu className="py-3">
					{tags?.length > 0 &&
						tags.map((tag) => (
							<DropdownItem key={tag}>
								<div
									className="d-flex justify-content-between align-items-center cursor-pointer"
									onClick={() => handleTagChange(tag)}
								>
									<div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
										<label className="form-check-label text-dark me-3">
											{tag}
										</label>
										<Input
											className="form-check-input n-checked-white-hvr rounded-1 tag-chk"
											type="checkbox"
											checked={selectedTags.includes(tag)}
										/>
									</div>
								</div>
							</DropdownItem>
						))}
				</DropdownMenu>
			</UncontrolledDropdown>

			{/* Sort Filters dropdown */}
			<UncontrolledDropdown className="d-inline nospce">
				<DropdownToggle
					id="dropdown-autoclose-false"
					className="btn btn-icon bg-light-info rounded-1"
					title="Sort"
				>
					<i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
				</DropdownToggle>
				<DropdownMenu className="py-3">
					{sortFilters?.length > 0 &&
						sortFilters.map((item) => (
							<DropdownItem onClick={() => handleSortChange(item?.filter)}>
								<div className="d-flex justify-content-between align-items-center cursor-pointer">
									<div className="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
										<label className="form-check-label text-dark me-3">
											{item?.label}
										</label>
									</div>
								</div>
							</DropdownItem>
						))}
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	)
}

export default SearchFilters

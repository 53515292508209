import { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const CROP_AREA_ASPECT = 4 / 4

const AvatarCropper = ({
	image,
	toggleHandler,
	cropHandler,
	cancelHandler,
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)

	const [croppedArea, setCroppedArea] = useState(null)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const onCropComplete = (cropArea, cropAreaPixels) => {
		setCroppedArea(cropArea)
		setCroppedAreaPixels(cropAreaPixels)
	}

	return (
		<Modal
			className="qrmobileviewmodal modal-dialog-centered"
			isOpen={true}
			toggle={toggleHandler}
		>
			<ModalHeader>
				<div className="d-flex justify-content-between align-items-center w-100">
					<h5 className="fw-bold mb-0">Edit Profile Photo</h5>
					<i onClick={toggleHandler} className="fas fa-times" />
				</div>
			</ModalHeader>
			<ModalBody>
				<div
					style={{
						width: '100%',
						height: '300px',
						position: 'relative',
						background: '#333',
					}}
				>
					<Cropper
						image={image}
						crop={crop}
						zoom={zoom}
						aspect={CROP_AREA_ASPECT}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<button
					className="btn btn-info rounded-1 w-lg-200px"
					type="button"
					onClick={() => {
						cropHandler(croppedArea, croppedAreaPixels)
					}}
				>
					Crop
				</button>
				<button
					className="btn btn-info rounded-1 w-lg-200px"
					type="button"
					onClick={() => cropHandler(null, null)}
				>
					Skip Cropping
				</button>

				<button
					className="btn btn-light border border-gray-250 rounded-1 w-lg-200px"
					type="button"
					onClick={cancelHandler}
				>
					Cancel
				</button>
			</ModalFooter>
		</Modal>
	)
}

export default AvatarCropper

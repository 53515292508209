import { getCountryData } from 'countries-list'
import { getCountryFlag } from '../../../../utils/getCountryFlag'
import { extractNameFromEmail } from '../../../../utils/extractName'

const Info = ({ profile }) => {
	const {
		country = '',
		firstName = '',
		lastName = '',
		mobile = '',
		email = '',
	} = profile || {}

	const userName =
		`${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim() ||
		extractNameFromEmail(email)
	const userCountry = country ? getCountryData(country) : ''

	return (
		<div className={'d-block'}>
			<div className="row mb-7">
				<label className="col-lg-4 fw-semibold text-muted">Full Name</label>

				<div className="col-lg-8">
					<span className="fw-bold fs-6 text-gray-800">{userName}</span>
				</div>
			</div>

			<div className="row mb-7">
				<label className="col-lg-4 fw-semibold text-muted">Email</label>

				<div className="col-lg-8 fv-row">
					<span className="fw-semibold text-gray-800 fs-6">{email}</span>
				</div>
			</div>

			{mobile ? (
				<div className="row mb-7">
					<label className="col-lg-4 fw-semibold text-muted">Phone</label>

					<div className="col-lg-8 d-flex align-items-center">
						<span className="fw-bold fs-6 text-gray-800 me-2">{mobile}</span>
					</div>
				</div>
			) : null}

			<div className="row mb-3">
				<label className="col-lg-4 fw-semibold text-muted">Country</label>

				<div className="col-lg-8">
					<div
						className="fw-semibold fs-6 text-gray-800 text-hover-info"
						style={{ display: 'flex' }}
					>
						<img
							src={
								country
									? getCountryFlag(country.toUpperCase())
									: '/flag-placeholder.png'
							}
							alt={country}
							className="h-20px me-2"
						/>
						<p>{userCountry?.name}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Info

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 1647}
				height={height || 2081}
				viewBox="0 0 1647 2081"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_7412)">
					<rect
						width="1647"
						height="2081"
						rx="200"
						transform="matrix(1 0 0 -1 0 2081)"
						fill={primary || 'black'}
					/>
					<path
						d="M200 207C200 321.323 292.677 414 407 414H1241C1355.32 414 1448 321.323 1448 207V0H200V207Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M346 1911.5H1302C1406.66 1911.5 1491.5 1826.66 1491.5 1722V766C1491.5 661.342 1406.66 576.5 1302 576.5H1014.98H819.871H624.249H346C241.342 576.5 156.5 661.342 156.5 766V1722C156.5 1826.66 241.342 1911.5 346 1911.5Z"
						fill="white"
						stroke="black"
						stroke-width="71"
					/>

					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 224}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="271.616">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_7412">
						<rect
							width="1647"
							height="2081"
							fill="white"
							transform="matrix(1 0 0 -1 0 2081)"
						/>
					</clipPath>
				</defs>
				<image href={url} x="248" y="703" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2630}
				height={height || 3025}
				viewBox="0 0 2630 3025"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_175_10799)">
					<path
						d="M5.03714 2284.48C-16.6142 2216.43 34.2413 2147 105.736 2147H2524.27C2595.39 2147 2646.19 2215.75 2625.23 2283.63L2586.56 2408.86C2580.29 2429.15 2580.3 2450.87 2586.59 2471.16L2625.06 2595.27C2646.1 2663.17 2595.28 2732 2524.12 2732H107.301C35.3518 2732 -15.5552 2661.74 6.93588 2593.49L45.3062 2477.04C52.2505 2455.97 52.3672 2433.24 45.6396 2412.1L5.03714 2284.48Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M359 956C359 428.016 787.016 0 1315 0V0C1842.98 0 2271 428.016 2271 956V2800C2271 2924.26 2170.26 3025 2046 3025H1574.71H1298.86H1043.36H584C459.736 3025 359 2924.26 359 2800V956Z"
						fill={primary || '#0E1B21'}
					/>
					<ellipse cx="1315" cy="947.5" rx="838" ry="837.5" fill="white" />
					<rect
						x="2470"
						y="2658"
						width="2310"
						height="703"
						rx="97"
						transform="rotate(-180 2470 2658)"
						fill={secondary || 'white'}
					/>
					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 334}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="2421.79">
							{text}
						</tspan>
					</text>
				</g>
				<defs>
					<clipPath id="clip0_175_10799">
						<rect width="2630" height="3025" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="739" y="357" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

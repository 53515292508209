import QrCodes from '../components/qr-codes'
import QrCodeContxtProvider from '../components/qr-codes/qrCodeContxt'

const Page = () => {
	return (
		<QrCodeContxtProvider>
			<QrCodes />
		</QrCodeContxtProvider>
	)
}

export default Page

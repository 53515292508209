import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 3326}
				height={height || 3607}
				viewBox="0 0 3326 3607"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0_177_29744)">
					<path
						d="M884.141 3601.78C690.219 3572.02 529.352 3471.22 423.44 3313.07C382.832 3252.46 344.797 3161.32 331.219 3092.14C319.831 3034.15 318.914 3006.17 318.914 2714.07V2420.79H288.806C236.326 2420.79 183.152 2408.56 137.269 2386.28C65.6512 2351.32 20.0852 2279.61 5.04099 2178.17C-1.7089 2132.64 -1.68114 2087.14 5.12987 2064.19C25.8518 1994.32 136.795 1985.17 164.294 2051.12C167.85 2059.74 169.572 2075.04 169.6 2098.65C169.733 2168.3 180.599 2205.46 207.56 2228.57C227.143 2245.26 249.959 2252.21 286.651 2252.49L317.203 2252.66L318.036 2046.17L318.869 1839.65H1658.05H2997.23V2045.14V2250.66L3009.53 2253.44C3016.28 2253.44 3033.47 2256.22 3047.72 2253.44C3127.95 2247.6 3156.5 2206.99 3156.71 2098.82C3156.78 2061.55 3161.07 2045.36 3174.77 2030.65C3204.83 1998.38 3268.4 1998.91 3301.15 2030.65C3323.03 2052.62 3328.12 2072.98 3325.28 2127.58C3319.28 2242.9 3281.43 2326.2 3214.96 2370.31C3167.33 2401.88 3117.95 2416.07 3045.62 2418.96L2997.25 2421.74V2712.04C2997.25 3010.7 2996.14 3037.88 2983.11 3102.32C2964.94 3191.27 2920.95 3287.95 2865.83 3360.05C2843.83 3388.81 2793.48 3440.71 2764.72 3464.27C2694.09 3522.12 2611.94 3564.04 2524.84 3586.59C2441.36 3608.29 2488.16 3607.18 1664.41 3606.9C1045.57 3606.9 909.358 3606.79 884.141 3601.78Z"
						fill={secondary || 'white'}
					/>
					<path
						d="M334.953 1220.18V1340.86L334.934 1678.94H1674.14H3013.34V1340.86V1220.18H2448.75H1884.15L1878.51 1202.37C1871.54 1180.23 1852.43 1147.92 1836.87 1131.78L1825.21 1119.82V1030.73V941.646H1674.15H1523.08V1030.73V1119.82L1511.42 1131.78C1495.87 1147.83 1476.75 1180.12 1469.78 1202.37L1464.14 1220.18H899.547H334.953Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M793.218 1033.85C805.674 1052.6 825.509 1067.06 846.565 1072.34C865.162 1077.07 889.181 1073.54 901.764 1064.36C915.45 1054.32 934.322 1027.23 950.627 994.27C996.932 900.592 1004.99 815.036 977.46 708.62C966.374 665.73 953.138 630.851 918.5 553.222C878.745 464.134 867.134 427.725 865.189 385.642C863.273 344.561 867.523 321.809 883.328 288.766C895.531 263.26 905.866 248.436 931.81 219.12C940.088 209.83 948.977 197.313 951.477 191.194C958.31 175.229 957.338 148.027 949.532 132.729C939.563 113.064 914.119 92.482 892.231 86.0848C855.367 75.3486 822.737 85.5007 794.104 116.597C715.839 201.624 675.942 310.099 687.609 406.086C694.717 464.551 703.592 490.335 756.044 604.929C813.218 729.869 826.357 775.095 822.718 834.395C820.601 869.023 813.879 890.857 793.102 930.715C771.163 972.825 771.191 1000.56 793.218 1033.85Z"
						fill={primary || '#0E1B21'}
					/>
					<path
						d="M1175.93 997.914C1185.05 1015.27 1213.26 1041.19 1228.34 1045.92C1248.07 1052.32 1269.56 1050.96 1285.73 1042.33C1303.89 1032.71 1322.23 1006.56 1348.12 953.356C1380.79 886.268 1396.15 827.998 1399.68 757.906C1403.56 681.056 1392.9 620.31 1353.34 493.7C1340.04 451.117 1326.15 403.388 1322.51 387.812C1300.49 293.3 1310.5 221.456 1355.01 154.48C1378.83 118.627 1383.91 106.278 1383.91 84.861C1383.91 63.2216 1377.94 48.6749 1361.33 30.3176C1344.07 11.1258 1319.79 -0.0554216 1295.65 0.000206589C1260.36 0.0836627 1240.82 15.1589 1205.25 69.8136C1158.99 140.879 1138.73 210.275 1138.91 297.138C1139.05 364.448 1147.28 405.585 1187.24 538.648C1222.21 655.105 1225.84 674.074 1225.96 740.912C1226.09 816.538 1223.19 826.774 1175.37 919.367C1165.4 938.67 1165.68 978.444 1175.93 997.914Z"
						fill={primary || '#0E1B21'}
					/>

					<text
						fill={textColor || 'white'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 226}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="1538.79">
							{text}
						</tspan>
					</text>

					<rect
						x="1005"
						y="2066"
						width="1316"
						height="1316"
						rx="50"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_177_29744">
						<rect width="3326" height="3607" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="1001" y="2032" width="1344" height="1344" />
			</svg>
		</>
	)
}

export default IconFrame5

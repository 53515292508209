import { useContext, useEffect, useState } from 'react'
import { AuthContxt } from '../../store/authContxt'
import {
	MONTHLY_STANDARD,
	MONTHLY_STARTER,
	MONTHLY_SUPER,
	YEARLY_STANDARD,
	YEARLY_STARTER,
	YEARLY_SUPER,
} from '../../utils/planLookUpKeys'
import capitalizeFirstLetter from '../../utils/captilizeFirstLetter'
import { Tooltip } from 'reactstrap'

export const CONTENT_MAPPER = {
	[MONTHLY_STARTER]: { type: 'Starter' },
	[MONTHLY_STANDARD]: { type: 'Standard' },
	[MONTHLY_SUPER]: { type: 'Super' },
	[YEARLY_STARTER]: { type: 'Starter' },
	[YEARLY_STANDARD]: { type: 'Standard' },
	[YEARLY_SUPER]: { type: 'Super' },
}

const typeMapper = {
	starter: 0,
	standard: 1,
	super: 2,
}

const planRankings = {
	starter: 1,
	standard: 2,
	super: 3,
}

const MON = 'monthly'
const ANN = 'yearly'

const Plan = ({ planHandler, planPrices, form }) => {
	const { profile } = useContext(AuthContxt)
	const [curPlan, setCurPlan] = useState(0)
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [planDuration, setPlanDuration] = useState(MON)
	const { watch } = form || {}
	const { plan } = watch?.() || {}

	const isMon = planDuration === MON
	const subscribedPlanDuration = profile?.plan?.split('-')[0]?.toLowerCase()
	const subscribedPlanType = profile?.plan?.split('-')[1]?.toLowerCase()

	const isPlanDisabled = (planToCheck) => {
		if (!profile?.plan) return false

		const currentPlanRank = planRankings[subscribedPlanType]
		const planToCheckRank = planRankings[planToCheck.toLowerCase()]
		const isCheckingYearlyPlan = planDuration === ANN

		if (subscribedPlanDuration === ANN) {
			if (currentPlanRank > 1 && planDuration === MON) {
				return true
			}
			return planToCheckRank < currentPlanRank
		}

		if (subscribedPlanDuration === MON && planDuration === MON) {
			return planToCheckRank < currentPlanRank
		}

		return false
	}

	// const canSwitchToMonthly = () => {
	//   if (subscribedPlanDuration === ANN) {
	//     return planRankings[subscribedPlanType] > 1;
	//   }
	//   return true;
	// };

	const planDurationHandler = (val) => {
		if (val === MON && !canSwitchToMonthly()) {
			return
		}
		setPlanDuration(val)
		if (plan) {
			const selectedPlanType = plan.split('-')[1]
			if (!isPlanDisabled(selectedPlanType)) {
				planHandler(`${val}-${selectedPlanType}`)
				setCurPlan(typeMapper[selectedPlanType.toLowerCase()])
			}
		}
	}

	// const toggle = () => {
	//   if (subscribedPlanDuration === ANN && !canSwitchToMonthly()) {
	//     setTooltipOpen(!tooltipOpen);
	//   }
	// };

	useEffect(() => {
		if (profile?.plan) {
			planHandler(profile.plan)
			setPlanDuration(profile.plan.includes('yearly') ? ANN : MON)
			const type = profile.plan.split('-')[1]?.toLowerCase()
			setCurPlan(typeMapper[type])
		}
	}, [profile])

	const currencyMapper = {
		usd: '$',
	}

	const canSwitchToMonthly = () => {
		if (subscribedPlanDuration === ANN) {
			return subscribedPlanType === 'starter' // Only Yearly Starter can switch to Monthly
		}
		return true
	}

	const toggle = () => {
		if (!canSwitchToMonthly()) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	return (
		<>
			<div>
				<div className="mb-5">
					<h2 className="mb-3">Upgrade a Plan</h2>
					<div className="text-muted fw-semibold fs-5">
						If you need more info, please check{' '}
						<span className="link-info fw-bold">Pricing Guidelines</span>.
					</div>
				</div>

				<div
					className="nav-group nav-group-outline"
					style={{ width: 'fit-content', display: 'flex' }}
				>
					<div onMouseOver={toggle} onMouseLeave={toggle} id="monthlyTab">
						<button
							type="button"
							disabled={!canSwitchToMonthly()}
							onClick={() => planDurationHandler(MON)}
							className={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 me-2 ${
								planDuration === MON ? 'active' : ''
							}`}
						>
							Monthly
						</button>
					</div>

					<Tooltip
						placement={'top'}
						isOpen={tooltipOpen}
						target={'monthlyTab'}
						toggle={toggle}
					>
						{subscribedPlanDuration === ANN && subscribedPlanType !== 'starter'
							? 'You are currently on a Yearly plan (Standard or Super) and cannot switch to Monthly plans.'
							: 'Switch to Monthly plans.'}
					</Tooltip>

					<button
						type="button"
						onClick={() => planDurationHandler(ANN)}
						className={`btn btn-color-gray-400 btn-active btn-active-light-info rounded-1 px-6 py-3 ${
							planDuration === ANN ? 'active' : ''
						}`}
					>
						Annual
					</button>
				</div>
			</div>

			<div className="row px-4 gap-5 mt-10">
				{planPrices?.map((item, index) => {
					const {
						currency = '',
						monthlyPrice,
						yearlyPrice,
						plan: planType,
					} = item || {}
					const isDisabled = isPlanDisabled(planType)

					return (
						<label
							key={index}
							className={`nav-link col btn rounded-1 btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-light-info border-info d-flex flex-stack text-start p-6 mb-6 ${
								curPlan === index ? 'active' : ''
							}`}
							style={{
								opacity: isDisabled ? 0.5 : 1,
								cursor: isDisabled ? 'not-allowed' : 'pointer',
							}}
						>
							<div className="d-flex align-items-center me-2">
								<div className="form-check form-check-custom form-check-solid form-check-info flex-shrink-0 me-5">
									<input
										className="form-check-input"
										type="radio"
										name="plan"
										checked={curPlan === index}
										disabled={isDisabled}
										onChange={() => {
											if (!isDisabled) {
												planHandler(`${planDuration}-${planType}`)
												setCurPlan(index)
											}
										}}
									/>
								</div>

								<div className="flex-grow-1">
									<div className="d-flex align-items-center fs-2 fw-bold flex-wrap">
										{capitalizeFirstLetter(planType)}
									</div>
								</div>
							</div>

							<div className="ms-5">
								<span className="mb-2">
									{currencyMapper[currency] || currency}
								</span>
								<span className="fs-3x fw-bold">
									{isMon ? monthlyPrice : yearlyPrice}
								</span>
								<span className="fs-7 opacity-50">
									<span data-kt-element="period">
										{`/ ${isMon ? 'Mon' : 'Year'}`}
									</span>
								</span>
							</div>
						</label>
					)
				})}
			</div>
		</>
	)
}

export default Plan

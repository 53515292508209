import { useContext, useState } from 'react'
import ShortLinksAPI from '../../../services/http/shortLinks'
import DeleteModal from '../../../modals/deleteModal'
import Copy from '../../common/copy'
import { AuthContxt } from '../../../store/authContxt'
import { ShortLinkContxt } from '../shortLinkContxt'

const BulkActions = ({
	bulkLinks,
	bulkLinksHandler,
	bulkDeleteLoading,
	setBulkDeleteLoading,
}) => {
	// Starred Toggle States
	const [starredLoading, setStarredLoading] = useState(false)
	const { fetchQuota } = useContext(AuthContxt)
	const { tagsHandler, setShortLinks, setNotLinksScreen, getBioPages } =
		useContext(ShortLinkContxt)

	// Delete Link States
	const [deleteLoading, setDeleteLoading] = useState(false)
	const [deleteModal, setDeleteModal] = useState()

	const copyHandler = () => {
		const copiedData = bulkLinks.reduce((result, link) => {
			return `https://${link.brandedDomain}/${link.slashTag},    ` + result
		}, '')
		return copiedData
	}

	const starredHandler = (status) => {
		setStarredLoading(true)
		const auth_token = localStorage.getItem('auth_token')
		const ids = bulkLinks.map((item) => item.id)
		const requestData = {
			isStarred: !status,
			ids,
		}
		ShortLinksAPI.bulkStarredShortLink(auth_token, requestData)
			.then((data) => {
				setShortLinks((preState) => {
					return preState.map((item) =>
						ids.includes(item.id)
							? { ...item, isStarred: !item.isStarred }
							: item
					)
				})
				bulkLinksHandler([])
			})
			.catch((error) => {
				setStarredLoading(false)
			})
			.finally(() => {
				setStarredLoading(false)
			})
	}

	const deleteHandler = () => {
		setBulkDeleteLoading(true)
		setDeleteLoading(true)
		setDeleteModal(false)
		const auth_token = localStorage.getItem('auth_token')

		const ids = bulkLinks.map((item) => item.id)
		ShortLinksAPI.bulkDeleteShortLink(auth_token, { bulkLinks })
			.then((data) => {
				setShortLinks((preState) => {
					const remainingLinks = preState.filter(
						(item) => !ids.includes(item.id)
					)
					remainingLinks?.length === 0 && setNotLinksScreen('notFound')
					tagsHandler(remainingLinks)
					return remainingLinks
				})
				fetchQuota()
				getBioPages()
				bulkLinksHandler([])
			})
			.catch((error) => {
				setBulkDeleteLoading(false)
				setDeleteLoading(false)
			})
			.finally(() => {
				setBulkDeleteLoading(false)
				setDeleteLoading(false)
			})
	}

	const allStarred = bulkLinks.every((item) => item.isStarred === true)
	const allNotStarred = bulkLinks.every((item) => item.isStarred === false)
	const isStarred = allStarred || allNotStarred

	return (
		<>
			<div
				className="w-fit-content d-flex px-8 gap-5 py-4 bulkuploadmenu"
				style={{ top: '22px', left: '200px' }}
			>
				{isStarred ? (
					<button
						type="button"
						className="btn btn-icon rounded-1 bg-light-info"
						onClick={() => starredHandler(allStarred)}
						disabled={starredLoading}
					>
						{' '}
						<i
							className={`fa-star fs-2 cursor-pointer ${
								allStarred
									? 'fa-solid'
									: 'fa-regular on-hvr-yellow text-warning'
							} `}
							style={{ color: allStarred ? 'gold' : '' }}
						></i>
					</button>
				) : null}

				<button
					type="button"
					className="btn btn-icon rounded-1 bg-light-info"
					disabled={starredLoading}
				>
					<Copy className="bulkcopy" handler={copyHandler} />
				</button>

				<button
					type="button"
					className="btn btn-icon rounded-1 bg-light-info"
					onClick={() => setDeleteModal(true)}
					disabled={deleteLoading}
				>
					<i className="fa-regular fa-trash-can fs-2 text-danger cursor-pointer" />
				</button>
			</div>

			{/* Modals */}
			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={deleteHandler}
				rightBtnHandler={() => setDeleteModal(false)}
				data={{
					heading: 'Delete shot link',
					description: 'Are you sure you want to delete this short link?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default BulkActions

import React, { useState, useEffect, useRef } from 'react'

const WebPreview = ({ formData }) => {
	const [hasError, setHasError] = useState(false)
	const ref = useRef(null)

	useEffect(() => {
		// Reset error when the URL changes
		setHasError(false)
	}, [formData?.destinationUrl])

	const handleIframeLoad = () => {}

	const handleIframeError = () => {
		// Use a timeout to simulate detection of an error in case iframe blocks or fails
	}

	return (
		<div className="w-100" style={{ height: '486px' }}>
			<div className="web-qr-input-box bg-gray-300 p-5">
				<input
					className="w-100 form-control"
					placeholder={formData?.destinationUrl || 'enter website URL'}
					type="text"
					value={formData?.destinationUrl || ''}
					readOnly
				/>
			</div>

			<iframe
				ref={ref}
				id="web-view"
				title="Website Preview"
				className="h-100 w-100"
				src={formData?.destinationUrl || 'https://divsly.com'}
				onLoad={handleIframeLoad}
				onError={handleIframeError}
				sandbox="allow-scripts allow-same-origin"
			></iframe>
		</div>
	)
}

export default WebPreview

import ShortLinksMain from '../components/short-links'
import ShortLinkContxtProvider from '../components/short-links/shortLinkContxt'

const ShortLinks = () => {
	return (
		<ShortLinkContxtProvider>
			<ShortLinksMain />
		</ShortLinkContxtProvider>
	)
}

export default ShortLinks

import { useEffect, useRef, useState } from 'react'
import QrCodesAPI from '../../services/http/qrCodes'
import isValidHttpUrl from '../../utils/isValidHttpUrl'
import convertToURLFriendly from '../../utils/convertToURLFriendly'
import Frames from '../qr-codes/create-qr-code/frames-svg'

// Load the environment variable
const BASE_URL = process.env.REACT_APP_QR_BASE_URL

export const options = {
	data: BASE_URL,
	config: {
		erf1: [],
		erf2: ['fh'],
		erf3: ['fv'],
		brf1: [],
		brf2: ['fh'],
		brf3: ['fv'],
		gradientType: 'radial',
		gradientOnEyes: false,
		//Logo
		logo: '',
		// corner
		eye: 'frame0',
		eyeBall: 'ball0',
		// pattern
		body: 'round',
		// Color
		bodyColor: '#000000',
		// Background color
		bgColor: '#FFFFFF',
	},
	size: 300,
	download: 'imageUrl',
	file: 'png',
}

const patternMapper = {
	classy: 'round',
	rounded: 'square',
	'extra-rounded': 'circle-zebra',
	'classy-rounded': 'circular',
	square: 'mosaic',
	dots: 'circle',
}

const cornerMapper = {
	square: {
		eye: 'frame0',
		eyeBall: 'ball0',
	},
	'extra-rounded': {
		eye: 'frame13',
		eyeBall: 'ball15',
	},
	none: {
		eye: 'frame10',
		eyeBall: 'ball15',
	},
	8: {
		eye: 'frame12',
		eyeBall: 'ball14',
	},
	4: {
		eye: 'frame2',
		eyeBall: 'ball2',
		erf1: ['fh'],
		brf1: ['fh'],
	},
	5: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fh'],
		erf3: ['fh'],
		brf1: ['fh'],
		brf3: ['fh'],
	},
	dot: {
		eye: 'frame1',
		eyeBall: 'ball1',
		erf1: ['fv'],
		erf2: ['fv'],
		brf1: ['fv'],
		brf2: ['fv'],
	},
	9: {
		eye: 'frame0',
		eyeBall: 'ball18',
	},
}

const QrCode = ({ values, width = '100%', valueHandler = () => {} }) => {
	const [qrImg, setQrImg] = useState('')
	const latestRequestRef = useRef(0)

	const {
		color,
		bgColor,
		pattern,
		frame,
		qrLogoId,
		brandedDomain,
		slashTag,
		corner,
		isBgTransparent,
		primary,
		secondary,
		text,
		textColor,
		destinationUrl,
	} = values
	const createQrCodeHandler = (requestId) => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			QrCodesAPI.generateQrCode(auth_token, {
				payload: {
					...options,
					config: {
						...options?.config,
						bodyColor: color,
						bgColor: isBgTransparent ? 'transparent' : bgColor,
						eye1Color: color,
						eye2Color: color,
						eye3Color: color,
						eyeBall1Color: color,
						eyeBall2Color: color,
						eyeBall3Color: color,
						body: patternMapper[pattern],
						...cornerMapper[corner],
						logo: qrLogoId,
						// logoMode: 'clean'
					},
					data: isValidHttpUrl(destinationUrl)
						? `https://${brandedDomain}/${convertToURLFriendly(slashTag)}?r=qr`
						: 'https://app.divsly.com/',
				},
			})
				.then(({ data }) => {
					if (requestId === latestRequestRef.current) {
						const byteArray = new Uint8Array(data.data.data)

						const blob = new Blob([byteArray], { type: 'image/png' })

						const url = URL.createObjectURL(blob)

						setQrImg(data.data)
						valueHandler('qr', data.data)
					}
				})
				.catch((error) => {
					console.log('Error while creating qr code: ', error)
				})
		} catch (e) {
			console.log('Error while creating qr code: ', e)
		}
	}

	useEffect(() => {
		const newRequestId = latestRequestRef.current + 1
		latestRequestRef.current = newRequestId
		createQrCodeHandler(newRequestId)
	}, [
		options,
		color,
		bgColor,
		pattern,
		brandedDomain,
		slashTag,
		corner,
		qrLogoId,
		isBgTransparent,
		destinationUrl,
	])

	return (
		<div style={{ textAlign: 'center' }} className="w-100">
			{/* { ? (
    qrImg.startsWith('//api') ? (
      <img src={qrImg} alt="Qr code" width={width} />
    ) : (
      <div dangerouslySetInnerHTML={{ __html: qrImg }} />
    )
  ) : (
    <img src="/default-qr.svg" alt="Qr code" width={width} />
  )}
   */}

			{qrImg ? (
				frame ? (
					<Frames
						type={frame.type}
						minFontSize={frame.minFontSize}
						maxFontSize={frame.maxFontSize}
						width="300"
						height={300}
						url={qrImg}
						primary={primary}
						textColor={textColor}
						secondary={secondary}
						text={text}
					/>
				) : (
					<img src={qrImg} alt="Qr code" width={width} />
				)
			) : (
				<img src="/default-qr.svg" alt="Qr code" width={width} />
			)}
		</div>
	)
}
export default QrCode

import { roun } from '@amcharts/amcharts5/.internal/core/util/Time'
import React, { useState, useRef, useEffect } from 'react'
import { Document, Page } from 'react-pdf'

const FileUpload = ({ setFormData, formData, fileUpdater, qrType }) => {
	const [file, setFile] = useState(null)
	const [imageList, setImageList] = useState([])
	const [uploadPercentage, setUploadPercentage] = useState(0)
	const [uploadStatus, setUploadStatus] = useState('')
	const [hideLoadBar, setHideLoadBar] = useState(true)
	const dropAreaRef = useRef(null)
	const fileInputRef = useRef(null) // Reference for the file input

	// Handle file selection manually or via drag-and-drop
	const handleFileChange = async (e) => {
		const selectedFile =
			qrType === 'images' ? e.target.files : e.target.files[0]

		// If qrType is "images", validate the file
		await validateFile(selectedFile)
	}

	// Handle file drop
	const handleFileDrop = (e) => {
		e.preventDefault()
		const selectedFile = e.dataTransfer.files[0]
		validateFile(selectedFile)
		dropAreaRef.current.style.border = '2px dashed #ccc' // Reset the border style
	}

	const validateFile = async (selectedFile) => {
		if (selectedFile) {
			setHideLoadBar(false)
			setUploadPercentage(0)

			setFile(selectedFile)
			const auth_token = localStorage.getItem('auth_token')

			const startTime = Date.now()

			let percentage = 0

			const intervalId = setInterval(() => {
				const elapsed = Date.now() - startTime

				percentage = Math.min(
					(elapsed / (10000 * selectedFile?.length)) * 100,
					100
				)
				setUploadPercentage(percentage)
			}, 100)
			let newImageList = []
			if (qrType === 'images') {
				newImageList = await fileUpdater(selectedFile, auth_token)
			}

			const totalTime = Date.now() - startTime

			clearInterval(intervalId)

			setUploadPercentage(100)
			setTimeout(() => {
				setHideLoadBar(true)
			}, 1000)

			setImageList(newImageList)
			if (qrType === 'images') {
				setFormData((prev) => ({
					...prev,
					imageList: newImageList?.length > 0 ? [...newImageList] : [],
				}))
			} else {
				setFormData((prev) => ({
					...prev,
					file: selectedFile,
					[qrType]: URL.createObjectURL(selectedFile),
				}))
			}
		}
	}

	// Dragging events to style drop area
	const handleDragOver = (e) => {
		e.preventDefault()
		dropAreaRef.current.style.border = '2px solid #4caf50' // Change border on drag over
	}

	const handleDragLeave = (e) => {
		e.preventDefault()
		dropAreaRef.current.style.border = '2px dashed #ccc' // Reset border on drag leave
	}

	// Trigger file input click when area is clicked
	const handleDropAreaClick = () => {
		fileInputRef.current.click() // Trigger hidden file input click
	}

	return (
		<div>
			{/* Drag-and-drop area that also acts as a clickable area */}
			<div
				ref={dropAreaRef}
				onClick={handleDropAreaClick} // Open file picker on click
				onDrop={handleFileDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				style={{
					width: '100%',
					height: '150px',
					border: '2px dashed #ccc',
					borderRadius: '5px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer', // Change cursor to pointer for clickable effect
					marginBottom: '10px',
				}}
			>
				{console.log(file, formData, file && formData?.pdf, 'object')}
				{file && (formData?.pdf || formData?.audio) ? (
					<p>{file.name}</p>
				) : (
					<p>Drag & drop file here, or click to select</p>
				)}

				{/* Hidden file input */}
				<input
					ref={fileInputRef}
					type="file"
					accept={
						qrType === 'pdf'
							? '.pdf'
							: qrType === 'audio'
								? 'audio/*'
								: qrType === 'images'
									? 'image/jpeg, image/png'
									: ''
					}
					multiple={qrType === 'images'}
					onChange={handleFileChange}
					style={{ display: 'none' }} // Hide the file input element
				/>
			</div>

			{/* Show progress bar after file selection */}
			{/* Show progress bar after file selection */}
			{
				<>
					{file && qrType !== 'pdf' && qrType !== 'audio' && !hideLoadBar && (
						<div style={{ marginTop: '10px' }}>
							<div
								style={{
									width: '100%',
									backgroundColor: '#f0f0f0',
									borderRadius: '5px',
									overflow: 'hidden', // Ensure the progress bar stays within its container
								}}
							>
								<div
									style={{
										width: `${uploadPercentage}%`,
										height: '10px',
										backgroundColor: '#4caf50',
										borderRadius: '5px',
										transition: 'width 0.5s ease', // Add transition for smooth animation
									}}
								></div>
							</div>
							<p>{Math.ceil(uploadPercentage)}%</p>
						</div>
					)}
					{formData?.audio && (
						<div className="position-relative border border-dark border-opacity-25 rounded-1">
							<i
								onClick={() => {
									setFormData((prev) => ({ ...prev, audio: '', file: null }))
									setFile(null)
								}}
								style={{
									top: '50%',
									right: '10px',
									zIndex: '5',
									transform: 'translateY(-50%)',
								}}
								className="fa-solid fa-circle-xmark  fs-3 text-danger position-absolute"
							></i>

							<div style={{ padding: '10px' }} className="">
								{formData?.file?.name}
							</div>
						</div>
					)}
					{formData?.pdf && (
						<div className="position-relative" style={{ width: 'fit-content' }}>
							<i
								onClick={() => {
									setFormData((prev) => ({ ...prev, pdf: '', file: null }))
									setFile(null)
								}}
								style={{
									height: '32px',
									top: '10px',
									right: '10px',
									zIndex: '5',
								}}
								className="fa-solid fa-circle-xmark  fs-3 text-danger position-absolute"
							></i>
							<div
								className="pdf-qr-preview m-0"
								style={{ width: 'fit-content' }}
							>
								<Document
									file={formData?.pdf}
									onLoadError={(error) =>
										console.error('Error while loading document', error)
									}
									onLoadSuccess={() => {}}
								>
									<Page pageNumber={1} height={100} width={300} />
								</Document>
							</div>
						</div>
					)}
				</>
			}

			{/* Upload status message */}
			{uploadStatus && <p>{uploadStatus}</p>}
		</div>
	)
}

export default FileUpload

import httpMethods from '..'

const AdminAPI = {
	getSubscribers: async (params) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.get(`/subscribers`, {
				params,
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	updateUserStatus: async (payload) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.post(`/active`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getStats: async (payload) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.post(`/stats`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getCurrentWeekUsers: async () => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.get(`/admin-users`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getSubscriptions: async (payload) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.post(`/subscriptions`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getRecentSubscriptions: async () => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.get(`/recent-subscriptions`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getRevenue: async (payload) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.post(`/revenue`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getSales: async (payload) => {
		const token = localStorage?.getItem('auth_token')
		return httpMethods
			.post(`/sales`, payload, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},
}

export default AdminAPI

import './assets/style.css'
import divslyicon from './assets/divsly-c.svg'
import { Link } from 'react-router-dom'
import { getBackground, getButton } from './stylesMapper'
import { SOCIAL_MAPPER } from '../customization/socialDropdown'

const Layout4 = ({ values }) => {
	const {
		avatar,
		links,
		name,
		bio,
		bg,
		bgType,
		bottomBg,
		bottomBgType,
		socials,
		fontClr,
		shadowClr,
		button,
		hideLogo,
		descriptionClr,
		titleClr,
		theme,
		btnClr,
		font,
	} = values

	return (
		<div
			linkinbiotheme={theme}
			className="layoutfour"
			style={{
				...(getBackground(bottomBg)[bottomBgType] || {
					backgroundColor: `#ffffff`,
				}),
				fontFamily: font,
			}}
		>
			<div className="main-container_four">
				<div className="theme-main-container shadow-sm">
					<div className="profile_container_four_after">
						<div
							className="profile_container_four"
							style={
								bgType !== 'video'
									? {
											...getBackground(bg)[bgType],
										}
									: {}
							}
						>
							<div className="bio_background">
								{bgType === 'video' && bg !== '' ? (
									<div
										style={{
											position: 'absolute',
											width: '100%',
											height: '100%',
											left: 0,
											top: 0,
										}}
									>
										<video
											key={bg}
											width={'100%'}
											height={'100%'}
											controls={false}
											autoPlay
											muted
											loop
										>
											<source src={bg} type="video/mp4" />
										</video>
									</div>
								) : null}
							</div>
							<div class="d-flex flex-column align-items-center">
								<div>
									<div class="mb-2 profile_img_four position-relative">
										<img src={avatar || '/placeholder.png'} alt={name} />
									</div>
									<div class="d-flex align-items-center justify-content-center mx-3">
										<h1
											class="profile_name"
											style={{
												color: titleClr,
											}}
										>
											{name}
										</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						className="container pb-5"
						style={{
							...(getBackground(bottomBg)[bottomBgType] || {
								backgroundColor: `#ffffff`,
							}),
						}}
					>
						<div className="d-flex flex-column align-items-center position-relative">
							<div class="px-10 mt-2">
								<h2
									class="profile_description"
									style={{
										color: descriptionClr,
									}}
								>
									{bio}
								</h2>
							</div>

							<div className="social_icons">
								{socials
									?.filter(
										(social) =>
											social?.isValid && social?.url !== '' && social?.id !== ''
									)
									.map((social, index) => {
										return (
											<Link
												key={index}
												to={social?.url || '#'}
												className="social_icon"
											>
												<img
													src={SOCIAL_MAPPER[social?.id]?.icon}
													alt={'Social'}
													className="rounded-circle h-20px me-2"
												/>
											</Link>
										)
									})}
							</div>
						</div>
						<div className="buttons_list">
							{links
								?.filter((link) => link?.isActive)
								?.map((item) => {
									const { btnLabel, brandedDomain, slashTag } = item
									return (
										<div
											className="link_button"
											style={{
												...getButton(btnClr, shadowClr)[button],
											}}
										>
											<div className="h-100 w-100">
												<Link
													to={`https://${brandedDomain}/${slashTag}?r=lb`}
													target="_blank"
													className="link_text"
													style={{
														color: fontClr,
													}}
												>
													{btnLabel}
												</Link>
											</div>
										</div>
									)
								})}
						</div>
						{!hideLogo ? (
							<div className="divsly_brand">
								<Link to="https://divsly.com">
									<img style={{ width: '80px' }} src={divslyicon} alt="" />
								</Link>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Layout4

import { useContext, useEffect, useRef, useState } from 'react'
import SubscriptionApi from '../../services/http/subscription'
import { Spinner } from 'reactstrap'
import { useStripe } from '@stripe/react-stripe-js'
import delayResponse from '../../utils/delayResponse'
import { AuthContxt } from '../../store/authContxt'
import { UserSettingsContxt } from '../user-settings/userSettingContxt'
import { PLAN } from '../../utils/enum'
import RazorPaySubcription from '../../services/http/razorPaySubcription'
import axios from 'axios'
import { Link } from 'react-router-dom'
import BillingAddress from '../user-settings/billing-info/billing/addess'
import AddressForm from '../user-settings/billing-info/billing/addressForm'
const subscriptionStatusMapper = {
	active: 'Subscribed to a plan, updates will take a time.',
	past_due: 'Payment processing due to payment due.',
	canceled: 'You subscription has been canceled!',
	incomplete: 'Subscribing to a plan, updates will take some time.',
}

const Summary = ({ form, planPrices }) => {
	const [showForm, setShowForm] = useState(false)
	const [edittedAddress, setEdittedAddress] = useState(null)
	const addressHandler = (action, data) => {
		if (action === 'update') {
			setEdittedAddress(data)
			setShowForm(true)
		} else {
			billingAddressHandler(action, data)
		}
	}
	const { profile } = useContext(AuthContxt)
	const { selectedAddress } = useContext(UserSettingsContxt)
	const { watch } = form
	const { plan } = watch()
	const stripe = useStripe()
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState()
	const [successMessage, setSuccessMessage] = useState()
	const [statusMsg, setStatusMsg] = useState('')
	const [showPaymentOptions, setShowPaymentOptions] = useState(false)
	const [planQuota, setPlanQuota] = useState({})
	const isAnnual = plan?.includes('yearly')
	const billingAddressRef = useRef(null)

	// Function to scroll to the BillingAddress component
	const handleScrollToBillingAddress = () => {
		if (billingAddressRef.current) {
			billingAddressRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}
	const [errorMessage, setErrorMessage] = useState('')

	// Plan info
	const selectedPlanType = plan?.split('-')[1]
	const subscribedPlanType = profile?.plan?.split('-')[1]
	const subscribedPlanDuration = profile?.plan?.split('-')[0]
	const subscribedPlan = profile?.plan

	const isAlreadySubscribed =
		selectedPlanType === subscribedPlanType &&
		subscribedPlanDuration === 'monthly'

	const resetErrorMessage = () => {
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const razorPay_Key = process.env.RAZORPAY_KEY

	const purchaseHandler = async () => {
		try {
			if (!selectedAddress) {
				setStatusMsg('Please select address.')
				setTimeout(() => {
					setStatusMsg('')
				}, 500)
				return
			}
			setLoading(true)
			const values = watch()
			if (plan === 'monthly-super') {
				values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY
			} else if (plan === 'yearly-super') {
				values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY
			}
			const response = await SubscriptionApi.createSubscription({
				...values,
				address: selectedAddress,
			})
			if (response?.data?.error) {
				setMessage(response.data?.data?.message || 'Something went wrong')
				setLoading(false)
				resetErrorMessage()
			} else {
				setStatusMsg('Please wait...')
				if (response?.data?.status === 'requires_action') {
					const result = await stripe.confirmCardPayment(
						response?.data?.client_secret
					)
					if (result.error) {
						setStatusMsg(
							result.error?.message || 'Payment is not processed yet'
						)
						console.log(result.error)
					} else {
						setStatusMsg(subscriptionStatusMapper[response.data?.data?.status])
						await delayResponse(5)
					}
				} else {
					await delayResponse(5)
					setStatusMsg(
						subscriptionStatusMapper[response.data?.data?.status || 'active']
					)
				}
				setLoading(false)
			}
		} catch (e) {
			console.log('Error while creating subscription: ', e)
			setMessage(
				e?.response?.data?.data?.message ||
					e?.response?.data?.message ||
					'Something went wrong'
			)
			setLoading(false)
			resetErrorMessage()
		}
	}

	const MON = 'monthly'
	const latestPlan = useRef('')

	useEffect(() => {
		if (selectedPlanType) {
			const fetchDefaultPlan = async () => {
				latestPlan.current = selectedPlanType
				try {
					const response =
						await SubscriptionApi.getDefaultPlan(selectedPlanType)
					if (!response.data.error && latestPlan.current === selectedPlanType) {
						setPlanQuota(response?.data?.data)
					}
				} catch (e) {
					console.log('Error while fetching default plan: ', e)
				}
			}

			fetchDefaultPlan()
		}
	}, [selectedPlanType])

	const {
		connectedDomain = 0,
		qrCode = 0,
		bulkShortening = 0,
		linkInBioPages = 0,
		linkRedirect = 0,
		linkEdit = 0,
		shortlink = 0,
	} = planQuota || {}

	const selectedPlan = planPrices?.filter((item) =>
		plan?.includes(item?.plan)
	)[0]
	const {
		currency = 'usd',
		monthlyPrice = 0,
		yearlyPrice = 0,
	} = selectedPlan || {}
	const currencyMapper = {
		usd: '$',
	}
	const calculatedPrice = `${currencyMapper[currency] || currency}${
		isAnnual ? yearlyPrice : monthlyPrice
	}`

	// const monthlyTotal = monthlyPrice * 12;
	// const yearlyTotal = yearlyPrice * 12;
	const monthlyTotal = monthlyPrice
	const yearlyTotal = yearlyPrice
	const savings = monthlyTotal - yearlyTotal

	//////////////////////////

	useEffect(() => {
		// Add the Razorpay script to the page
		const script = document.createElement('script')
		script.src = 'https://checkout.razorpay.com/v1/checkout.js'
		script.async = true
		document.body.appendChild(script)

		// Cleanup on component unmount
		return () => {
			document.body.removeChild(script)
		}
	}, [])

	///////////////////////////

	// razorpay function to create subscripion ID by waqas

	const handleRazorPaySubscription = async () => {
		setLoading(true)
		const values = watch()
		try {
			if (plan === 'monthly-super') {
				values.plan = process.env.REACT_APP_MONTHLY_SUPER_KEY
			} else if (plan === 'yearly-super') {
				values.plan = process.env.REACT_APP_YEARLY_SUPER_KEY
			}

			const response = await RazorPaySubcription.createSubscription({
				...values,
			})

			return response
		} catch (error) {}
	}

	const handlePayment = async () => {
		const response = await handleRazorPaySubscription()

		const options = {
			key: process.env.REACT_APP_RAZORPAY_KEY,
			subscription_id: `${response?.data?.razorSubscription?.id}`,
			name: `${profile?.firstName + ' ' + profile?.lastName}`,
			description: 'Subscription',
			image: `${profile?.avatar}`,
			prefill: {
				name: `${profile?.firstName + ' ' + profile?.lastName}`,
				email: `${profile?.email}`,
				contact: `${profile?.mobile}`,
			},
			theme: {
				color: '#F37254',
			},
			handler: async function (response) {
				try {
					const paymentVerificationResponse = await fetch(
						`${process.env.REACT_APP_API_URL}/payment_verification?id=${profile.id}&plan=${plan}`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								razorpay_payment_id: response.razorpay_payment_id,
								razorpay_subscription_id: response.razorpay_subscription_id,
								razorpay_signature: response.razorpay_signature,
							}),
						}
					)

					const result = await paymentVerificationResponse.json()

					if (paymentVerificationResponse.ok && result) {
						setSuccessMessage(result?.message)
						setLoading(false)
					} else {
						setErrorMessage('Payment verification failed')
						setLoading(false)
					}
				} catch (error) {
					setErrorMessage('Payment verification failed')
					setLoading(false)
				}
			},
		}

		const rzp1 = new window.Razorpay(options)
		rzp1.open()
	}
	// Fetch Billing Address
	const { billingAddresses, billingAddressHandler } =
		useContext(UserSettingsContxt)

	return (
		<div className="flex-column flex-lg-row-auto w-100 w-xl-400px mb-10">
			<div
				className="card card-flush bg-light border-info border-opacity-25 mb-0"
				style={{ height: 'fit-content', position: 'sticky', top: '5px' }}
			>
				<div className="card-header">
					<div className="card-title">
						<h2>Purchase summary</h2>
					</div>
				</div>

				<div className="card-body pt-0 fs-6">
					<div className="separator separator-dashed mb-7"></div>

					<div className="mb-7">
						<h5 className="mb-4">Plan details</h5>

						<div className="mb-0">
							<span className="badge badge-success me-2">{`${selectedPlanType} Plan`}</span>
							<span className="fw-semibold text-gray-600">
								{`${calculatedPrice} / ${isAnnual ? 'Year' : 'Month'}`}
							</span>
						</div>
						{isAnnual && selectedPlanType !== PLAN.STARTER ? (
							<div className="mb-0 mt-4">
								<div className="alert alert-success bg-light-success border-left-4 rounded-1 border-top-dashed border-right-dashed border-bottom-dashed border-left-">
									<div className="fs-6 fs-semibold">
										Save{' '}
										<span className="fw-bold">
											{' '}
											{currencyMapper[currency] || currency}
											{savings.toFixed(2)}{' '}
										</span>{' '}
										when Purchase Annually{' '}
										<span className="fw-bold">
											({((savings / monthlyTotal) * 100).toFixed(0)}%)
										</span>
									</div>
								</div>
							</div>
						) : null}

						<div className="mt-5">
							<ul>
								<li>{`${shortlink} Shortlink/mon`}</li>
								<li>{`${connectedDomain} Connected domains/mon`}</li>
								<li>{`${qrCode} Qr Codes/mon`}</li>
								<li>{`${
									linkRedirect === null ? 'Unlimited' : linkRedirect
								} Link Redirects`}</li>
								<li>{`${
									linkEdit === null ? 'Unlimited' : linkEdit
								} Link Edits`}</li>
								<li>{`Bulk Shortening ${bulkShortening}/CSV`}</li>
								<li>{`${linkInBioPages} Link-in-bio`}</li>
							</ul>
						</div>
					</div>

					<div className="separator separator-dashed mb-4"></div>

					{/* Display the "Already Subscribed" message */}
					{isAlreadySubscribed && (
						<div className="alert alert-info text-center mb-4">
							You are already subscribed to this plan.
						</div>
					)}

					<div className="d-flex justify-content-between align-items-center mb-4">
						<h5 className="fw-bold fs-1 mb-0">
							{selectedPlanType === PLAN.STARTER
								? 'Billed '
								: `Billed ${subscribedPlan !== plan ? 'Now' : ''}`}
						</h5>
						<div className="fw-bold fs-2x">{calculatedPrice}</div>
					</div>

					<div className="separator separator-dashed mb-4"></div>

					<div
						style={{
							textAlign: 'end',
						}}
					>
						{message ? (
							<span className="text-error fs-6 fw-bold">{message}</span>
						) : null}
					</div>

					<div
						style={{
							textAlign: 'end',
						}}
					>
						{statusMsg !== '' ? (
							<span className="link-primary fs-6 fw-bold">{statusMsg}</span>
						) : null}
					</div>

					{/* Conditionally render payment options */}

					{!isAlreadySubscribed && (
						<div className="mt-4">
							{/* <button
                onClick={purchaseHandler}
                className="btn btn-success w-100 mb-3"
              >
                Pay with Stripe
              </button> */}
							<div
								style={{
									textAlign: 'end',
								}}
							>
								{successMessage !== '' ? (
									<span className="link-primary fs-6 fw-bold">
										{successMessage}
									</span>
								) : errorMessage !== '' ? (
									<span className="link-danger fs-6 fw-bold">
										{errorMessage}
									</span>
								) : null}
							</div>
							<>
								{
									billingAddresses && billingAddresses.length ? (
										<div>
											{loading ? (
												<Spinner />
											) : (
												<button
													onClick={handlePayment}
													className="btn btn-danger w-100"
												>
													{subscribedPlan !== subscribedPlanDuration
														? 'Upgrade Plan'
														: 'Pay with Razorpay'}
												</button>
											)}
										</div>
									) : (
										//             <div>
										//             {loading ? (
										//               <Spinner />
										//             ) : (
										//               <Link to={"/user-settings"}>
										//               <button

										//                 className="btn btn-danger w-100"
										//               >
										// Add Billing Address Before Upgradation
										//               </button>
										//               </Link>
										//             )}
										//           </div>
										<div>
											{loading ? (
												<Spinner />
											) : (
												//     <>
												//     <div className="py-5">Provide the <span style={{ color: '#F0406B', textDecoration: 'underline', cursor:"pointer" }}>Billing Address</span> before selecting {subscribedPlan !== subscribedPlanDuration
												//         ? "Upgrade Plan"
												//         : "Pay with Razorpay"}.</div>
												//     <button
												// // onClick={handlePayment}
												// onClick={<BillingAddress/>}
												//       className="btn btn-danger w-100"
												//     >
												//       {subscribedPlan !== subscribedPlanDuration
												//         ? "Upgrade Plan"
												//         : "Pay with Razorpay"}
												//     </button>
												//     </>
												<div>
													<div className="py-5">
														Provide the{' '}
														<span
															style={{
																color: '#F0406B',
																textDecoration: 'underline',
																cursor: 'pointer',
															}}
															// onClick={handleScrollToBillingAddress}
															onClick={() => setShowForm(true)} // Scroll to BillingAddress when clicked
														>
															Billing Address
														</span>{' '}
														before selecting{' '}
														{subscribedPlan !== subscribedPlanDuration
															? 'Upgrade Plan'
															: 'Pay with Razorpay'}
														.
													</div>

													<button className="btn btn-danger w-100">
														{subscribedPlan !== subscribedPlanDuration
															? 'Upgrade Plan'
															: 'Pay with Razorpay'}
													</button>
													{showForm ? (
														<AddressForm
															addressHandler={addressHandler}
															edittedAddress={edittedAddress}
															toggleHandler={() => {
																setShowForm(false)
																setEdittedAddress(null)
															}}
														/>
													) : null}
												</div>
											)}
										</div>
									)
									//
								}
							</>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default Summary

import CreateQRCode from '../../components/qr-codes/create-qr-code'
import PageWrapper from '../../components/wrapper/pageWrapper'
const Index = () => {
	return (
		<PageWrapper>
			<CreateQRCode />
		</PageWrapper>
	)
}

export default Index

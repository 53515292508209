import { useEffect } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5geodata_worldLow from '@amcharts/amcharts5-geodata/worldLow'

const FLAG_BASE_URL =
	'https://purecatamphetamine.github.io/country-flag-icons/3x2/'

const Map = ({ countriesHandler = () => {}, countries = [] }) => {
	let groupData = [
		{
			name: 'World Map',
			data: countries,
		},
	]

	useEffect(() => {
		const root = am5.Root.new('chartdiv')
		root._logo.dispose()
		root?.setThemes([am5themes_Animated.new(root)])

		const chart = root?.container?.children?.push(
			am5map.MapChart.new(root, {
				panX: 'rotateX',
				projection: am5map?.geoNaturalEarth1(),
			})
		)

		const polygonSeries = chart?.series?.push(
			am5map?.MapPolygonSeries.new(root, {
				geoJSON: am5geodata_worldLow,
				exclude: ['AQ'],
			})
		)

		polygonSeries.mapPolygons.template.setAll({
			tooltipText: '{name}',
			toggleKey: 'active',
			interactive: true,
			fill: am5.color('#D3D3D3'),
		})

		polygonSeries.mapPolygons.template.states.create('hover', {
			fill: root.interfaceColors.get('primaryButtonHover'),
		})

		polygonSeries?.mapPolygons?.template?.states?.create('hover', {
			fill: am5.color(0x677935),
		})

		polygonSeries.mapPolygons.template.states.create('active', {
			fill: root.interfaceColors.get('primaryButtonActive'),
		})

		polygonSeries?.mapPolygons?.template?.events?.on('click', (e) => {
			countriesHandler(e.target.dataItem.dataContext.name)
		})

		chart?.set('zoomControl', am5map.ZoomControl.new(root, {}))

		var colors = am5.ColorSet.new(root, {
			step: 2,
		})
		colors.next()

		am5.array.each(groupData, function (group) {
			var countries = []
			var color = colors.next()

			am5.array.each(group.data, function (country) {
				countries.push(country.id)
			})

			var polygonSeries = chart.series.push(
				am5map.MapPolygonSeries.new(root, {
					geoJSON: am5geodata_worldLow,
					include: countries,
					name: group.name,
					fill: am5.color('#A1A0EC'),
				})
			)

			polygonSeries.mapPolygons.template.setAll({
				tooltipHTML: `{percent} <img src=${FLAG_BASE_URL}{id}.svg alt={id} width="18" height="18"> {name}`,
				interactive: true,
				toggleKey: 'active',
				fill: color,
				strokeWidth: 1,
			})

			polygonSeries.mapPolygons.template.states.create('hover', {
				fill: am5.Color.brighten(color, -0.3),
			})

			polygonSeries.data.setAll(group.data)
		})

		return () => {
			root?.dispose()
		}
	}, [countries])

	return (
		<div
			id="chartdiv"
			className="clickscanbylocationmap"
			style={{ height: '300px' }}
		></div>
	)
}

export default Map

import { useContext, useState } from 'react'
import PaymentCard from './paymentCard'
import CardNote from './cardNote'
import CardForm from './cardForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { UserSettingsContxt } from '../userSettingContxt'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const PaymentMethods = () => {
	const { cards, cardHandler } = useContext(UserSettingsContxt)
	const [showForm, setShowForm] = useState(false)

	return (
		<>
			<div className="card mb-5 mb-xl-10">
				<div className="card-header px-lg-10 px-5">
					<div className="card-title">
						<h3>Payment Methods</h3>
					</div>
					<div class="card-toolbar m-0">
						<ul
							class="nav nav-stretch nav-line-tabs border-transparent"
							role="tablist"
						>
							<li class="nav-item" role="presentation">
								<span class="nav-link fs-5 fw-bold me-5 active">
									Credit / Debit Card
								</span>
							</li>
						</ul>
					</div>
				</div>

				<div className="card-body p-lg-10 p-5">
					{/* <div className="card-title" style={{ marginBottom: "20px" }}>
            <h3 className="mb-5">My Cards</h3>
          </div> */}
					<div className="row g-lg-10 g-5">
						{cards &&
							cards?.length > 0 &&
							cards?.map((card) => (
								<PaymentCard
									key={card?.id}
									cardHandler={cardHandler}
									card={card}
									showPrimary={cards[0].id === card?.id}
								/>
							))}
						<CardNote formHandler={() => setShowForm(true)} />
					</div>
				</div>
			</div>

			{showForm ? (
				<Elements stripe={stripePromise}>
					<CardForm
						cardHandler={cardHandler}
						toggleHandler={() => {
							setShowForm(false)
						}}
					/>
				</Elements>
			) : null}
		</>
	)
}

export default PaymentMethods

import CreateWhatsappCampaign from '../components/whatsapp-campaigns/createWhatsappCampaign'
import WhatsappCampaignContextProvider from '../components/whatsapp-campaigns/whatsappCampaignContext'

const WhatsappCampaigns = () => {
	return (
		<WhatsappCampaignContextProvider>
			<CreateWhatsappCampaign />
		</WhatsappCampaignContextProvider>
	)
}

export default WhatsappCampaigns

import { useReducer } from 'react'

/**
 * Use toggle state hook
 *
 * @param {boolean} initialState
 * @returns {[boolean, () => void]} Returns a tuple [state, toggleState]
 */
export const useToggle = (initialState) =>
	useReducer((state) => !state, initialState)

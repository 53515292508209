import React from 'react'
import { Offcanvas } from 'react-bootstrap'

const DrawerWrapper = ({ open, toggle, header, children }) => {
	return (
		<Offcanvas
			className="add_new_link_body right-side-offcanvas"
			size="xl"
			show={open}
			onHide={toggle}
			placement="end"
		>
			{header ? header : null}
			<Offcanvas.Body className="modal-body px-lg-20">
				{children}
			</Offcanvas.Body>
		</Offcanvas>
	)
}

export default DrawerWrapper

import { useParams } from 'react-router-dom'
import PageWrapper from '../../components/wrapper/pageWrapper'
import { useEffect, useState } from 'react'
import LinkInBioApi from '../../services/http/linkInBio'
import LinkInBioForm from '../../components/link-in-bio/linkInBioForm'
import LinkInBioContxtProvider from '../../components/link-in-bio/linkInBioContxt'
import ShortLinkContxtProvider from '../../components/short-links/shortLinkContxt'
const EditLinkInBio = () => {
	const { id } = useParams()

	const [linkInBioPage, setLinkInBioPage] = useState(null)

	useEffect(() => {
		const fetchLinkInBioPage = async () => {
			const auth_token = localStorage.getItem('auth_token')
			LinkInBioApi.getLinkInBioPage(auth_token, id)
				.then(({ data }) => {
					if (data.resultData) {
						setLinkInBioPage(data.resultData)
					}
				})
				.catch((error) => {
					console.log('Error while fetching link in bio page: ', error)
				})
		}

		fetchLinkInBioPage()
	}, [id])

	return (
		<PageWrapper>
			<LinkInBioContxtProvider>
				<ShortLinkContxtProvider>
					<LinkInBioForm screen={2} edittedData={linkInBioPage} />
				</ShortLinkContxtProvider>
			</LinkInBioContxtProvider>
		</PageWrapper>
	)
}

export default EditLinkInBio

import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import TableHeader from './tableHeader'
import Subscriber from './subscriber'
import AdminAPI from '../../services/http/admin'
import emptyScreen from '../../assets/media/empty-state/analytics.svg'
import SubscriptionHeader from './header'

const Subscribers = () => {
	const [loading, setLoading] = useState(false)
	const [subscribers, setSubscribers] = useState([])

	const subscriberSelectorHandler = (id) => {
		setSubscribers((preState) => {
			const updatedSubscribers = preState?.map((item) => {
				const updatedItem =
					item?.id === id
						? {
								...item,
								isSelected: item?.isSelected ? !item?.isSelected : true,
							}
						: item
				return updatedItem
			})
			return updatedSubscribers
		})
	}

	const allSubscribersSelectorHandler = (isSelected) => {
		setSubscribers((preState) => {
			return preState?.map((item) => ({
				...item,
				isSelected: isSelected,
			}))
		})
	}

	const showBulkAction = subscribers?.some((item) => item?.isSelected)
	const isAllSubscribersSelected = subscribers?.every(
		(item) => item?.isSelected
	)

	const fetchSubscribers = async (filters) => {
		try {
			setLoading(true)
			const response = await AdminAPI.getSubscribers(filters)
			if (!response?.data?.error) {
				setSubscribers(response?.data?.data)
			}
			setLoading(false)
		} catch (e) {
			console.log('Error while fetching subscribers: ', e)
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchSubscribers({
			startDate: new Date().setDate(new Date().getDate() - 30),
			endDate: new Date(),
		})
	}, [])

	return (
		<div className="card card-flush rounded-sm-0 card">
			<SubscriptionHeader fetchFilteredSubscribers={fetchSubscribers} />
			<div
				className="card-body px-6 px-lg-10 pt-0 overflow-x-auto  card-body"
				style={{ minHeight: '200px' }}
			>
				<div className="w-100 shortlinktableouterdiv overflow-x-auto">
					<div class="card-body p-0">
						<div class="table-responsive">
							<table class="table = align-middle fs-6 gy-5 border-bottom">
								<TableHeader
									allSelected={isAllSubscribersSelected}
									allSubscribersSelectorHandler={allSubscribersSelectorHandler}
								/>
								<tbody class="fw-semibold text-gray-600">
									{loading ? (
										<Spinner />
									) : subscribers && subscribers?.length > 0 ? (
										subscribers?.map((item) => (
											<Subscriber
												subscriber={item}
												key={item?.id}
												subscriberHandler={setSubscribers}
												subscriberSelectorHandler={subscriberSelectorHandler}
											/>
										))
									) : (
										<div
											style={{ width: '100%' }}
											className="d-flex flex-column justify-content-center"
											id="no-analytics-message"
										>
											<img
												src={emptyScreen}
												className="w-lg-300px w-75 opacity-75 mx-auto mb-10"
												alt=""
											/>
											<h2 className="mx-10 opacity-75 text-center">
												No Subscribers Found !
											</h2>
										</div>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Subscribers

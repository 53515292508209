import httpMethods from '..'

const AuthApi = {
	invitedUserInfo: async (payload) => {
		return httpMethods.post(`/invite`, payload).then((res) => res)
	},
	resetPassword: async (payload) => {
		return httpMethods.post(`/reset-password`, payload).then((res) => res)
	},
	changePassword: async (payload) => {
		return httpMethods.post(`/change-password`, payload).then((res) => res)
	},
	verifyAccount: async (payload) => {
		return httpMethods.post(`/verify-email`, payload).then((res) => res)
	},
	resendVerifyEmail: async (payload) => {
		return httpMethods.post(`/resend-email`, payload).then((res) => res)
	},
	signup: async (payload) => {
		return httpMethods.post(`/sign-up`, payload).then((res) => res)
	},
	signin: async (payload) => {
		return httpMethods.post(`/sign-in`, payload).then((res) => res)
	},
	googleLogin: async (payload) => {
		return httpMethods.post(`/googleLogin`, payload).then((res) => res)
	},
}

export default AuthApi

import PageWrapper from '../../components/wrapper/pageWrapper'
import LinkInBioPages from '../../components/link-in-bio'

const LinkInBioIndex = () => {
	return (
		<PageWrapper>
			<div className="card border-0">
				<div className="card-body p-lg-10 p-6">
					<div className="d-block">
						<LinkInBioPages />
					</div>
				</div>
			</div>
		</PageWrapper>
	)
}

export default LinkInBioIndex

import React from 'react'
import {
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Spinner,
} from 'reactstrap'

const DeleteModal = (props) => {
	const {
		singleDeleteModal,
		toggleSingleDeleteModal,
		handleSingleContactDelete,

		showDeleteModal,
		toggleDeleteModal,
		handleContactsDelete,
		deleteSingleContactLoading,
		deleteManyContactLoading,
	} = props

	return (
		<>
			{/* Delete modal for single contact */}
			<Modal isOpen={singleDeleteModal} toggle={toggleSingleDeleteModal}>
				<ModalHeader toggle={toggleSingleDeleteModal}>
					Confirm Deletion
				</ModalHeader>
				<ModalBody>Are you sure you want to delete this contact?</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						disabled={deleteSingleContactLoading}
						onClick={toggleSingleDeleteModal}
					>
						Cancel
					</Button>
					<Button
						color="danger"
						disabled={deleteSingleContactLoading}
						onClick={handleSingleContactDelete}
					>
						{deleteSingleContactLoading ? (
							<>
								<Spinner size="sm" />
							</>
						) : (
							<>Delete</>
						)}
					</Button>
				</ModalFooter>
			</Modal>

			{/* Delete modal for selected contacts */}
			<Modal isOpen={showDeleteModal} toggle={toggleDeleteModal}>
				<ModalHeader toggle={toggleDeleteModal}>Confirm Deletion</ModalHeader>
				<ModalBody>
					Are you sure you want to delete the selected contact?
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						disabled={deleteManyContactLoading}
						onClick={toggleDeleteModal}
					>
						Cancel
					</Button>
					<Button
						color="danger"
						disabled={deleteManyContactLoading}
						onClick={handleContactsDelete}
					>
						{deleteManyContactLoading ? (
							<>
								<Spinner size="sm" />
							</>
						) : (
							<>Delete</>
						)}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default DeleteModal

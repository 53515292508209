import { Spinner } from 'reactstrap'
import Charts from './charts'
import ClicksAnalytics from './clicksAnalytics'
import LinksAnalytics from './linksAnalytics'
import { useState } from 'react'
import dayjs from 'dayjs'
import DateRange from './dateRange'
import analyticsempty from '../../../assets/media/empty-state/analytics.svg'

const CONTENT = {
	shortlink: {
		heading: 'Link Analytics',
		type: 'Clicks',
	},
	qr: {
		heading: 'Scan Analytics',
		type: 'Scan',
	},
}

const Analytics = ({
	shortlink = '',
	loading = false,
	analytics = {},
	type,
	analyticsHandler = () => {},
}) => {
	const {
		country = [],
		city = [],
		date = [],
		ip = [],
		device = [],
		clientName = [],
	} = analytics || {}
	const [selectedDateRange, setSelectedDateRange] = useState({
		startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
		endDate: new Date(),
		key: 'selection',
	})
	const [openDatePicker, setOpenDatePicker] = useState(false)

	const isEmpty = (arr) => arr.length === 0
	const areAnalyticsFetched = [
		country,
		city,
		date,
		ip,
		device,
		clientName,
	].every(isEmpty)

	const content = CONTENT[type]

	return (
		<>
			<>
				<div className="pt-lg-10 pb-5 pb-lg-10">
					<div className="d-flex justify-content-between align-items-center mb-5 ladamedia">
						<h2>{content.heading}</h2>
						<div className="mt-5 mt-lg-0">
							<div
								onClick={() => setOpenDatePicker(!openDatePicker)}
								class="form-control form-control-solid justify-content-between cursor-pointer"
								style={{
									position: 'relative',
								}}
							>
								<span>
									{dayjs(selectedDateRange.startDate).format('MM/DD/YYYY')}
								</span>
								<span>{` - `}</span>
								<span>
									{dayjs(selectedDateRange.endDate).format('MM/DD/YYYY')}
								</span>
							</div>
							{openDatePicker ? (
								<div
									style={{
										position: 'absolute',
										zIndex: 99999,
										right: 0,
									}}
								>
									<DateRange
										analyticsHandler={analyticsHandler}
										toggleHandler={setOpenDatePicker}
										selectedDateRange={selectedDateRange}
										setSelectedDateRange={setSelectedDateRange}
									/>
								</div>
							) : null}
						</div>
					</div>
					{loading ? (
						<Spinner />
					) : (
						<div>
							{areAnalyticsFetched ? (
								<div
									className="d-flex flex-column justify-content-center border py-10 mt-10 border-dark border-opacity-25 rounded-2"
									id="no-analytics-message"
								>
									<img
										src={analyticsempty}
										className="w-lg-300px w-75 opacity-75 mx-auto mb-10"
										alt=""
									/>
									<h2 className="mx-10 opacity-75 text-center">
										No analytics available !
									</h2>
									<p className="w-lg-75 mx-auto text-center">
										Share short link or QR Code on social media or other
										platforms to get insights
									</p>
								</div>
							) : (
								<>
									<LinksAnalytics
										content={content}
										loading={loading}
										date={date}
										ip={ip}
									/>

									<ClicksAnalytics
										shortlink={shortlink}
										loading={loading}
										country={country}
										city={city}
									/>
									<Charts
										loading={loading}
										device={device}
										clientName={clientName}
									/>
								</>
							)}
						</div>
					)}
				</div>
			</>
		</>
	)
}

export default Analytics

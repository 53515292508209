import React, { useContext, useEffect } from 'react'
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/logos/divsly-icon-c.svg'
import { AuthContxt } from '../../store/authContxt'

import { useState } from 'react'
import { Tooltip } from 'reactstrap'
import AdminSidebar from '../sidebar/adminSidebar'
import UserSidebar from '../sidebar/userSidebar'
import { extractNameFromEmail } from '../../utils/extractName'

const Sidemenu = () => {
	const navigate = useNavigate()
	const { profile, logout } = useContext(AuthContxt)
	const [collapsed, setCollapsed] = React.useState(true)

	const { avatar, firstName = '', lastName = '', email = '' } = profile || {}
	const name =
		`${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim() ||
		extractNameFromEmail(email)

	const getIconClass = (collapsed) => {
		return collapsed ? 'fa-bars' : 'fa-times'
	}

	const logoutHandler = () => {
		logout()
		navigate('/')
		window.location.reload()
	}

	const [logoutTooltipOpen, setlogoutTooltipOpen] = useState(false)
	const togglelogoutTooltip = () => setlogoutTooltipOpen(!logoutTooltipOpen)

	return (
		<>
			<div className="d-flex justify-content-between bg-info align-items-center w-100 position-absolute fixed-top desktop-hide-f">
				<div
					className="d-flex align-items-center  p-4 ps-6"
					style={{ minWidth: '66px' }}
				>
					<div className="symbol symbol-40px border border-white border-2 overflow-hidden me-3">
						<span>
							<div className="symbol-label">
								<img
									src={avatar || '/placeholder.png'}
									alt={name}
									className="w-100"
								/>
							</div>
						</span>
					</div>
					<div className="d-flex flex-column flex-start menu-title ps-2 app-sidebar-d-none">
						<span className="text-white fw-bold fs-4 text-wrap-oneline">
							{name}
						</span>
						<span className="text-white text-wrap-oneline">{email}</span>
					</div>
				</div>
				<div>
					<i
						style={{ transition: 'all 0.3s ease' }}
						className={`fal fa-bars fs-2x text-white collapseiconmob me-6 ${getIconClass(
							collapsed
						)}`}
						onClick={() => setCollapsed(!collapsed)}
					></i>
				</div>
			</div>

			<Sidebar
				collapsed={collapsed}
				width="280px"
				collapsedWidth="90px"
				className="ms-lg-5 z-1111"
			>
				<div className="w-full d-flex align-items-center justify-content-between mb-7">
					<img src={Logo} className="w-30px mobile-hide" alt="LOGO" />

					<span
						className="cursor-pointer  mobile-hide collapser-icon"
						onClick={() => setCollapsed(!collapsed)}
					>
						<i className="fa-light text-black fa-square-chevron-left fs-2x rotate-180"></i>
					</span>
				</div>
				<div className="border-bottom mobile-hide border-gray-250 mt-4 mb-7 profile-seprator"></div>

				<Menu
					menuItemStyles={{
						button: {
							// the active class will be added automatically by react router
							// so we can use it to style the active menu item
							[`&.active`]: {
								color: 'red',
							},
						},
					}}
				>
					<div className="colpse-5m menu-link mobile-hide">
						<div
							className="d-flex align-items-center bg-white border border-gray-250 rounded-1 profile-menu-box p-4"
							style={{ minWidth: '66px' }}
						>
							<div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
								<span>
									<div className="symbol-label">
										<img
											src={avatar || '/placeholder.png'}
											alt={name}
											className="w-100"
										/>
									</div>
								</span>
							</div>
							<div className="d-flex flex-column flex-start menu-title ps-2 app-sidebar-d-none">
								<span className="fw-bold fs-4 text-black text-wrap-oneline text-hover-info cursor-pointer">
									{name}
								</span>
							</div>
						</div>
					</div>
					{profile?.isAdmin ? <AdminSidebar /> : <UserSidebar />}
					<MenuItem onClick={logoutHandler}>
						{' '}
						<i
							id="logouttooltip"
							className="fa-regular fa-power-off text-black fs-1"
						></i>
						<span className="menu-title fs-4 fw-semibold text-black">
							{' '}
							Log out
							<Tooltip
								placement="top"
								isOpen={logoutTooltipOpen}
								autohide={false}
								innerClassName="rounded-1"
								target="logouttooltip"
								toggle={togglelogoutTooltip}
							>
								Logout
							</Tooltip>
						</span>
					</MenuItem>
				</Menu>
			</Sidebar>
		</>
	)
}

export default Sidemenu

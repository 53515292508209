import { useParams } from 'react-router-dom'
import CreateQRCode from '../../components/qr-codes/create-qr-code'
import PageWrapper from '../../components/wrapper/pageWrapper'
import { useEffect, useState } from 'react'
import QrCodesAPI from '../../services/http/qrCodes'
import { SvgIcons } from '../../components/qr-codes/create-qr-code/frames-svg'
import QrGeneration from '../../components/qr-codes/create-qr-code/qrGeneration'
const Index = () => {
	const { id } = useParams()
	const [qrCode, setQrCode] = useState(null)
	const [isEdit, setIsEdit] = useState(false)

	useEffect(() => {
		const fetchQrCode = async () => {
			const auth_token = localStorage.getItem('auth_token')
			QrCodesAPI.getQrCode(auth_token, id)
				.then(({ data }) => {
					if (data.resultData) {
						const newData = {
							...data?.resultData,
							frame: data?.resultData?.frame
								? SvgIcons.find((q) => q.type === data.resultData.frame)
								: data?.resultData?.frame,
						}
						newData['data'] =
							`${data?.resultData?.brandedDomain}/${data?.resultData?.slashTag}?r=qr`
						setQrCode(newData)
						setIsEdit(true)
					}
				})
				.catch((error) => {
					console.log('Error while fetching qr codes: ', error)
				})
		}

		fetchQrCode()
	}, [id])

	return (
		<>
			{
				<QrGeneration
					editData={qrCode}
					isEdit={isEdit}
					qrType={qrCode?.qrType}
				/>
			}
		</>
	)
}

export default Index

import React from 'react'
import { Modal, ModalBody, Button } from 'reactstrap'

const ModalWrapper = ({
	isOpen,
	modalHandler,
	data,
	rightBtnHandler,
	leftBtnHandler,
}) => {
	const { heading, description, leftBtn, rightBtn } = data

	return (
		<Modal
			className="add_new_link_body rounded-3 modal-dialog-centered mw-550px"
			size="lg"
			isOpen={isOpen}
			toggle={modalHandler}
		>
			<ModalBody className="modal-body m-5 p-12">
				<div>
					<div className="d-flex justify-content-center">
						<div className="bg-light-danger rounded-circle w-100px h-100px d-flex justify-content-center align-items-center mb-5">
							<i className="fa-light fa-trash-can text-danger fs-3hx"></i>
						</div>
					</div>
					<div>
						<h2 className="fw-bold px-lg-0 mb-0 text-center mb-3 fs-2hx">
							{heading}
						</h2>
						<p className="px-lg-0 text-center mb-5 text-gray-800 fs-5">
							{description}
						</p>
					</div>
					<div className="d-flex justify-content-center mt-12">
						<Button
							color="danger"
							className="me-5 w-50 rounded-1"
							style={{ cursor: 'pointer' }}
							onClick={leftBtnHandler}
						>
							{leftBtn}
						</Button>
						<Button
							color="light"
							className="border w-50 rounded-1"
							onClick={rightBtnHandler}
						>
							{rightBtn}
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default ModalWrapper

import { createContext, useContext, useEffect, useState } from 'react'
import SmsCampaignAPI from '../../services/http/smsCampaign'
import { AuthContxt } from '../../store/authContxt'

export const SmsCampaignContxt = createContext(null)

const SmsCampaignContxtProvider = (props) => {
	const { fetchQuota } = useContext(AuthContxt)
	const [loading, setLoading] = useState(true)
	const [smsCampaigns, setSmsCampaigns] = useState([])
	const [notLinksScreen, setNotLinksScreen] = useState('')
	const [search, setSearch] = useState()
	const [status, setStatus] = useState('All Statuses')
	const [filters, setFilters] = useState({})
	const [currentCampaign, setCurrentCampaign] = useState({})

	const getSmsCampaigns = async (params) => {
		const auth_token = localStorage.getItem('auth_token')
		setLoading(true)
		SmsCampaignAPI.getAllSmsCampaigns(auth_token, params)
			.then(({ data }) => {
				setSmsCampaigns(data.resultData)
				data.resultData.length < 1 && setNotLinksScreen('notFound')
			})
			.catch((error) => {
				console.log('Error while fetching shortlinks', error)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		getSmsCampaigns()
	}, [])

	return (
		<SmsCampaignContxt.Provider
			value={{
				smsCampaigns,
				setSmsCampaigns,
				getSmsCampaigns,
				loading,
				setLoading,
				notLinksScreen,
				setNotLinksScreen,
				search,
				setSearch,
				filters,
				setFilters,
				status,
				setStatus,
				currentCampaign,
				setCurrentCampaign,
			}}
		>
			{props.children}
		</SmsCampaignContxt.Provider>
	)
}

export default SmsCampaignContxtProvider

import { useCallback, useContext, useEffect, useState } from 'react'
import AvatarCropper from './avatarCropper'
import { LinkInBioContxt } from '../linkInBioContxt'
import generateProfileImage from '../../../utils/generateProfileImage'

const ProfileInfo = () => {
	const { bioPageForm } = useContext(LinkInBioContxt)
	const { setValue, watch } = bioPageForm || {}
	const { avatar, bio, name, pageLink, generatedAvatar } = watch()
	const [errorMsg, setErrorMsg] = useState({})
	const [cropperModal, setCropperModal] = useState(false)
	const [uploadedImage, setUploadedImage] = useState()
	const [file, setFile] = useState()

	const resetErrorMsg = (type) => {
		setErrorMsg((preState) => ({
			...preState,
			[type]: null,
		}))
	}

	const handleFileChange = () => {
		if (file) {
			const reader = new FileReader()
			reader.onload = () => {
				let img = new Image()
				img.onload = () => {
					const base64String = reader.result
					setValue('avatar', base64String)
					setValue('generatedAvatar', '')
				}
				img.src = reader.result
			}
			reader.readAsDataURL(file)
		}
	}

	const createImage = (url) =>
		new Promise((resolve, reject) => {
			const image = new Image()
			image.addEventListener('load', () => resolve(image))
			image.addEventListener('error', (error) => reject(error))
			image.setAttribute('crossOrigin', 'anonymous')
			image.src = url
		})

	const getCroppedImg = async (imageSrc, pixelCrop) => {
		const image = await createImage(imageSrc)
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		if (!ctx) {
			return null
		}
		canvas.width = image.width
		canvas.height = image.height

		ctx.drawImage(image, 0, 0)
		const data = ctx.getImageData(
			pixelCrop.x,
			pixelCrop.y,
			pixelCrop.width,
			pixelCrop.height
		)

		canvas.width = pixelCrop.width
		canvas.height = pixelCrop.height

		ctx.putImageData(data, 0, 0)

		return new Promise((resolve, reject) => {
			canvas.toBlob((file) => {
				const reader = new FileReader()
				reader.onload = function () {
					const base64data = reader.result
					resolve(base64data)
				}
				reader.readAsDataURL(file)
			}, 'image/png')
		})
	}

	const showCroppedImage = useCallback(async (image, croppedAreaPixels) => {
		try {
			const croppedImage = await getCroppedImg(image, croppedAreaPixels)
			setValue('avatar', croppedImage)
			setValue('generatedAvatar', '')
		} catch (e) {
			console.error(e)
		}
	}, [])

	const cropModalHandler = (event) => {
		const selectedFile = event.target.files[0]
		const maxAllowedSize = 2 * 1024 * 1024
		if (event.target.files[0].size > maxAllowedSize) {
			event.target.value = ''
			setErrorMsg((preState) => ({
				...preState,
				image: 'Max allowed side is 2 MB',
			}))
			setTimeout(() => resetErrorMsg('image'), 4 * 1000)
			return
		}

		resetErrorMsg('image')
		if (selectedFile) {
			setFile(selectedFile)
			const reader = new FileReader()
			reader.onload = () => {
				const base64String = reader.result
				setUploadedImage(base64String)
				setCropperModal(true)
			}
			reader.readAsDataURL(selectedFile)
		}
		event.target.value = ''
	}

	const resetAvatarHandler = () => {
		setValue(
			'avatar',
			generateProfileImage(name?.split(' ')?.[0] || name || pageLink)
		)
		setValue('generatedAvatar', 'yes')
	}
	return (
		<>
			<div>
				<h3 className="fw-bolder fs-2 mb-3">Profile</h3>
				<div className="border-lg d-lg-flex p-lg-5 rounded-1">
					<div className="mt-lg-3 pe-lg-8 my-lg-0 my-6 pe-6 border-lg-end">
						<div className="image-input image-input-outline image-input-circle">
							<img
								className="image-input-wrapper w-125px h-125px"
								alt="Avatar"
								src={avatar || '/placeholder.png'}
							></img>
							<label
								className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
								data-kt-image-input-action="change"
								data-bs-toggle="tooltip"
								data-bs-dismiss="click"
								title="Change avatar"
							>
								<span>
									<i className="fa fa-pencil opacity-50"></i>
								</span>
								<input
									onChange={cropModalHandler}
									type="file"
									name="avatar"
									accept=".png, .jpg, .jpeg"
								/>
							</label>
							{generatedAvatar !== 'yes' && (
								<span
									onClick={resetAvatarHandler}
									className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
									data-kt-image-input-action="remove"
									data-bs-toggle="tooltip"
									data-bs-dismiss="click"
									title={'Remove avatar'}
								>
									{avatar ? (
										<i className="fa fa-x opacity-50"></i>
									) : (
										<i className="fa fa-user opacity-50"></i>
									)}
								</span>
							)}
						</div>
					</div>
					{errorMsg?.image !== null ? (
						<span className="error-text text-danger">{errorMsg?.image}</span>
					) : null}
					<div className="w-100 ps-lg-8">
						<div className="w-100">
							<div className="form-floating mt-3">
								<input
									type="text"
									className="form-control border border-info border-opacity-10 rounded-1 fs-4"
									placeholder="Profile Name"
									onChange={(e) => {
										const value = e.target.value
										value?.length < 31 && setValue('name', value)
									}}
									value={name}
								/>
								<label htmlFor="floatingInput1">Profile Name</label>
							</div>
						</div>

						<div className="form-floating mt-7">
							<textarea
								id="textInput"
								onChange={(e) => {
									const value = e.target.value
									value?.length <= 80 && setValue('bio', value)
								}}
								className="form-control border border-info border-opacity-10 rounded-1 h-70px"
								maxLength="80"
								name="bio"
								value={bio}
								placeholder="Bio"
							>
								{bio}
							</textarea>
							<label htmlFor="textInput">Bio</label>
						</div>
						<div id="textCharCount" className="charCount mt-3 d-flex flex-end">
							{`${bio?.length} / 80`}
						</div>
					</div>
				</div>
			</div>

			{cropperModal ? (
				<AvatarCropper
					image={uploadedImage || '/logo-with-white-bg.png'}
					toggleHandler={() => {
						setCropperModal(false)
						handleFileChange()
					}}
					cancelHandler={() => {
						setCropperModal(false)
					}}
					cropHandler={async (area = null, pixels = null) => {
						pixels
							? await showCroppedImage(uploadedImage, pixels)
							: handleFileChange()
						setCropperModal(false)
					}}
				/>
			) : null}
		</>
	)
}

export default ProfileInfo

import { Input, FormGroup } from 'reactstrap'
import { Controller } from 'react-hook-form'

const DomainName = ({ form }) => {
	const { control } = form
	return (
		<div className="w-100 py-lg-10 py-6">
			<div className="w-100">
				<div className="pb-6 pb-lg-15">
					<h2 className="fw-bold d-flex align-items-center text-dark">
						{' '}
						Connect a domain name you already own{' '}
					</h2>

					<div className="text-muted fw-semibold fs-6 ps-0">
						{' '}
						Configure a domain name you already own to use as a branded domain
						for your links. Please note that this is a technical procedure and
						DNS experience is recommended.{' '}
					</div>
				</div>
				<FormGroup>
					<label
						htmlFor="domain"
						className="form-label d-flex align-items-center ps-0"
					>
						<span className="required fs-5 fw-bold">
							Enter Your Domain Name
						</span>
					</label>
					<Controller
						name="domain"
						control={control}
						render={({ field, fieldState }) => (
							<>
								<Input
									{...field}
									type="text"
									className="form-control h-50px rounded-1"
									placeholder="yourbrand.com"
									onChange={(e) => {
										field.onChange(e)
									}}
								/>
								{fieldState.error && (
									<span className="error-text text-danger">
										{fieldState.error.message}
									</span>
								)}
							</>
						)}
					/>
				</FormGroup>
			</div>
		</div>
	)
}

export default DomainName

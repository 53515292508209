import React, { useState } from 'react'
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap'

const MergeTagsDropdown = ({ mergeTags, onSelect, loading }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)

	const toggle = () => setDropdownOpen((prevState) => !prevState)

	const handleSelect = (tag) => {
		onSelect(tag)
		toggle()
	}

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle caret disabled={loading}>
				Select Merge Tag
			</DropdownToggle>
			<DropdownMenu>
				{Object.keys(mergeTags).map((key) => (
					<DropdownItem key={key} onClick={() => handleSelect(mergeTags[key])}>
						{key}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	)
}

export default MergeTagsDropdown

import React, { useState } from 'react'
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	Input,
	FormGroup,
	Label,
	Form,
} from 'reactstrap'
import designicon from '../../../../assets/qr-icons/design.png'
import infoicon from '../../../../assets/qr-icons/info-contact.png'
import socialicon from '../../../../assets/qr-icons/social-media.png'
import linkicon from '../../../../assets/qr-icons/link.png'
import uploadicon from '../../../../assets/qr-icons/upload.png'
import audioPreview from '../../../../assets/qr-preview/qr-audio-preview.png'
import QrLink from '../QrLink'
import FileUpload from './components/fileUpload'

const AudioForm = ({ formData, setFormData, isEdit, qrType }) => {
	const [open, setOpen] = useState('0')

	const toggle = (id) => {
		open === id ? setOpen('') : setOpen(id)
	}

	// Handle input changes
	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	const accordionItems = [
		{
			id: '0',
			title: 'Short Link',
			icon: linkicon,
			content: (
				<QrLink
					formData={formData}
					setFormData={setFormData}
					isEdit={isEdit}
					qrType={qrType}
				/>
			),
		},
		{
			id: '1',
			title: 'Design Customization',
			icon: designicon,
			content: (
				<div className="d-flex gap-10 align-items-center">
					{/* Primary Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Primary Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.audioPrimary}
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										audioPrimary: e.target.value,
									}))
								}
							/>
							<Input
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										audioPrimary: e.target.value,
									}))
								}
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.audioPrimary}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
					{/* Button Color Picker */}
					<FormGroup>
						<Label className="fs-4 fw-semibold mb-4">Button Color</Label>
						<div className="color-picker-wrapper">
							<Input
								type="color"
								className="color-picker rounded-0 cursor-pointer"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
							/>
							<Input
								className="hex-input form-control rounded-0"
								type="text"
								maxLength="7"
								value={formData?.ctaColor}
								onChange={(e) =>
									setFormData((prev) => ({ ...prev, ctaColor: e.target.value }))
								}
								placeholder="#000000"
							/>
						</div>
					</FormGroup>
				</div>
			),
		},
		{
			id: '3',
			icon: uploadicon,
			title: 'Upload Audio',
			content: (
				<>
					<FileUpload
						setFormData={setFormData}
						formData={formData}
						qrType={qrType}
					/>

					<div className="mt-10">
						<div>
							<p className="fs-4 fw-semibold">Banner Image:</p>
						</div>
						<div className="mt-3 pe-8">
							<div
								className="image-input image-input-outline"
								style={{
									backgroundImage: `url(${formData?.image || audioPreview})`,
								}}
							>
								<div
									className="image-input-wrapper w-125px h-125px shadow-none border border-dark border-opacity-10"
									style={{
										backgroundImage: `url(${formData?.banner || audioPreview})`,
									}}
								></div>
								<Label
									className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body position-absolute border border-dark border-opacity-10"
									style={{ right: '3px', top: '3px' }}
								>
									<i className="fas fa-pencil fs-6"></i>
									<Input
										className="d-none"
										type="file"
										name="banner"
										accept=".png, .jpg, .jpeg"
										onChange={(e) =>
											setFormData({
												...formData,
												banner: URL.createObjectURL(e.target.files[0]),
												profilefile: e.target.files[0],
											})
										}
									/>
								</Label>
							</div>
						</div>
					</div>
				</>
			),
		},
		{
			id: '4',
			icon: infoicon,
			title: 'Basic Information',
			content: <></>,

			fields: [
				{
					id: 'audioTitle',
					label: 'Audio Title',
					type: 'text',
					colSize: 12,
					className: 'h-50px',
				},
				{
					id: 'description',
					label: 'Description',
					type: 'textarea',
					colSize: 12,
					className: 'h-100px',
				},
				{
					id: 'website',
					label: 'Website',
					type: 'text',
					colSize: 12,
					className: 'h-50px',
				},
				{
					id: 'buttonText',
					label: 'Button Text',
					value: 'Buy Now',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'buttonLink',
					label: 'Button Link',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
			],
		},
		{
			id: '5',
			icon: socialicon,
			title: 'Social Media',
			fields: [
				{
					id: 'facebook',
					label: 'Facebook URL',
					placeholder: 'https://facebook.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'instagram',
					label: 'Instagram URL',
					placeholder: 'https://instagram.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'google',
					label: 'Google URL',
					placeholder: 'https://google.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
				{
					id: 'linkedin',
					label: 'LinkedIn URL',
					placeholder: 'https://linkedin.com/',
					type: 'text',
					colSize: 6,
					className: 'h-50px',
				},
			],
		},
	]

	return (
		<>
			<Accordion className="qr-accodian-form mt-8" open={open} toggle={toggle}>
				{accordionItems.map((item) => (
					<AccordionItem key={item.id}>
						<AccordionHeader targetId={item.id}>
							{' '}
							<img
								src={item.icon}
								alt={item.title}
								className="w-30px me-4"
							/>{' '}
							{item.title}
						</AccordionHeader>
						<AccordionBody accordionId={item.id}>
							{item.content}
							{item.fields && (
								<Form>
									<div className="row">
										{item.fields.map((field) => (
											<div
												key={field.id}
												className={`col-md-${field.colSize || 12} mb-3`} // 6 columns if colSize is 6, otherwise full width
											>
												<FormGroup>
													<Label className="fs-5 fw-bold" for={field.id}>
														{field.label}
													</Label>
													<Input
														type={field.type}
														className={field.className}
														maxLength={field.maxLength}
														id={field.id}
														placeholder={field.placeholder}
														value={
															(field.type != 'file' && formData[field?.id]) ||
															''
														}
														onChange={handleInputChange}
													/>
												</FormGroup>
											</div>
										))}
									</div>
								</Form>
							)}
						</AccordionBody>
					</AccordionItem>
				))}
			</Accordion>
		</>
	)
}

export default AudioForm

import React, { useState } from 'react'

import {
	Form,
	Label,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Spinner,
} from 'reactstrap'

import TagsInput from 'react-tagsinput' // For tags input
import 'react-tagsinput/react-tagsinput.css' // For tags input styling
import ContactApi from '../../services/http/contact'

const TagModal = (props) => {
	const {
		showTagModal,
		toggleTagModal,

		tags,
		handleTagsChange,
		handleBulkUpdate,
		updateTagModalLoading,
	} = props

	return (
		<>
			{/* Tag Modal */}
			<Modal isOpen={showTagModal} toggle={toggleTagModal}>
				<ModalHeader toggle={toggleTagModal}>Add or Select Tags</ModalHeader>
				<ModalBody>
					<Form>
						<Label className="fs-5 fw-bold mb-2">Tags</Label>
						<TagsInput
							value={tags}
							onChange={handleTagsChange}
							inputProps={{ placeholder: 'Type and press enter' }}
							onlyUnique
							disabled={updateTagModalLoading}
							addOnBlur // Optional: Adds the tag when the input loses focus
						/>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						disabled={updateTagModalLoading}
						onClick={toggleTagModal}
					>
						Close
					</Button>
					<Button
						color="primary"
						disabled={updateTagModalLoading}
						onClick={() => {
							handleBulkUpdate()
						}}
					>
						{updateTagModalLoading ? (
							<>
								<Spinner size="sm" />
							</>
						) : (
							<>Confirm</>
						)}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default TagModal

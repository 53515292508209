import { Spinner } from 'reactstrap'
import PieChart from './pieChart'

const Charts = ({ loading = false, device = [], clientName = [] }) => {
	const clients = clientName?.map((item) => {
		return {
			name: item?.clientName === '' ? 'Others' : `${item?.clientName}`,
			clicks: item?._count?._all,
		}
	})
	const namesMapper = {
		smartphone: 'Mobile',
	}

	const capitalizeFirstLetter = (item = '') => {
		return item?.charAt(0)?.toUpperCase() + item?.slice(1) || ''
	}

	const devices = device.map((item) => {
		return {
			name:
				item?.device === ''
					? 'Others'
					: `${
							namesMapper[item?.device] || capitalizeFirstLetter(item?.device)
						}`,
			clicks: item?._count?._all,
		}
	})

	return (
		<>
			<div className="row pt-10 pb-10">
				<div className="col-12">
					<div className="mb-5 row">
						<div className="col-lg-6 col-12">
							<h2 className="mb-0">Browser Chart</h2>
						</div>
						<div className="col-lg-6 col-12 mobile-hide">
							<h2 className="mb-0">Device Chart</h2>
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-12">
					<div className="card card-bordered w-100 border-gray-300 rounded-1 col-lg-6">
						<div className="card-body p-1">
							{loading ? (
								<Spinner />
							) : (
								<PieChart
									id={'clients'}
									data={clients}
									fields={{ value: 'clicks', label: 'name' }}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-12 mt-10 mb-5 desktop-hide">
					<h2 className="mb-0">Device Chart</h2>
				</div>
				<div className="col-lg-6 col-12">
					<div className="card card-bordered w-100 border-gray-300 rounded-1 col-lg-6">
						<div className="card-body p-1">
							{loading ? (
								<Spinner />
							) : (
								<PieChart
									id={'devices'}
									data={devices}
									fields={{ value: 'clicks', label: 'name' }}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Charts

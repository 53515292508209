import React, { useState, useEffect, useMemo } from 'react'
import { Card, Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap'
import Sidebar from '../../components/common/sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import WhatsappCampaignAPI from '../../services/http/whatsappCampaign'
import Sender from './Sender'
import To from './To'
import { Link } from 'react-router-dom'
import { useToggle } from '../../utils/state-utils'
import WhatsappMessageModal from './whatsapp-message-modal'

const WhatsappCampaignSettings = () => {
	const navigate = useNavigate()

	const { id: whatsappCampaignId } = useParams()

	const [isToModalOpen, toggleToModal] = useToggle()
	const [isFromModalOpen, toggleFromModal] = useToggle()
	const [isMessageModalOpen, toggleMessageModal] = useToggle()
	const [whatsappTemplateId, setWhatsappTemplateId] = useState()

	const [modal, setModal] = useState(false)
	const toggle = () => {
		setModal(!modal)
	}
	const [toAddress, setToAddress] = useState([])
	// const [selectedFromNumber, setSelectedFromNumber] = useState('')
	const [selectedProviderId, setSelectedProviderId] = useState()

	const [title, setTitle] = useState('')
	const [isEditingTitle, setIsEditingTitle] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')

	const [initialToAddress, setInitialToAddress] = useState([])

	const areFieldsInValid = useMemo(
		() =>
			whatsappTemplateId === undefined ||
			!toAddress ||
			selectedProviderId === undefined ||
			toAddress.length === 0,
		[toAddress, whatsappTemplateId, selectedProviderId]
	)

	const getWhatsappCampaign = async () => {
		try {
			const auth_token = localStorage.getItem('auth_token')
			WhatsappCampaignAPI.getWhatsappCampaignsById(
				auth_token,
				whatsappCampaignId
			)
				.then(({ data }) => {
					if (data.resultData.name) {
						setTitle(data.resultData.name)
					}
					if (data.resultData.whatsappProviderId !== undefined) {
						setSelectedProviderId(data.resultData.whatsappProviderId)
					}

					if (
						data.resultData.recipients &&
						data.resultData.recipients.length > 0
					) {
						setToAddress(JSON.parse(data.resultData.recipients))
						setInitialToAddress(JSON.parse(data.resultData.recipients))
					}
					if (data.resultData.whatsappTemplateId !== undefined) {
						setWhatsappTemplateId(data.resultData.whatsappTemplateId)
					}
				})
				.catch((error) => {
					console.error('Error while fetching Whatsapp campaign', error)
				})
		} catch (e) {
			console.error('Error while loading Whatsapp campaign : ', e.message)
		}
	}

	const handleScheduleWhatsappCampaign = async () => {
		try {
			const values = { status: 'SCHEDULED' }
			if (areFieldsInValid) {
				alert('Message, Recipients and From Number should be filled')
				setLoading(false)
				return
			}
			WhatsappCampaignAPI.updateWhatsappCampaign(whatsappCampaignId, values)
				.then(() => {
					navigate('/whatsappcampaigns')
				})
				.catch((error) => {
					console.error('Error while updating Whatsapp campaign', error)
					setError(
						'Failed to schedule the Whatsapp campaign. Please try again.'
					)
				})
				.finally(() => {
					setLoading(false)
				})
		} catch (e) {
			console.error('Error while updating Whatsapp campaign : ', e.message)
			setError('Failed to schedule the Whatsapp campaign. Please try again.')
			setLoading(false)
		}
	}

	const handleSave = async (nextProvider) => {
		try {
			const nextProviderId = nextProvider.id

			if (nextProviderId) {
				if (selectedProviderId === nextProviderId) {
					toggleFromModal()
					return
				}
				const data = {
					fromNumber: nextProvider.fromNumber,
					whatsappProviderId: nextProviderId,
					whatsappProviderName: nextProvider.name,
				}
				await WhatsappCampaignAPI.updateWhatsappCampaign(
					whatsappCampaignId,
					data
				)

				setSelectedProviderId(nextProviderId)
				toggleFromModal()
			}
		} catch (error) {
			console.error(
				'Error occured while saving from number in whatsapp integration',
				error
			)
		}
	}

	const handleTo = (newToAddress) => {
		if (newToAddress && newToAddress.length > 0) {
			if (JSON.stringify(newToAddress) === JSON.stringify(initialToAddress)) {
				toggleToModal()
				return
			}
			setToAddress(newToAddress)
			WhatsappCampaignAPI.updateWhatsappCampaign(whatsappCampaignId, {
				recipients: JSON.stringify(newToAddress),
			})
				.then(({ data }) => {
					console.error('Whatsapp campaign updated successfully', data)
					setInitialToAddress(newToAddress)
					toggleToModal()
				})
				.catch((error) => {
					console.error('Error while updating Whatsapp campaign', error)
				})
		} else {
			console.error('Please select recipients')
		}
	}

	const handleUpdateTemplateId = async (nextTemplateId) => {
		try {
			await WhatsappCampaignAPI.updateWhatsappCampaign(whatsappCampaignId, {
				whatsappTemplateId: +nextTemplateId,
			})
			toggleMessageModal()
		} catch (error) {
			console.error('Failed to update template Id', error)
		}
	}

	const handleTitleEdit = () => {
		setIsEditingTitle(true)
	}

	const handleTitleSave = () => {
		setIsEditingTitle(false)
		WhatsappCampaignAPI.updateWhatsappCampaign(whatsappCampaignId, {
			name: title,
		})
	}

	useEffect(() => {
		getWhatsappCampaign()
	}, [])

	const handleScheduleClick = async (e) => {
		e.preventDefault()
		setLoading(true)
		try {
			await handleScheduleWhatsappCampaign()
		} catch (err) {
			setError('Failed to schedule the Whatsapp campaign. Please try again.')
			setLoading(false)
		}
	}

	return (
		<div>
			<div className="w-full d-flex">
				<div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
					<Sidebar />
				</div>
				<div className="customDashboardSize">
					<div className="d-flex flex-column w-100 flex-root app-root">
						<div className="app-page flex-column flex-column-fluid">
							<div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
								<div className="app-main flex-column flex-row-fluid">
									<Card className="card card-flush">
										<div
											className="card card-flush position-absolute scroll-y"
											data-kt-scroll-max-height="auto"
											data-kt-scroll-offset="100px"
											style={{
												right: '0px',
												bottom: '0px',
												height: '100%',
												left: '0',
												marginLeft: '27px',
											}}
										>
											<div className="ps-10 pt-10 d-flex justify-content-between w-100">
												<h2 className="me-15 fs-2x mb-0">
													<Link to="/whatsappcampaigns">
														<a>
															<i className="fal fa-circle-arrow-left fs-1 me-3 text-gray-600 text-hover-dark"></i>
														</a>
													</Link>
													{isEditingTitle ? (
														<input
															type="text"
															value={title}
															onChange={(e) => setTitle(e.target.value)}
															className="form-control d-inline-block w-auto"
														/>
													) : (
														title
													)}
													{isEditingTitle ? (
														<button
															onClick={handleTitleSave}
															className="btn btn-icon"
														>
															<i className="fas fa-check"></i>
														</button>
													) : (
														<button
															onClick={handleTitleEdit}
															className="btn btn-icon"
														>
															<i className="fas fa-pencil-alt"></i>
														</button>
													)}
												</h2>

												<div className="d-flex mx-3 gap-3">
													{error && (
														<div className="text-danger mt-2">{error}</div>
													)}
													<button
														type="button"
														className="btn text-nowrap rounded-1 btn-info text-white"
														color="danger"
														onClick={toggle}
													>
														Preview & Test
													</button>

													<button
														type="button"
														className="btn text-nowrap rounded-1 btn-info text-white"
														style={{ width: '150px' }}
														onClick={handleScheduleClick}
														disabled={areFieldsInValid}
													>
														{loading ? (
															<Spinner size="sm" color="light" />
														) : (
															'Schedule'
														)}
													</button>
												</div>
											</div>

											<div className="separator border-1 my-5"></div>

											<div className="card-body d-flex pt-0 w-100">
												<div className="row w-100">
													<div className="col-6 h-100">
														<div className="border h-fit-content rounded-1 w-100 mt-5">
															<div>
																<div className="d-flex justify-content-between align-items-center py-10 px-20 border-bottom">
																	<div>
																		<h3 className="fs-4 fw-bold mb-2">From</h3>
																		<p className="text-gray-600 fs-5">
																			Choose which Whatsapp Business account you
																			will show.
																		</p>
																	</div>
																	<div>
																		<button
																			onClick={toggleFromModal}
																			className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
																		>
																			<i className="far fa-pen-to-square"></i>
																		</button>
																	</div>
																</div>
																<div className="d-flex justify-content-between py-10 px-20 border-bottom">
																	<div>
																		<h3 className="fs-4 fw-bold mb-2">To</h3>
																		<p className="text-gray-600 fs-5">
																			Select a list of recipients
																		</p>
																	</div>
																	<div>
																		<button
																			onClick={toggleToModal}
																			className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
																		>
																			<i className="far fa-pen-to-square"></i>
																		</button>
																	</div>
																</div>

																<div className="px-20 border-bottom">
																	<div className="d-flex justify-content-between py-10">
																		<div>
																			<h3 className="fs-4 fw-bold mb-2">
																				Message content
																			</h3>
																			<p className="text-gray-600 fs-5">
																				Select an existing message or start a
																				new design.
																			</p>
																		</div>
																		<div>
																			<button
																				onClick={toggleMessageModal}
																				className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
																			>
																				<i
																					className="fa fa-plus "
																					style={{ fontSize: '1.2em' }}
																				></i>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-6 pt-5 pe-0 position-relative">
														<div
															className="rounded-1 h-100 pt-15 position-sticky"
															style={{
																border: '1px solid #e9e6f1',
																top: '50px',
																backgroundColor: '#F7F5FD',
															}}
														>
															<div
																className="card border-0 card-flush d-flex justify-content-center bg-transparent position-sticky p-3 pt-0 mb-0"
																style={{ top: '60px' }}
															>
																<img
																	id="mobile-content"
																	src="../app/assets/media/whatsapp-campaign/chat.png"
																	width="300"
																	height="600"
																	className="mx-auto mb-8"
																	style={{
																		border: '10px solid black',
																		borderRadius: '30px',
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* From Modal */}
			{/* <Modal isOpen={isFromModalOpen} toggle={toggleFromModal}>
        <ModalHeader toggle={toggleFromModal}>From</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mt-6">
              <label className="fs-5 fw-bold mb-2">
                <span>Select Sender Number</span>
              </label>
              <select
                className="form-select"
                data-control="select2"
                data-hide-search="true"
                data-placeholder="Select Number"
              >
                <option></option>
                <option value="1">+91 123456789</option>
                <option value="2">+91 987654321</option>
              </select>
              <div>
                <div className="notice d-flex bg-light-info rounded-1 border-info border border-dashed align-items-center p-6 mt-10 mb-10">
                  <i className="ki-duotone ki-information fs-2tx text-info me-4">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                  </i>

                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-semibold">
                      <p className="mb-0">
                        You need to{" "}
                        <a
                          className="text-dark"
                          href="whatsapp-campaign-settings.html"
                          style={{ textDecoration: "underline" }}
                        >
                          login to{" "}
                          <span className="fw-bolder text-black">Facebook</span>
                        </a>{" "}
                        and link your Whatsapp Business account to Divsly in
                        order to schedule a campaign.
                      </p>
                      <a
                        className="d-block bgi-no-repeat mt-1 fw-normal text-info bgi-size-cover bgi-position-center rounded position-relative"
                        style={{ textDecoration: "underline" }}
                        data-fslightbox="lightbox-youtube"
                        href="https://www.youtube.com/watch?v=o-bLOVV5__E"
                      >
                        Watch How to connect Facebook
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex gap-5 mt-6 justify-content-end">
                <button className="btn btn-light border border-2 rounded-1 border">
                  Cancel
                </button>
                <button className="btn btn-info rounded-1 s">Save</button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> */}

			<Modal isOpen={isFromModalOpen} toggle={toggleFromModal}>
				<ModalHeader toggle={toggleFromModal}>From</ModalHeader>
				<ModalBody>
					<Sender
						providerId={selectedProviderId}
						onSave={handleSave}
						onCancel={toggleFromModal}
					/>
				</ModalBody>
			</Modal>

			<Modal isOpen={isToModalOpen} toggle={toggleToModal}>
				<ModalHeader toggle={toggleToModal}>Select Recipients</ModalHeader>
				<ModalBody>
					<To
						handleTo={handleTo}
						setToAddress={setToAddress}
						toAddress={toAddress}
					></To>
				</ModalBody>
			</Modal>

			<WhatsappMessageModal
				isOpen={isMessageModalOpen}
				templateId={whatsappTemplateId}
				toggle={toggleMessageModal}
				onSave={handleUpdateTemplateId}
			/>
		</div>
	)
}

export default WhatsappCampaignSettings

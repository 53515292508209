import { useContext, useEffect, useState } from 'react'
import PageWrapper from '../wrapper/pageWrapper'
import CustomLinks from './customLinks'
import CustomLinkForm from './modals'
import CustomLinksAPI from '../../services/http/customLinks'
import FinelStep from './modals/finalStep'
import { AuthContxt } from '../../store/authContxt'

const Index = () => {
	const [modal, setModal] = useState(false)
	const auth_token = localStorage.getItem('auth_token')
	const [customLinks, setCustomLinks] = useState([])
	const [loading, setLoading] = useState(false)
	const [finalStep, setFinalStep] = useState({ data: null, isOpen: false })
	const { fetchQuota } = useContext(AuthContxt)

	const modalHandler = () => {
		setModal(!modal)
	}

	const getCustomLinks = async () => {
		setLoading(true)
		CustomLinksAPI.getCustomLinks(auth_token)
			.then(({ data }) => {
				setCustomLinks(data.data)
			})
			.catch((error) => {
				console.log('Error while fetching custom links: ', error)
			})
			.finally(() => {
				setLoading(false)
			})
		fetchQuota()
	}

	useEffect(() => {
		getCustomLinks()
	}, [])

	return (
		<>
			<PageWrapper>
				{!finalStep?.isOpen ? (
					<CustomLinks
						customLinksHandler={setCustomLinks}
						fetchCustomLinks={getCustomLinks}
						customLinks={customLinks}
						loading={loading}
						modalHandler={modalHandler}
					/>
				) : (
					<FinelStep
						finalStep={finalStep}
						customLinkHandler={() =>
							setFinalStep({ data: null, isOpen: false })
						}
					/>
				)}
			</PageWrapper>
			<CustomLinkForm
				isOpen={modal}
				modalHandler={modalHandler}
				customLinksHandler={getCustomLinks}
				finalStepHandler={setFinalStep}
			/>
		</>
	)
}
export default Index

import LinkDetails from './link-details'
import CreatePageIntro from './create-page-intro'
import { useContext, useEffect } from 'react'
import { LinkInBioContxt } from './linkInBioContxt'

const LinkInBioForm = ({ screen, edittedData = null }) => {
	const { screenHandler, currentScreen } = useContext(LinkInBioContxt)

	useEffect(() => {
		if (screen) {
			screenHandler(screen, edittedData)
		}
	}, [screen, edittedData])

	const screensMapper = {
		1: <CreatePageIntro />,
		2: <LinkDetails />,
	}

	return <div>{screensMapper[currentScreen]}</div>
}

export default LinkInBioForm

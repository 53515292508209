import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row, TabContent } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createQrCodeValidation } from '../../../utils/validations/creatQrCodeValidation'
import CreateQr from './createQr'
import CustomizationQr from './customizationQr'
import Tabs from './tabs'
import WebsiteQr from './websiteQrcode'
import CreateQrHeader from './header'
import QrCodesAPI from '../../../services/http/qrCodes'
import { fetchTitleAndFavicon } from '../../../utils'
import { useNavigate } from 'react-router-dom'
//import { AuthContxt } from "../../../store/authContxt";
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import Sidebar from '../../common/sidebar'
import QrCodesList from '../qr-code-list'

const QrCodeList = ({ edittedData = null, isEdit = false }) => {
	const auth_token = localStorage.getItem('auth_token')
	//const { fetchQuota } = useContext(AuthContxt);
	const navigate = useNavigate()
	const [tab, setTab] = useState(1)
	const [loading, setLoading] = useState(false)
	const [qrLoading, setQrLoading] = useState(false)
	const [selectedPreview, setSelectedPreview] = useState('A') // Default to 'A' (WebPreview)

	const handlePreviewChange = (previewComponent) => {
		setSelectedPreview(previewComponent) // Update preview based on card selection
	}

	const [message, setMessage] = useState('')
	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const tabsHandler = (newTab) => {
		if (newTab !== tab) setTab(newTab)
	}
	const BASE_URL = process.env.REACT_APP_QR_BASE_URL
	const form = useForm({
		resolver: yupResolver(createQrCodeValidation),
		defaultValues: {
			title: '',
			qr: '',
			qrLogoId: process.env.REACT_APP_QR_LOGO_ID || '',
			destinationUrl: '',
			slashTag: '',
			brandedDomain: BASE_URL,
			preset: '#000000',
			color: '#000000',
			bgColor: '#ffffff',
			isBgTransparent: false,
			pattern: 'classy',
			corner: 'square',
			logo: '',
			edit: 0,
			frame: null,
			primary: '',
			secondary: '',
			textColor: '#ffffff',
			text: 'Scan Me',
		},
	})

	const { reset, handleSubmit, setError } = form

	const editQrCode = async (data) => {
		QrCodesAPI.updateQrCodeCustomization(auth_token, edittedData?.id, data)
			.then((response) => {
				navigate('/qr-codes')
			})
			.catch((error) => {
				messageHandler(
					error?.response?.data?.data?.message ||
						error?.response?.data?.message ||
						'Something went wrong'
				)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const onError = () => {
		setTab(1)
	}

	const onSubmit = async (data) => {
		if (data) {
			setLoading(true)
			const titleAndIcon = await fetchTitleAndFavicon(data?.destinationUrl)
			let title = data?.title || titleAndIcon?.pageTitle || ''

			if (isEdit) {
				await editQrCode({
					...data,
					bgColor: data?.isBgTransparent ? '#ffffff' : data?.bgColor,
					title,
					brandedDomain: edittedData?.brandedDomain,
					slashTag: edittedData?.slashTag,
				})
				return
			}

			let newExpirationDate = data.expirationDate || ''
			if (newExpirationDate === '') {
				const currentDate = new Date()
				const futureDate = new Date(currentDate)
				futureDate.setDate(currentDate.getDate() + 90)
				newExpirationDate = futureDate
			}
			if (data.slashTag === '') {
				setError('slashTag', {
					type: 'custom',
					message: 'Custom slug is required.',
				})
			}

			QrCodesAPI.createQrCode(auth_token, {
				...data,
				slashTag: convertToURLFriendly(data?.slashTag),
				bgColor: data?.isBgTransparent ? '#ffffff' : data?.bgColor,
				faviconUrl: titleAndIcon?.icon || '',
				title,
				expirationDate: newExpirationDate,
			})
				.then((response) => {
					navigate('/qr-codes')
					reset()
				})
				.catch((error) => {
					messageHandler(
						error?.response?.data?.data?.message ||
							error?.response?.data?.message ||
							'Something went wrong'
					)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	useEffect(() => {
		if (isEdit && edittedData) {
			reset({
				edit: edittedData?.edit,
				title: edittedData?.title,
				destinationUrl: edittedData?.destinationUrl,
				slashTag: edittedData?.slashTag,
				brandedDomain: edittedData?.brandedDomain,
				preset: edittedData?.preset,
				color: edittedData?.color,
				bgColor: edittedData?.bgColor,
				pattern: edittedData?.pattern,
				corner: edittedData?.corner,
				logo: edittedData?.logo,
				qr: edittedData?.qr,
				qrLogoId: edittedData?.qrLogoId,
			})
		}
	}, [edittedData, isEdit])

	// useEffect(() => {
	//   fetchQuota();
	// }, []);

	return (
		<div className="w-full d-flex">
			<div className="mt-md-5 w-[280px] me-md-5 mt-xs-0 me-xs-0">
				<Sidebar />
			</div>
			<div className="customDashboardSize">
				<div className="d-flex flex-column w-100 flex-root app-root">
					<div className="app-page flex-column flex-column-fluid">
						<div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-5 mt-lg-5 mt-0 position-relative">
							<div className="app-main flex-column flex-row-fluid">
								<Card className="card card-flush mb-sm-60px rounded-sm-0">
									<CreateQrHeader loading={loading} isEdit={isEdit} />
									<CardBody className="card-body px-6 px-lg-8 pt-0 overflow-x-auto">
										<form
											className="h-100"
											onSubmit={handleSubmit(onSubmit, onError)}
										>
											<div className="d-flex justify-content-end mb-3">
												{message ? (
													<span className="error-text text-danger">
														{message}
													</span>
												) : null}
											</div>
											<div>
												<Row>
													<Col
														md={7}
														className="p-lg-5 px-3 pt-0 pt-lg-0 pe-lg-0"
													>
														<div className="card card-flush mw-1000px border-0 ps-0 pe-lg-0 h-xl-100">
															<QrCodesList
																onPreviewChange={handlePreviewChange}
															/>
														</div>
													</Col>
													<WebsiteQr
														form={form}
														qrLoading={qrLoading}
														tabsHandler={tabsHandler}
														selectedComponent={selectedPreview}
													/>
												</Row>
											</div>
										</form>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QrCodeList

import React from 'react'

const Icon0 = () => {
	return (
		<>
			<svg
				width="50"
				height="50"
				viewBox="0 0 50 50"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M25.0001 0C11.1929 0 0 11.1929 0 25.0001C0 38.8072 11.1929 50 25.0001 50C38.8072 50 50 38.8072 50 25.0001C50 11.1929 38.8072 0 25.0001 0ZM11.2513 8.58206C14.9711 5.46371 19.7663 3.58611 25.0001 3.58611C36.8266 3.58611 46.4139 13.1735 46.4139 25.0001C46.4139 30.2338 44.5363 35.029 41.4179 38.7487L11.2513 8.58206ZM8.58206 11.2513C5.46371 14.9711 3.58611 19.7663 3.58611 25.0001C3.58611 36.8266 13.1735 46.4139 25.0001 46.4139C30.2338 46.4139 35.029 44.5363 38.7487 41.4179L8.58206 11.2513Z"
					fill="black"
				/>
			</svg>
		</>
	)
}

export default Icon0

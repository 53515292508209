import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'
import convertToK from '../../../utils/convertToK'

const BarDeviceChart = ({ data, categories }) => {
	const chartRef = useRef(null)

	useEffect(() => {
		if (!chartRef.current) return

		const labelColor = '#34adws'
		const borderColor = '#dsferw'
		const maxValue = 18

		const options = {
			series: [
				{
					name: 'Clicks',
					data: [0, ...data],
				},
			],
			chart: {
				fontFamily: 'inherit',
				type: 'bar',
				height: 350,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					borderRadius: 8,
					horizontal: true,
					distributed: true,
					barHeight: 50,
					dataLabels: {
						position: 'bottom',
					},
				},
			},
			dataLabels: {
				enabled: true,
				textAnchor: 'start',
				offsetX: 0,
				formatter: function (val, opts) {
					return convertToK(+val || 0)
				},
				style: {
					fontSize: '14px',
					fontWeight: '600',
					align: 'left',
				},
			},
			legend: {
				show: false,
			},
			colors: ['#3E97FF', '#F1416C', '#50CD89', '#FFC700'],
			xaxis: {
				categories: ['', ...categories],
				labels: {
					formatter: function (val) {
						return convertToK(+val || 0)
					},
					style: {
						colors: [labelColor],
						fontSize: '14px',
						fontWeight: '600',
						align: 'left',
					},
				},
				axisBorder: {
					show: false,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val, opt) {
						if (Number.isInteger(val)) {
							var percentage = parseInt((val * 100) / maxValue).toString()
							return val + ' - ' + percentage + '%'
						} else {
							return val
						}
					},
					style: {
						colors: labelColor,
						fontSize: '14px',
						fontWeight: '600',
						paddingRight: '10px',
					},
					offsetY: 2,
					align: 'left',
				},
			},
			grid: {
				borderColor: borderColor,
				xaxis: {
					lines: {
						show: true,
					},
				},
				yaxis: {
					lines: {
						show: false,
					},
				},
				strokeDashArray: 4,
			},
			tooltip: {
				style: {
					fontSize: '12px',
				},
				y: {
					formatter: function (val) {
						return convertToK(+val || 0)
					},
				},
			},
		}

		const chart = new ApexCharts(chartRef.current, options)
		chart.render()

		return () => {
			chart.destroy()
		}
	}, [data, categories])

	return <div ref={chartRef}></div>
}

export default BarDeviceChart

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './CardGrid.css' // You can create a separate CSS file or use inline styles
import webicon from '../../assets/qr-icons/website.png'
import businessicon from '../../assets/qr-icons/business.png'
import vcardicon from '../../assets/qr-icons/vcard.png'
import facebookicon from '../../assets/qr-icons/facebook.png'
import barcodeicon from '../../assets/qr-icons/barcode.png'
import pdficon from '../../assets/qr-icons/pdf.png'
import videoicon from '../../assets/qr-icons/video.png'
import audioicon from '../../assets/qr-icons/audio.png'
import imageicon from '../../assets/qr-icons/image.png'
import instagramicon from '../../assets/qr-icons/instagram.png'
import socialicon from '../../assets/qr-icons/social-media.png'
import appicon from '../../assets/qr-icons/app.png'
import feedbackicon from '../../assets/qr-icons/feedback.png'
import ratingicon from '../../assets/qr-icons/rating.png'
import eventsicon from '../../assets/qr-icons/events.png'
import couponicon from '../../assets/qr-icons/coupon.png'

const CardGrid = ({ onPreviewChange }) => {
	const navigate = useNavigate() // Initialize the navigate function
	const [currentCardIndex, setCurrentCardIndex] = useState(null)

	const cards = [
		{
			icon: webicon,
			case: 'A',
			title: 'Website',
			description: 'A custom website to showcase your online presence.',
			url: '/create-qr-code-website',
		},
		{
			icon: businessicon,
			case: 'B',
			title: 'Business Page',
			description: 'A professional business page for better outreach.',
			url: '/create-qr-code-business-page',
		},
		{
			icon: vcardicon,
			case: 'C',
			title: 'VCard Plus',
			description: 'An advanced vCard with rich contact details.',
			url: '/create-qr-code-vcard',
		},
		{
			icon: barcodeicon,
			case: 'D',
			title: '2D Barcode',
			description: 'Generate 2D barcodes for quick information access.',
			url: '/create-qr-code-2dbarcode',
		},
		{
			icon: pdficon,
			case: 'E',
			title: 'PDF',
			description: 'Share important documents in PDF format.',
			url: '/create-qr-code-pdf',
		},
		{
			icon: videoicon,
			case: 'F',
			title: 'Video',
			description: 'Embed and share engaging video content.',
			url: '/create-qr-code-video',
		},
		{
			icon: audioicon,
			case: 'G',
			title: 'Audio',
			description: 'Share music, podcasts, or any audio media.',
			url: '/create-qr-code-audio',
		},
		{
			icon: imageicon,
			case: 'H',
			title: 'Image',
			description: 'Showcase images in high quality.',
			url: '/create-qr-code-images',
		},
		{
			icon: facebookicon,
			case: 'I',
			title: 'Facebook',
			description: 'Link to your Facebook business or personal profile.',
			url: '/create-qr-code-facebook',
		},
		{
			icon: instagramicon,
			case: 'J',
			title: 'Instagram',
			description: 'Connect with your Instagram profile.',
			url: '/create-qr-code-instagram',
		},
		{
			icon: socialicon,
			case: 'K',
			title: 'Social Media',
			description: 'Engage with multiple social media platforms.',
			url: '/create-qr-code-social-media',
		},
		{
			icon: appicon,
			case: 'L',
			title: 'App',
			description: 'Promote your mobile app and increase downloads.',
			url: '/create-qr-code-app',
		},
		{
			icon: feedbackicon,
			case: 'M',
			title: 'Feedback',
			description: 'Gather feedback from your users.',
			url: '/create-qr-code-feedback',
		},
		{
			icon: ratingicon,
			case: 'N',
			title: 'Rating',
			description: 'Display user ratings and reviews.',
			url: '/create-qr-code-rating',
		},
		{
			icon: couponicon,
			case: 'O',
			title: 'Coupons',
			description: 'Offer discounts with promotional coupons.',
			url: '/create-qr-code-coupons',
		},
		{
			icon: eventsicon,
			case: 'P',
			title: 'Events',
			description: 'Promote upcoming events and gather RSVPs.',
			url: '/create-qr-code-events',
		},
	]

	const handleCardClick = (url) => {
		navigate(url) // Navigate to the URL
	}

	return (
		<div className="card-container qr-codes-list">
			{cards.map((card, index) => (
				<div
					key={index}
					className={`card border rounded-1 d-flex justify-content-between border-gray-300 cursor-pointer ${currentCardIndex === index ? 'current' : ''}`} // Conditionally apply 'current' class
					onClick={() => handleCardClick(card.url)} // Add click event
				>
					<div className="d-flex">
						<div className="card-icon">
							<img className="w-40px" src={card.icon} alt={card.title} />
						</div>
						<div>
							<div className="card-title">{card.title}</div>
							<p className="card-description">{card.description}</p>
						</div>
					</div>

					<div>
						<button
							className="btn btn-icon bg-light-info text-hover-info border border-gray-250 rounded-1 card-qr-view"
							onClick={(e) => {
								e.stopPropagation() // Prevent card click event
								setCurrentCardIndex(index) // Update state to mark the current card
								onPreviewChange(card.case) // Trigger preview change
							}}
						>
							<i className="fa-solid fa-eye fs-3" />
						</button>
					</div>
				</div>
			))}
		</div>
	)
}

export default CardGrid

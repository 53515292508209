import React, { useCallback, useContext, useState } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import { Input, Spinner, Tooltip } from 'reactstrap'
import _debounce from 'lodash/debounce'
import { ShortLinkContxt } from '../shortLinkContxt'
import { AuthContxt } from '../../../store/authContxt'

const SORTS = [
	{ label: 'A - Z', value: 'a-z' },
	{ label: 'Z- A', value: 'z-a' },
	{ label: 'Oldest', value: 'oldest' },
	{ label: 'Latest', value: 'latest' },
	{ label: 'Most Clicked', value: 'mostClicked' },
]

const ShortLinksHeader = ({
	shortlinksHandler,
	tags,
	setIsAddNewLinkOpen,
	addBulkShow,
	loading,
	count,
}) => {
	const { filters, setFilters, search, setSearch } = useContext(ShortLinkContxt)
	const [showSearch, setShowSearch] = useState(false)
	const [showSearchmob, setShowSearchmob] = useState(true)

	const { quota } = useContext(AuthContxt)
	const isLimitReached = quota?.shortlink === 0 || false
	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [bulkTooltip, setBulkTooltip] = useState(false)

	const toggle = () => {
		if (isLimitReached) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	const bulkToggle = () => {
		if (isLimitReached) {
			setBulkTooltip(!bulkTooltip)
		}
	}

	const filtersHandler = (key, value) => {
		setFilters((preState) => {
			return { ...preState, [key]: value }
		})
		shortlinksHandler({ ...filters, [key]: value, search })
	}

	const handleSearch = useCallback(
		_debounce((filters) => shortlinksHandler(filters), 500),
		[]
	)

	const searchHandler = (search) => {
		handleSearch({ ...filters, search })
		setSearch(search)
	}

	return (
		<div className="px-6 px-lg-8 py-lg-8 py-5 d-flex items-center justify-content-between w-100 align-items-center">
			<h2 className="pl-4 mb-0 d-flex align-items-center fs-1">
				Short Links{' '}
				<span className="badge bg-light-danger text-danger fs-5 border ms-3 rounded-1">
					{count}
				</span>
			</h2>
			<div className="card-toolbar justify-content-center align-items-center gap-5 me-0 mb-0 h-10 cardToolbarIcons">
				{loading ? <Spinner /> : null}
				<InputGroup className="mb-0 w-auto">
					{showSearch ? (
						<Form.Control
							className="headerinputsearch fw-normal"
							placeholder="Search here ..."
							value={search}
							onChange={(e) => {
								searchHandler(e.target.value)
							}}
						></Form.Control>
					) : null}
					<InputGroup.Text
						id="basic-addon2"
						title="search"
						className="cursor-pointer btn btn-icon rounded-1 bg-light-info"
						onClick={() => {
							setShowSearch(!showSearch)
						}}
					>
						<i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
					</InputGroup.Text>
				</InputGroup>
				<button
					type="button"
					title="Starred"
					onClick={() => filtersHandler('starred', !filters.starred)}
					className="btn btn-icon rounded-1 bg-light-info"
				>
					<i
						className={`fs-3 fa-star ${
							filters.starred
								? 'fa-solid'
								: 'fa-regular on-hvr-yellow text-info'
						}`}
						style={filters.starred ? { color: 'gold' } : {}}
					></i>
				</button>

				<Dropdown className="d-inline nospce">
					<Dropdown.Toggle
						id="dropdown-autoclose-false"
						className="btn btn-icon bg-light-info rounded-1"
						title="Tags"
						variant="bg-light"
					>
						<i className="fa-solid fa-tags text-info fs-3"></i>
					</Dropdown.Toggle>

					<Dropdown.Menu className="py-3">
						{tags && tags.length > 0 ? (
							tags?.map((tag) => (
								<Dropdown.Item key={tag}>
									<div
										onClick={() => {
											const filteredTags = filters.tags.includes(tag)
												? filters.tags.filter((item) => item !== tag)
												: [...filters.tags, tag]
											filtersHandler('tags', filteredTags)
										}}
										className="d-flex justify-content-between align-items-center cursor-pointer"
									>
										<div class="form-check form-check-custom form-check-info form-check-solid d-flex justify-content-between w-100">
											<label class="form-check-label text-dark me-3">
												{tag}
											</label>
											<Input
												class="form-check-input n-checked-white-hvr rounded-1 tag-chk"
												type="checkbox"
												checked={filters.tags.includes(tag)}
											/>
										</div>
									</div>
								</Dropdown.Item>
							))
						) : (
							<span style={{ marginLeft: '10px' }}>No tag found!</span>
						)}
					</Dropdown.Menu>
				</Dropdown>

				<Dropdown className="d-inline nospce">
					<Dropdown.Toggle
						id="dropdown-autoclose-true"
						className="btn btn-icon bg-light-info rounded-1"
						title="Sort By"
						variant="bg-light"
					>
						<i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{SORTS.map((item) => {
							const { label, value } = item
							return (
								<Dropdown.Item
									key={value}
									onClick={() => filtersHandler('sort', value)}
								>
									{label}
								</Dropdown.Item>
							)
						})}
					</Dropdown.Menu>
				</Dropdown>

				<div onMouseOver={bulkToggle} onMouseLeave={bulkToggle} id="bulkShort">
					<button
						type="button"
						title="Bulk Import"
						disabled={isLimitReached}
						className="btn btn-icon rounded-1 bg-light-info"
						onClick={addBulkShow}
					>
						<i className="fa-solid fa-arrow-right-to-bracket fa-rotate-90 text-info fs-3"></i>
					</button>
				</div>

				<Tooltip
					placement={'top'}
					isOpen={bulkTooltip}
					target={'bulkShort'}
					toggle={bulkToggle}
				>
					{`Your Monthly Shortlink Limit Reached. Please Upgrade your Plan`}
				</Tooltip>

				<div onMouseOver={toggle} onMouseLeave={toggle} id="plusIcon">
					<button
						type="button"
						disabled={isLimitReached}
						title="Add New Link"
						className="btn btn-icon rounded-1 btn-info"
						color="danger"
						onClick={() => !isLimitReached && setIsAddNewLinkOpen(true)}
					>
						<i className="fa-solid fa-plus fs-3"></i>
					</button>
				</div>

				<Tooltip
					placement={'top'}
					isOpen={tooltipOpen}
					target={'plusIcon'}
					toggle={toggle}
				>
					{`Your Monthly Shortlink Limit Reached. Please Upgrade your Plan`}
				</Tooltip>
			</div>
			<div className="desktop-hide-f align-items-center justify-content-center">
				<div className="shortlinkMenu mt-0">
					<button
						type="button"
						title="more"
						className="btn btn-icon rounded-1 border bg-light-info"
					>
						<i className="fa-regular fa-magnifying-glass text-info fs-3"></i>
					</button>

					<div className="dropdown-content mobsearchbardropdown border bg-white rounded-1 py-5 end--50px">
						<div className="card-toolbar d-flex flex-wrap justify-content-center lg:flex-row align-items-center gap-5 me-0 mb-0 h-10">
							<InputGroup className="mb-0">
								{showSearchmob ? (
									<Form.Control
										placeholder="Search...."
										className="headerinputsearch rounded-1 border"
										aria-label="Recipient's username"
										aria-describedby="basic-addon2"
										onChange={(e) => {
											searchHandler(e.target.value)
										}}
									></Form.Control>
								) : (
									showSearchmob
								)}
								<InputGroup.Text
									id="basic-addon2"
									className="cursor-pointer btn btn-icon rounded-1 d-none bg-light-info"
								>
									<i className="fa-solid fa-magnifying-glass text-info fs-3"></i>
								</InputGroup.Text>
							</InputGroup>
						</div>
					</div>
				</div>

				<div className="position-absolute d-flex justify-content-between mobbottommenu">
					<button
						onClick={() => filtersHandler('starred', !filters.starred)}
						type="button"
						className="btn btn-icon rounded-1 bg-light-info"
					>
						<i
							className={`fs-3 fa-star ${
								filters.starred
									? 'fa-solid'
									: 'fa-regular on-hvr-yellow text-info'
							}`}
							style={filters.starred ? { color: 'gold' } : {}}
						></i>
					</button>

					<Dropdown className="d-inline nospce">
						<Dropdown.Toggle
							className=" btn btn-icon bg-light-info rounded-1"
							id="dropdown-autoclose-false"
							variant="bg-light"
						>
							<i className="fa-solid fa-tags text-info fs-3"></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{tags &&
								tags?.length > 0 &&
								tags?.map((tag) => (
									<Dropdown.Item key={tag}>
										<div
											onClick={() => {
												const filteredTags = filters.tags.includes(tag)
													? filters.tags.filter((item) => item !== tag)
													: [...filters.tags, tag]
												filtersHandler('tags', filteredTags)
											}}
											className="d-flex justify-content-between align-items-center cursor-pointer"
										>
											<span>{tag}</span>
											<Input
												className="form-check-input n-checked-white-hvr rounded-1 tag-chk"
												type="checkbox"
												checked={filters.tags.includes(tag)}
											/>{' '}
										</div>
									</Dropdown.Item>
								))}
						</Dropdown.Menu>
					</Dropdown>
					<div></div>
					<Dropdown className="d-inline nospce">
						<Dropdown.Toggle
							className="btn btn-icon bg-light-info rounded-1"
							id="dropdown-autoclose-true"
							variant="bg-light"
						>
							<i className="fa-solid fa-arrow-up-wide-short text-info fs-3"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{SORTS.map((item, index) => {
								const { label, value } = item
								return (
									<Dropdown.Item
										key={index}
										onClick={() => filtersHandler('sort', value)}
									>
										{label}
									</Dropdown.Item>
								)
							})}
						</Dropdown.Menu>
					</Dropdown>

					<button
						type="button"
						className="btn btn-icon rounded-1 bg-light-info px-4 py-3"
						onClick={addBulkShow}
					>
						<i className="fa-solid fa-arrow-down text-info fs-3"></i>
					</button>
				</div>
				<div>
					<div onMouseOver={toggle} onMouseLeave={toggle} id="shortlinkFormMob">
						<button
							type="button"
							title="Add New Link"
							disabled={isLimitReached}
							className="btn btn-icon btn-info rounded-circle mobile-add-link-btn desktop-hide"
							color="danger"
							onClick={() => setIsAddNewLinkOpen(true)}
						>
							<i className="fa-solid fa-plus fs-2x"></i>
						</button>
					</div>

					<Tooltip
						placement={'left'}
						isOpen={tooltipOpen}
						target={'shortlinkFormMob'}
						toggle={toggle}
						className="desktop-hide"
					>
						{`Your Monthly Shortlink Limit Reached. Please Upgrade your Plan`}
					</Tooltip>
				</div>
			</div>
		</div>
	)
}

export default ShortLinksHeader

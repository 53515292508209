import { useState } from 'react'
import { Spinner } from 'reactstrap'
import UserSettingsApi from '../../../services/http/userSettings'
import DeleteModal from '../../../modals/deleteModal'

import Visa from '../../../assets/media/card-logos/visa.svg'
import Mastercard from '../../../assets/media/card-logos/mastercard.svg'
import AmericanExpress from '../../../assets/media/card-logos/american-express.svg'

const cardsMapper = {
	Visa: Visa,
	MasterCard: Mastercard,
	'American Express': AmericanExpress,
}
const PaymentCard = ({ card, cardHandler = () => {}, showPrimary }) => {
	const [loading, setLoading] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)

	const deleteCardHandler = async () => {
		try {
			setDeleteModal(false)
			setLoading(true)
			const { data } = await UserSettingsApi.deleteCard(card?.id)
			if (data?.data) {
				cardHandler('delete', { id: card?.id })
			}
			setLoading(false)
		} catch (e) {
			console.log('Error while deleting card: ', e)
			setLoading(false)
		}
	}

	const { name, last4, exp_month, exp_year, brand } = card || {}

	return (
		<>
			<div className="col-xl-6">
				<div
					className={`card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-lg-6 p-5`}
				>
					<div className="d-flex flex-column py-2 mobile-hide-f">
						<div className="d-flex align-items-center fs-4 fw-bold mb-5">
							{name}
							{showPrimary ? (
								<span className="badge bg-light-info text-info ms-3">
									Primary
								</span>
							) : null}
						</div>

						<div className="d-flex align-items-center">
							<img src={cardsMapper[brand]} alt={brand} className="me-4" />

							<div>
								<div className="fs-4 fw-bold text-start">{`${brand} **** ${last4}`}</div>
								<div className="fs-6 fw-semibold text-gray-500">
									{`Card expires at ${exp_month}/${exp_year}`}
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column desktop-hide-f">
						<div className="d-flex align-items-center fs-4 fw-bold mb-5">
							{name}
							{showPrimary ? (
								<span className="badge bg-light-info text-info ms-3">
									Primary
								</span>
							) : null}
						</div>

						<div className="d-flex flex-column align-items-start">
							<div>
								<div className="fs-4 fw-bold text-start">{`${brand} **** ${last4}`}</div>
								<div className="fs-6 fw-semibold text-gray-500 text-start">
									{`Card expires at ${exp_month}/${exp_year}`}
								</div>
							</div>
							<img
								src={cardsMapper[brand]}
								alt={brand}
								className="me-4 border border-gray-300 rounded-1 bg-white mt-3"
							/>
						</div>

						<div className="py-2 pt-4">
							<button
								type="button"
								disabled={loading}
								onClick={(e) => {
									setDeleteModal(true)
								}}
								className="btn btn-sm btn-light-danger border border-danger border-opacity-10 me-3"
							>
								{loading ? <Spinner /> : 'Delete'}
							</button>
						</div>
					</div>

					<div className="py-2 mobile-hide">
						<button
							type="button"
							disabled={loading}
							onClick={(e) => {
								setDeleteModal(true)
							}}
							className="btn btn-sm btn-light-danger border border-danger border-opacity-10 me-3"
						>
							{loading ? <Spinner /> : 'Delete'}
						</button>
					</div>
				</div>
			</div>

			<DeleteModal
				isOpen={deleteModal}
				modalHandler={() => setDeleteModal(false)}
				leftBtnHandler={deleteCardHandler}
				rightBtnHandler={() => setDeleteModal(false)}
				data={{
					heading: 'Delete Card',
					description: 'Are you sure you want to delete this card ?',
					leftBtn: 'Yes, delete it',
					rightBtn: 'Cancel',
				}}
			/>
		</>
	)
}

export default PaymentCard

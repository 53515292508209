import React, { useEffect } from 'react'
import { isColorDark } from './color-checker/isColorDark'

const ImageGallery = ({ formData }) => {
	const { imageList } = formData
	const images = [
		'https://loremflickr.com/200/200?random=1',
		'https://loremflickr.com/200/200?random=2',
		'https://loremflickr.com/200/200?random=3',
		'https://loremflickr.com/200/300?random=4',
		'https://loremflickr.com/300/200?random=5',
		'https://loremflickr.com/200/200?random=6',
		'https://loremflickr.com/300/200?random=7',
		'https://loremflickr.com/200/300?random=8',
		'https://loremflickr.com/200/300?random=9',
		'https://loremflickr.com/200/200?random=10',
	]
	return (
		<div className="position-relative onhvr-scroll-y">
			<div
				className="w-100 mx-auto pb-10 scroll-content"
				style={{
					height: '575px',
					maxWidth: ' 900px',
				}}
			>
				<div
					style={{
						background: formData?.primaryColor || 'black',
					}}
					className=" py-10 px-5 pt-15"
				>
					<div className="text-center text-light">
						<h4 className="fw-bold fs-1 text-light">
							{formData?.pageTitle || 'Page Title'}
						</h4>
						<p className="fs-5 mb-0">
							{formData?.description ||
								'Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui ex'}
						</p>
						<a
							target="_blank"
							style={{
								background: formData?.ctaColor,
							}}
							href={formData?.buttonLink}
							referrerPolicy="no-referrer"
							className={`text-${isColorDark(formData?.ctaColor) ? 'white' : 'black'} btn btn-light mt-5 px-10`}
						>
							{' '}
							{formData?.buttonText || 'View'}
						</a>
					</div>
				</div>
				<div className="preview-qr-image-main-container bg-light">
					<div className="preview-qr-image-container">
						{imageList?.length > 0
							? imageList?.map((src, index) => (
									<img
										key={index}
										src={src}
										alt={`Random from LoremFlickr ${index + 1}`}
									/>
								))
							: images?.map((src, index) => (
									<img
										key={index}
										src={src}
										alt={`Random from LoremFlickr ${index + 1}`}
									/>
								))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImageGallery

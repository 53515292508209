import React, { useState } from 'react'
import { Card, Input, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import Sidebar from '../../components/common/sidebar'

const SetupWhatsappCampaign = () => {
	// State for modal visibility
	const [isFromModalOpen, setFromModalOpen] = useState(false)
	const [isToModalOpen, setToModalOpen] = useState(false)

	// Toggle functions for modals
	const toggleFromModal = () => setFromModalOpen(!isFromModalOpen)
	const toggleToModal = () => setToModalOpen(!isToModalOpen)

	return (
		<div>
			<div className="w-full d-flex">
				<div className="mt-md-10 w-[280px] me-md-7 mt-xs-0 me-xs-0">
					<Sidebar />
				</div>
				<div className="customDashboardSize">
					<div className="d-flex flex-column w-100 flex-root app-root">
						<div className="app-page flex-column flex-column-fluid">
							<div className="app-wrapper flex-column flex-row-fluid pe-3 pe-lg-0 me-lg-10 mt-lg-10 mt-0 position-relative">
								<div className="app-main flex-column flex-row-fluid">
									<Card className="card card-flush">
										<div
											className="card card-flush position-absolute scroll-y"
											data-kt-scroll-max-height="auto"
											data-kt-scroll-offset="100px"
											style={{
												right: '0px',
												bottom: '0px',
												height: '100%',
												left: '0',
												marginLeft: '27px',
											}}
										>
											<div className="ps-10 pt-10 d-flex justify-content-between w-100">
												<h2 className="me-15 fs-2x mb-0">
													<a href="create-whatsapp-campaign.html">
														<i className="fal fa-circle-arrow-left fs-1 me-3 text-gray-600 text-hover-dark"></i>
													</a>
													Campaign Name
												</h2>

												<div>
													<div className="d-flex gap-5 me-10">
														<button className="btn btn-info rounded-1">
															Schedule
														</button>
													</div>
												</div>
											</div>

											<div className="separator border-1 my-5"></div>

											<div className="card-body d-flex pt-0 w-100">
												<div className="row w-100">
													<div className="col-6 h-100">
														<div className="border h-fit-content rounded-1 w-100 mt-5">
															<div>
																<div className="d-flex justify-content-between align-items-center py-10 px-20 border-bottom">
																	<div>
																		<h3 className="fs-4 fw-bold mb-2">From</h3>
																		<p className="text-gray-600 fs-5">
																			Choose which WhatsApp Business account you
																			will show.
																		</p>
																	</div>
																	<div>
																		<button
																			onClick={toggleFromModal}
																			className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
																		>
																			<i className="far fa-pen-to-square"></i>
																		</button>
																	</div>
																</div>
																<div className="d-flex justify-content-between py-10 px-20 border-bottom">
																	<div>
																		<h3 className="fs-4 fw-bold mb-2">To</h3>
																		<p className="text-gray-600 fs-5">
																			Select a list of recipients
																		</p>
																	</div>
																	<div>
																		<button
																			onClick={toggleToModal}
																			className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info"
																		>
																			<i className="far fa-pen-to-square"></i>
																		</button>
																	</div>
																</div>
																<div className="px-20 border-bottom">
																	{/* <div className="d-flex justify-content-between py-10">
                                    <div>
                                      <h3 className="fs-4 fw-bold mb-2">Message Content</h3>
                                      <p className="text-gray-600 fs-5">Select an existing message or start a new design.</p>
                                    </div>
                                    <div>
                                      <button className="btn btn-icon bg-light-info border rounded-1 fw-bold fs-5 hover-elevate-up text-info">
                                        <i className="far fa-plus"></i>
                                      </button>
                                    </div>
                                  </div> */}
																	<div
																		id="message-content-wa-campaign"
																		className="mb-10 py-10"
																	>
																		<label className="fs-5 fw-bold mb-2">
																			<span>Select Template</span>
																		</label>
																		<select
																			id="wa-temp-select"
																			className="form-select rounded-1"
																			data-control="select2"
																			data-hide-search="true"
																			data-placeholder="Select Template"
																		>
																			<option></option>
																			<option value="1">
																				50% Off Template
																			</option>
																			<option value="2">
																				Welcome Template
																			</option>
																			<option value="3">
																				Thank You Template
																			</option>
																		</select>
																		{/* <div className="d-flex w-100 justify-content-end">
                                      <button className="btn btn-light-info mt-6 rounded-1 border border-info border-opacity-10">Create New Message</button>
                                    </div> */}
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-6 pt-5 pe-0 position-relative">
														<div
															className="rounded-1 h-100 pt-15 position-sticky"
															style={{
																border: '1px solid #e9e6f1',
																top: '50px',
																backgroundColor: '#F7F5FD',
															}}
														>
															<div
																className="card border-0 card-flush d-flex justify-content-center bg-transparent position-sticky p-3 pt-0 mb-0"
																style={{ top: '60px' }}
															>
																<img
																	id="mobile-content"
																	src="../app/assets/media/whatsapp-campaign/chat.png"
																	width="300"
																	height="600"
																	className="mx-auto mb-8"
																	style={{
																		border: '10px solid black',
																		borderRadius: '30px',
																	}}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* From Modal */}
			<Modal isOpen={isFromModalOpen} toggle={toggleFromModal}>
				<ModalHeader toggle={toggleFromModal}>From</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-12 mt-6">
							<label className="fs-5 fw-bold mb-2">
								<span>Select Sender Number</span>
							</label>
							<select
								className="form-select"
								data-control="select2"
								data-hide-search="true"
								data-placeholder="Select Number"
							>
								<option></option>
								<option value="1">+91 123456789</option>
								<option value="2">+91 987654321</option>
							</select>
							<div>
								<div className="notice d-flex bg-light-info rounded-1 border-info border border-dashed align-items-center p-6 mt-10 mb-10">
									<i className="ki-duotone ki-information fs-2tx text-info me-4">
										<span className="path1"></span>
										<span className="path2"></span>
										<span className="path3"></span>
									</i>

									<div className="d-flex flex-stack flex-grow-1">
										<div className="fw-semibold">
											<p className="mb-0">
												You need to{' '}
												<a
													className="text-dark"
													href="whatsapp-campaign-settings.html"
													style={{ textDecoration: 'underline' }}
												>
													login to{' '}
													<span className="fw-bolder text-black">Facebook</span>
												</a>{' '}
												and link your WhatsApp Business account to Divsly in
												order to schedule a campaign.
											</p>
											<a
												className="d-block bgi-no-repeat mt-1 fw-normal text-info bgi-size-cover bgi-position-center rounded position-relative"
												style={{ textDecoration: 'underline' }}
												data-fslightbox="lightbox-youtube"
												href="https://www.youtube.com/watch?v=o-bLOVV5__E"
											>
												Watch How to connect Facebook
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="d-flex gap-5 mt-6 justify-content-end">
								<button className="btn btn-light border border-2 rounded-1 border">
									Cancel
								</button>
								<button className="btn btn-info rounded-1 s">Save</button>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>

			{/* To Modal */}
			<Modal isOpen={isToModalOpen} toggle={toggleToModal}>
				<ModalHeader toggle={toggleToModal}>Select Recipients</ModalHeader>
				<ModalBody>
					<p>Here you can select the list of recipients for your campaign.</p>
					{/* Add your content here */}
				</ModalBody>
			</Modal>
		</div>
	)
}

export default SetupWhatsappCampaign

import Copy from '../../common/copy'
import colorlogo from '../../../assets/logos/divsly-icon-c.svg'

const FinelStep = ({ customLinkHandler, finalStep }) => {
	const { data } = finalStep
	return (
		<div className="card-body overflow-x-auto p-lg-10 p-6 mw-1000px m-auto">
			<div
				className="d-flex justify-content-center align-items-center flex-flow-column"
				style={{
					flexFlow: 'column',
					gap: '5px',
				}}
			>
				<img className="w-40px mb-5" src={colorlogo} alt="logo" />
				<h2 className="fw-bold px-lg-0">You are almost done!</h2>
				<p className="fw-bold px-lg-0">
					Just follow below step and you are done
				</p>
			</div>
			<div
				id="statusDiv"
				className={`notice border-1 d-flex rounded align-items-center p-6 mb-10 rejected-status-modal`}
			>
				<i id="modal_icon" className="fal fs-2x text-warning me-4">
					<span className="path1"></span>
					<span className="path2"></span>
					<span className="path3"></span>
				</i>

				<div className="d-flex flex-stack flexGrow-1">
					<div id="status" className="fw-semibold">
						<p id="verified-text" className="mb-2 fwBold fs-4">
							We need your attention!
						</p>
						<p id="extra-text">
							IMPORTANT: Configure your existing domain DNS records. Your domain
							will not be available immediately, it can take up to 24 hours for
							the propagate. We'll email you when the setup is complete.
						</p>
					</div>
				</div>
			</div>

			<div className="pb-8 pb-lg-10 my-lg-5">
				<h2 className="fw-bold textDark fs-4">Mandatory</h2>

				<div className="text-muted fw-normal fs-6">
					{' '}
					Apply the following DNS configuration to turn any domain into your own
					link shortener. All you have to do is “point” your{' '}
					<span className="text-gray-600 fw-bold">A</span> Records to Divsly,
					and make sure that all pre-existing records of type{' '}
					<span className="text-gray-600 fw-bold">A, </span>
					<span className="text-gray-600 fw-bold">AAAA</span> or{' '}
					<span className="text-gray-600 fw-bold">CNAME</span> for{' '}
					<span id="modal_domain_span">sub.divsly.com</span> are removed
				</div>
			</div>
			<div className="table-responsive pb-lg-10">
				<table
					className="table table-rounded table-rowBordered border borderDashed borderDark gy-7 gs-7"
					style={{
						borderColor: 'rgba(0, 0, 0, 0.35) !important',
					}}
				>
					<thead>
						<tr className="fw-semibold fs-6 textGray-800 borderBottom-2 borderGray-200">
							<th> Record type </th>
							<th> hostname(or name) </th>
							<th> Points to(or address) </th>
						</tr>
					</thead>
					<tbody>
						<tr className="table-active rounded-2">
							<td className="rounded-2"> A </td>
							<td id="table_domain">
								{data?.spare === 'No' ? data?.subDomain : '@'}
							</td>
							<td className="rounded-2">
								{process.env.REACT_APP_TARGET_IP}
								<Copy
									copyData={process.env.REACT_APP_TARGET_IP}
									includeProtocol={false}
								/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="pt-10 row">
				<div className="d-flex justify-content-center">
					<button
						type="button"
						onClick={customLinkHandler}
						className="btn btn-info h-50px w-lg-300px w-100 rounded-1"
					>
						Go to Custom Links
					</button>
				</div>
			</div>
		</div>
	)
}

export default FinelStep

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 2907}
				height={height || 3429}
				viewBox="0 0 2907 3429"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M2678.04 229.274C2530.44 81.6397 2333.88 0.328166 2124.58 0.328166C1915.28 0.328166 1718.73 81.6397 1571.12 229.234C1450.12 350.227 1373.71 504.09 1350.06 670.892H894.561V335.446C894.561 150.474 744.08 0 559.1 0H111.823C50.0509 0 0 50.0487 0 111.818C0 173.587 50.0509 223.635 111.823 223.635H559.106C620.764 223.635 670.929 273.798 670.929 335.453V670.899H335.461C304.083 670.899 274.158 684.079 252.981 707.185C231.796 730.331 221.315 761.312 224.081 792.535L397.521 2897.5H2062.53L2167.9 1563.92C2359.35 1553.5 2540.76 1473.49 2678.04 1336.15C2825.68 1188.55 2907 992 2907 782.71C2907 573.42 2825.68 376.869 2678.04 229.274ZM1658.12 474.374L1854.64 670.892H1576.93C1591.07 600.564 1618.56 534.054 1658.12 474.374ZM931.833 2161.76C870.081 2161.76 820.01 2111.7 820.01 2049.95C820.01 1988.19 870.074 1938.13 931.833 1938.13C993.591 1938.13 1043.66 1988.19 1043.66 2049.95C1043.66 2111.7 993.591 2161.76 931.833 2161.76ZM1528.21 2534.48C1466.46 2534.48 1416.39 2484.42 1416.39 2422.66C1416.39 2360.91 1466.45 2310.85 1528.21 2310.85C1589.96 2310.85 1640.03 2360.91 1640.03 2422.66C1640.03 2484.42 1589.96 2534.48 1528.21 2534.48ZM1985.09 1219.5C1899.32 1222.9 1780.08 1239.44 1701.84 1278.56C1509.34 1374.86 1359.01 1275.25 1284.46 1249.13C1149.36 1201.3 998.212 1201.22 854.583 1258.32C787.989 1284.91 650.053 1359.74 496.022 1309L457.585 894.514H2002.47L1985.09 1219.5ZM2124.58 624.594L1816.24 316.259C1906.83 256.224 2013.16 223.957 2124.59 223.957C2236.02 223.957 2342.35 256.217 2432.94 316.259L2124.58 624.594ZM2187.83 1338.07L2214.91 1031.15L2432.91 1249.13C2359.46 1297.76 2275.58 1328.26 2187.83 1338.07ZM2591.05 1091.03L2282.71 782.703L2591.06 474.368C2651.09 564.955 2683.36 671.281 2683.36 782.703C2683.36 894.126 2651.09 1000.45 2591.05 1091.03Z"
					fill={primary || 'black'}
				/>
				<path
					d="M417.258 2981.74L447.725 3327.01C452.822 3384.73 501.158 3429 559.106 3429H1900.95C1958.9 3429 2007.24 3384.74 2012.33 3327.01L2042.8 2981.74H417.258Z"
					fill={secondary || 'white'}
				/>
				<rect
					x="572"
					y="1459"
					width="1316"
					height="1316"
					rx="50"
					fill="white"
				/>
				<text
					fill={textColor || 'black'}
					xmlSpace="preserve"
					style={{ whiteSpace: 'pre' }}
					fontFamily="Arial"
					fontSize={fontSize || 224}
					fontWeight="bold"
					letterSpacing="0em"
				>
					<tspan x="42%" textAnchor="middle" y="3282.66">
						{text}
					</tspan>
				</text>

				<image href={url} x="658" y="1545" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

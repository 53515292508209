import { useContext, useEffect, useState } from 'react'
import QrCreatedPage from './qrCreatedPage'
import CreatePageModal from '../create-page-intro/create-page-modal'
import LinkInBioApi from '../../../services/http/linkInBio'
import { Spinner, Tooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import convertToURLFriendly from '../../../utils/convertToURLFriendly'
import { AuthContxt } from '../../../store/authContxt'
import { LinkInBioContxt } from '../linkInBioContxt'

const LinkInBioHeader = ({ tabsHandler }) => {
	const { bioPageForm, isEdit, setIsEdit } = useContext(LinkInBioContxt)
	const { quota, fetchQuota } = useContext(AuthContxt)
	const [isPublished, setIsPublished] = useState(false)
	const { watch, setValue, reset } = bioPageForm
	const { pageLink, pageDomain, id = null, isActive } = watch()
	const [loading, setLoading] = useState(false)
	const [draftLoading, setDraftLoading] = useState(false)
	const [toggleEditPageModal, setToggleEditPageModal] = useState(false)
	const [toggleQrModal, setToggleQrModal] = useState(false)

	const [tooltipOpen, setTooltipOpen] = useState(false)
	const [previewTooltip, setPreviewTooltip] = useState(false)
	const [bioPageTooltipOpen, setBioPageTooltipOpen] = useState(false)

	const [message, setMessage] = useState('')
	const messageHandler = (msg) => {
		setMessage(msg)
		setTimeout(() => {
			setMessage('')
		}, 5 * 1000)
	}

	const editPageModalHandler = () => {
		setToggleEditPageModal(false)
	}

	const editPageDataHandler = (data) => {
		setValue('pageDomain', data?.pageDomain)
		setValue('pageLink', data?.pageLink)
		setToggleEditPageModal(false)
	}

	const [isCopied, setIsCopied] = useState(false)

	const copyHandler = () => {
		setIsCopied(true)
		navigator.clipboard
			.writeText(`https://${pageDomain}/${pageLink}`)
			.then(() => {
				console.log('Link copied to clipboard')
			})
			.catch((err) => {
				console.error('Failed to copy link: ', err)
			})
			.finally(() => {
				setTimeout(() => {
					setIsCopied(false)
				}, 500)
			})
	}

	const newLinkInBio = async (draft) => {
		try {
			const data = watch()
			const mappedLinks = data?.links.map((item) => {
				return {
					destinationUrl: item?.destinationUrl,
					isActive: draft ? false : item?.isActive,
					btnLabel: item?.btnLabel,
					slashTag: item?.slashTag,
					brandedDomain: item?.brandedDomain,
				}
			})
			const { socials, goal, category, pageLink, ...rest } = data
			const updatedPayload = {
				...rest,
				pageLink: convertToURLFriendly(pageLink),
				links: mappedLinks,
				socials: JSON.stringify(socials?.filter((social) => social?.isValid)),
				...(draft ? { isActive: false } : {}),
			}
			const response = await LinkInBioApi.createLinkInBio(updatedPayload)
			if (response?.data?.status === 200) {
				setIsPublished(true)
				await fetchQuota()
				setIsEdit(true)
				reset({
					...response?.data?.data,
					socials: JSON.parse(response.data.data.socials),
				})
			}
			setLoading(false)
			setDraftLoading(false)
		} catch (e) {
			messageHandler(e?.response?.data?.data?.message || 'Something went wrong')
			setLoading(false)
			setDraftLoading(false)
			console.log('Error while creating link in bio: ', e)
		}
	}

	const updateLinkInBio = async (draft) => {
		try {
			const data = watch()
			const mappedLinks = data?.links.map((item) => {
				return {
					id: item?.id,
					destinationUrl: item?.destinationUrl,
					isActive: draft ? false : isActive ? item?.isActive : true,
					btnLabel: item?.btnLabel,
					slashTag: item?.slashTag,
					brandedDomain: item?.brandedDomain,
				}
			})
			const { socials, goal, category, pageLink, ...rest } = data

			const updatedPayload = {
				...rest,
				pageLink: convertToURLFriendly(pageLink),
				links: mappedLinks,
				socials: JSON.stringify(socials?.filter((social) => social?.isValid)),
				...(draft ? { isActive: false } : { isActive: true }),
			}

			const response = await LinkInBioApi.updateLinkInBioPage(
				data?.id,
				updatedPayload
			)

			// Check if response is successful
			if (response?.data?.success) {
				reset({
					...response.data.result,
					socials: JSON.parse(response.data.result.socials),
				})
				setLoading(false)
				setDraftLoading(false)
			} else {
				// Handle the case where the response indicates failure
				const errorMessage = response.data.message || 'Something went wrong'
				messageHandler(errorMessage)
				setLoading(false)
				setDraftLoading(false)
				console.error('API response error:', response.data)
			}
		} catch (e) {
			// This catch block is for unexpected errors
			const errorMessage =
				e?.response?.data?.data?.message || 'Something went wrong'
			messageHandler(errorMessage)
			setLoading(false)
			setDraftLoading(false)
			console.error('Error while updating link in bio:', e)
		}
	}

	const submitHandler = async (draft = false) => {
		draft ? setDraftLoading(true) : setLoading(true)
		if (isEdit) {
			updateLinkInBio(draft)
		} else {
			await newLinkInBio(draft)
		}
	}

	const isNotUpgraded = quota?.linkInBioQrCode === 0
	const bioPageLimit = !isEdit && quota?.linkInBioPages === 0

	const toggle = (val) => {
		if (!isPublished || isNotUpgraded) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	const previewToggle = (val) => {
		if (!isPublished) {
			setPreviewTooltip(!previewTooltip)
		}
	}

	const bioPagetoggle = (val) => {
		if (bioPageLimit) {
			setBioPageTooltipOpen(!bioPageTooltipOpen)
		}
	}

	useEffect(() => {
		if (isEdit) {
			setIsPublished(isEdit)
		}
	}, [isEdit])

	return (
		<>
			<div className="admin-header border-bottom px-lg-0 px-6 mb-5 pb-lg-10 pb-5 pt-lg-10 pt-5">
				<div className="admin-header__actions">
					<div className="url-actions" style={{ gap: '12px' }}>
						<i className="far fa-link fs-6 text-gray-400"></i>
						<Link
							to={`https://${pageDomain}/${convertToURLFriendly(pageLink)}`}
							target="_blank"
							className="text-info fs-4 fw-semibold mb-0 text-wrap-oneline pe-5"
						>{`${pageDomain}/${convertToURLFriendly(pageLink)}`}</Link>
					</div>
				</div>
				<div className="publishing-actions d-flex gap-5">
					{/* Edit */}
					<button
						type="button"
						onClick={() => setToggleEditPageModal(true)}
						className="btn btn-icon rounded-1 border bg-light-info mobile-hide"
					>
						<span>
							<i className="fa-regular fa-pen-to-square text-info fs-3"></i>
						</span>
					</button>

					{/* Copy */}
					<button
						type="button"
						onClick={copyHandler}
						className="btn btn-icon rounded-1 bg-light-info mobile-hide"
					>
						{isCopied ? (
							<i class="fa-regular fa-check fs-2 text-green-800 cursor-pointer"></i>
						) : (
							<i className="fa-regular fa-clone text-info fs-3"></i>
						)}
					</button>

					{/* Qr Model */}
					<div onMouseOver={toggle} onMouseLeave={toggle} id="qrCode">
						<button
							disabled={!isPublished || isNotUpgraded}
							onClick={() =>
								isPublished && !isNotUpgraded && setToggleQrModal(true)
							}
							type="button"
							className="btn btn-icon rounded-1 border bg-light-info mobile-hide"
						>
							<span>
								<i className="fa-regular fa-qrcode text-info fs-3"></i>
							</span>
						</button>
					</div>
					<Tooltip
						placement={'top'}
						isOpen={tooltipOpen}
						target={'qrCode'}
						toggle={toggle}
					>
						{!isPublished
							? `Publish changes to see the QR`
							: `Please Upgrade your Plan`}
					</Tooltip>
					{console.log(isPublished, 'isPublished', isActive, bioPageForm)}
					{/* View Page */}
					<div
						onMouseOver={previewToggle}
						onMouseLeave={previewToggle}
						id="previewIcon"
					>
						<button
							type="button"
							disabled={!(isPublished && isActive)}
							onClick={() => {
								if (isPublished && isActive) {
									const link = `https://${pageDomain}/${convertToURLFriendly(
										pageLink
									)}`
									window.open(link, '_blank')
								}
							}}
							className="btn btn-icon rounded-1 border bg-light-info mobile-hide"
						>
							<span>
								<i className="fa-regular fa-eye text-info fs-3"></i>
							</span>
						</button>
					</div>
					<Tooltip
						placement={'top'}
						isOpen={previewTooltip}
						target={'previewIcon'}
						toggle={previewToggle}
					>
						{`Publish changes to see the QR`}
					</Tooltip>
					{!isActive && (
						<div
							onMouseOver={bioPagetoggle}
							onMouseLeave={bioPagetoggle}
							id="biopageLimit"
						>
							<button
								disabled={draftLoading || bioPageLimit}
								type="button"
								onClick={() => !bioPageLimit && submitHandler(true)}
								className="btn btn-icon me-0 rounded-1 min-w-150px btn-secondary mobile-hide"
							>
								{draftLoading ? <Spinner /> : 'Save as Draft'}
							</button>
						</div>
					)}

					{/* Publish changes */}
					<div
						onMouseOver={bioPagetoggle}
						onMouseLeave={bioPagetoggle}
						id="biopageLimit"
					>
						<button
							disabled={loading || bioPageLimit}
							type="button"
							onClick={() => !bioPageLimit && submitHandler()}
							className="btn btn-icon me-0 rounded-1 min-w-200px btn-info mobile-hide"
						>
							{loading ? <Spinner /> : 'Publish Changes'}
						</button>
					</div>
					<Tooltip
						placement={'top'}
						isOpen={bioPageTooltipOpen}
						target={'biopageLimit'}
						toggle={bioPagetoggle}
					>
						{`Your Monthly Bio Page Limit Reached. Please Upgrade your Plan`}
					</Tooltip>

					<button
						type="button"
						onClick={copyHandler}
						className="btn btn-icon rounded-1 bg-light-info desktop-hide"
					>
						{loading ? (
							<Spinner />
						) : (
							<i class="fa-regular fa-floppy-disk text-info fs-3"></i>
						)}
					</button>
				</div>
			</div>
			<div className="d-flex justify-content-end mb-3">
				{message ? (
					<span className="error-text text-danger">{message}</span>
				) : null}
			</div>

			{toggleEditPageModal ? (
				<CreatePageModal
					valuesHandler={editPageDataHandler}
					edittedData={{
						pageLink,
						pageDomain,
						...(isEdit && id ? { id } : {}),
					}}
					toggleHandler={editPageModalHandler}
				/>
			) : null}

			{toggleQrModal ? (
				<QrCreatedPage
					toggleHandler={() => setToggleQrModal(false)}
					tabsHandler={tabsHandler}
				/>
			) : null}
			<div className="position-fixed d-flex justify-content-between mobbottommenu">
				<button
					type="button"
					onClick={() => setToggleEditPageModal(true)}
					className="btn btn-icon rounded-1 border bg-light-info"
				>
					<span>
						<i className="fa-regular fa-pen-to-square text-info fs-3"></i>
					</span>
				</button>
				<button
					type="button"
					onClick={copyHandler}
					className="btn btn-icon rounded-1 bg-light-info"
				>
					{isCopied ? (
						<i class="fa-regular fa-check fs-2 text-green-800 cursor-pointer"></i>
					) : (
						<i className="fa-regular fa-clone text-info fs-3"></i>
					)}
				</button>
				<button
					onClick={() => setToggleQrModal(true)}
					type="button"
					className="btn btn-icon rounded-1 border bg-light-info"
				>
					<span>
						<i className="fa-regular fa-qrcode text-info fs-3"></i>
					</span>
				</button>
			</div>
			<button
				type="button"
				onClick={() => {
					const link = `https://${pageDomain}/${convertToURLFriendly(pageLink)}`
					window.open(link, '_blank')
				}}
				className="btn rounded-1 border border-info rounded-pill shadow-sm bg-light-info text-dark fw-bold mobile-add-link-btn position-fixed desktop-hide"
				style={{ bottom: '80px' }}
			>
				<i className="fa-regular fa-eye fs-3"></i>{' '}
				<span className="fw-bold">Preview</span>
			</button>
		</>
	)
}

export default LinkInBioHeader

import React from 'react'
import Slider from 'react-slick'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import anaylytics from '../../assets/media/icons/analytics.svg'
import arrow from '../../assets/media/icons/arrow.svg'
import emailIcon from '../../assets/media/icons/email-icon.svg'
import ingegrationIcon from '../../assets/media/icons/ingegration-icon.svg'
import qrCode from '../../assets/media/icons/qr-code.svg'
import smsEmailIcon from '../../assets/media/icons/sms-email-icon.svg'
import tick from '../../assets/media/icons/tick.svg'
import urlIcon from '../../assets/media/icons/url-icon.svg'
import whatsapp from '../../assets/media/icons/whatsapp.svg'
import url from '../../assets/media/icons/url.svg'
import customDomain from '../../assets/media/icons/custom-domain.svg'
import loginEmptyState from '../../assets/media/sign-in-empty-state.png'
import whiteLogo from '../../assets/logos/divsly-w-icon.svg'
import colorlogo from '../../assets/logos/divsly-icon-c.svg'
import 'react-toastify/dist/ReactToastify.css'
import elemntDot from '../../assets/media/element-dot.png'
import elemntDots from '../../assets/media/element-dots.png'
import elementCross from '../../assets/media/element-cross.png'
import elemntSquare from '../../assets/media/element-squre.png'
import elemntLines from '../../assets/media/element-lines.png'
import elemntCurve from '../../assets/media/element-curve.png'
import TermsImage from '../../assets/auth/acceptTerms.svg'
import TermsBackground from '../../assets/auth/background.jpg'

const settings = {
	dots: true,
	infinite: true,
	autoplay: true,
	speed: 1000,
	arrows: false,
	autoplaySpeed: 3000,
	slidesToShow: 1,
	slidesToScroll: 1,
}

const SignupPageWrapper = ({ children }) => {
	return (
		<div
			className="d-flex justify-content-center align-items-center w-100"
			style={{ height: '100vh' }}
		>
			<div className="floating-icons">
				<img src={anaylytics} alt="floating-icons" />
				<img src={arrow} alt="floating-icons" />
				<img src={emailIcon} alt="floating-icons" />
				<img src={ingegrationIcon} alt="floating-icons" />
				<img src={qrCode} alt="floating-icons" />
				<img src={smsEmailIcon} alt="floating-icons" />
				<img src={tick} alt="floating-icons" />
				<img src={urlIcon} alt="floating-icons" />
				<img src={whatsapp} alt="floating-icons" />
				<img src={url} alt="floating-icons" />
				<img src={customDomain} alt="floating-icons" />
			</div>
			<div className="d-flex flex-column h-fit-content m-lg-auto login_main_container">
				<Container
					fluid
					className="bg-white overflow-hidden px-0 d-flex flex-column flex-column-fluid justify-content-center"
					style={{ borderRadius: '25px' }}
				>
					<Row className="row w-100 h-100 mx-auto align-items-center">
						<Col
							md={6}
							className="text-center px-5 h-100 d-flex justify-content-center align-items-center"
							style={{
								backgroundImage: `url(${TermsBackground})`,
								borderRadius: '25px 0px 0px 25px',
								backgroundPosition: 'center',
								backgroundSize: 'cover',
							}}
						>
							<div
								className="h-100 d-flex justify-content center align-items-center px-5 ps-7"
								style={{ minHeight: '729px' }}
							>
								<img
									src={TermsImage}
									alt="Left Side Image"
									className="img-fluid"
								/>
							</div>
						</Col>
						<Col md={6}>{children}</Col>
					</Row>
				</Container>
			</div>
		</div>
	)
}

export default SignupPageWrapper

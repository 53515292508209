import SignInMethod from './profile/signInMethod'
import User from './profile/user'
import ProfileDetails from './profile/details'

const Profile = () => {
	return (
		<>
			<User />
			<ProfileDetails />
			<SignInMethod />
		</>
	)
}
export default Profile

import { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import WhatsappCampaignAPI from '../../services/http/whatsappCampaign'

export const WhatsappCampaignContext = createContext(null)

const WhatsappCampaignContextProvider = (props) => {
	const [loading, setLoading] = useState(true)
	const [whatsappCampaigns, setWhatsappCampaigns] = useState([])
	const [notLinksScreen, setNotLinksScreen] = useState('')
	const [search, setSearch] = useState()
	const [status, setStatus] = useState('All Statuses')
	const [filters, setFilters] = useState({})
	const [currentCampaign, setCurrentCampaign] = useState({})

	const getWhatsappCampaigns = async (params) => {
		setLoading(true)
		WhatsappCampaignAPI.getAllWhatsappCampaigns(params)
			.then(({ data }) => {
				setWhatsappCampaigns(data.resultData)
				data.resultData.length < 1 && setNotLinksScreen('notFound')
			})
			.catch((error) => {
				console.log('Error while fetching shortlinks', error)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		getWhatsappCampaigns()
	}, [])

	return (
		<WhatsappCampaignContext.Provider
			value={{
				whatsappCampaigns,
				setWhatsappCampaigns,
				getWhatsappCampaigns,
				loading,
				setLoading,
				notLinksScreen,
				setNotLinksScreen,
				search,
				setSearch,
				filters,
				setFilters,
				status,
				setStatus,
				currentCampaign,
				setCurrentCampaign,
			}}
		>
			{props.children}
		</WhatsappCampaignContext.Provider>
	)
}

WhatsappCampaignContextProvider.propTypes = {
	children: PropTypes.element,
}

export default WhatsappCampaignContextProvider

import React, { useEffect, useState } from 'react'

const FloatingBtn = ({ type, items }) => {
	const [modal, setModal] = useState(false)
	const handleCta = () => {
		setModal(false)
	}

	return (
		<>
			{/* Floating Button */}
			<button
				onClick={() => setModal(true)}
				className={`d-flex justify-content-center align-items-center p-1 rounded-circle position-absolute border-0`}
				style={{
					background: '#e91e63',
					zIndex: 2,
					bottom: '20px',
					right: '1.25rem',
					height: '50px',
					width: '50px',
					boxShadow:
						'0px 10px 14.1px 0.9px rgba(0, 0, 0, 0.24), 0px 4px 19.6px 0.4px rgba(0, 0, 0, 0.16)',
				}}
			>
				<i
					className={`fa-solid ${
						type === 'vCard' ? 'fa-user-plus' : 'fa-bars'
					} text-white`}
				></i>
			</button>

			{/* Modal */}
			{modal && (
				<div
					className="position-absolute row align-items-center"
					tabIndex="-1"
					style={{
						padding: '0px 0.25rem',
						position: 'absolute',
						height: '100%',
						width: '100%',
						maxWidth: '285px',
						bottom: '-115px',
						right: '23px',
						zIndex: 10,
					}}
				>
					<div
						style={{
							width: '100%',
							maxWidth: '285px',
							float: 'left',
							display: 'inline-block',
							textAlign: 'left',
							padding: '25px',
							background: '#fff',
							borderRadius: '10px',
							boxShadow:
								'0px 4px 7.36px 0.64px rgba(0, 0, 0, 0.24), 0px 1px 7.68px 0.32px rgba(0, 0, 0, 0.16)',
						}}
						className="modal-save-contact"
					>
						<div className="">
							<div className="position-relative">
								<h5 style={{ color: '#e91e63' }} className="">
									{type === 'vCard' ? 'Save Contact Data' : 'Menu'}
								</h5>
								<p>
									{type === 'vCard' ? 'Save contact data to your vCard' : ''}
								</p>
								<button
									style={{ top: 0, right: 0 }}
									className="position-absolute btn-close"
									onClick={handleCta}
								></button>
							</div>
							{/* actions */}
							<div className="pt-3">
								{items?.length > 0 &&
									items?.map((item, index) => (
										<a
											href={item?.href}
											key={index}
											style={{
												padding: '0.5rem',
											}}
											onClick={item?.onClick}
											className="w-100 d-flex gap-5 mb-2 cursor-pointer text-dark align-items-center border-0 bg-white bg-hover-light"
										>
											<i className={`fs-2 ${item?.icon} text-dark`}></i>
											<span>{item?.text}</span>
										</a>
									))}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default FloatingBtn

import InstagramIcon from '../../assets/media/brand-logos/instagram-2-1.svg'
import FacebookIcon from '../../assets/media/brand-logos/facebook-5.svg'
import GoogleIcon from '../../assets/media/brand-logos/google-icon.svg'
import TwitterIcon from '../../assets/media/brand-logos/twitter.svg'
import SlackIcon from '../../assets/media/brand-logos/slack-icon.svg'
import YoutubeIcon from '../../assets/media/brand-logos/youtube-3.svg'
import LinkedInIcon from '../../assets/media/brand-logos/linkedin-1.svg'
import convertToK from '../../utils/convertToK'
import { Spinner } from 'reactstrap'
import AnalyticsNotFound from '../common/emptyScreens/analyticsNotFound'

const LogoMapper = {
	instagram: { icon: InstagramIcon, name: 'Instagram' },
	linkedIn: { icon: LinkedInIcon, name: 'Linked In' },
	facebook: { icon: FacebookIcon, name: 'Facebook' },
	google: { icon: GoogleIcon, name: 'Google' },
	twitter: { icon: TwitterIcon, name: 'Twitter' },
	slack: { icon: SlackIcon, name: 'Slack' },
	youtube: { icon: YoutubeIcon, name: 'Youtube' },
}

const SourceChart = ({ referrer = [], loading }) => {
	return (
		<div class="col-lg-6">
			<div class="card card-flush h-xl-100" style={{ borderRadius: '15px' }}>
				<div class="card-header min-h-lg-70px min-h-50px px-5 px-lg-10">
					<div class="card-title flex-column">
						<h3 class="fw-bold mb-1">Clicks + Scan by Source</h3>
					</div>

					<div class="card-toolbar"></div>
				</div>

				<div class="card-body border-top card-body d-flex justify-content-between flex-column pt-5">
					{loading ? (
						<Spinner />
					) : referrer?.length > 0 ? (
						referrer?.map((item) => {
							const refer = LogoMapper[item?.referrer]
							return (
								<>
									<div class="d-flex flex-stack">
										{refer?.icon ? (
											<img
												src={refer?.icon}
												class="me-4 w-30px"
												style={{ borderRadius: '4px' }}
												alt={item?.referrer}
											/>
										) : null}

										<div class="d-flex align-items-center flex-stack flex-wrap flex-row-fluid d-grid gap-2">
											<div class="me-5">
												<span class="text-gray-800 fw-bold text-hover-primary fs-6">
													{refer?.name || 'Others'}
												</span>
											</div>

											<div class="d-flex align-items-center">
												<span class="text-gray-800 fw-bold fs-4 me-3">
													{convertToK(item?._count?.referrer || 0)}
												</span>
											</div>
										</div>
									</div>
									<div class="separator separator-dashed my-3"></div>
								</>
							)
						})
					) : (
						<AnalyticsNotFound />
					)}
				</div>
			</div>
		</div>
	)
}

export default SourceChart

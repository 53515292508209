import Background from './background'
import Buttons from './buttons'
import Fonts from './fonts'
import Layout from './layout'
import Logo from './logo'
import ProfileInfo from './profileInfo'
import Socials from './socials'
import Theme from './theme'

const Customization = () => {
	return (
		<div class="tab-pane mb-20 pb-1 mb-lg-0" role="tabpanel">
			<ProfileInfo />
			<Layout />
			<Theme />
			<Background />
			<Buttons />
			<Fonts />
			<Socials />
			<Logo />
		</div>
	)
}

export default Customization

import { useEffect, useState } from 'react'
import SharedApi from '../services/http/shared'
import Layout0 from '../components/link-in-bio/create-page/layout0'
import Layout1 from '../components/link-in-bio/create-page/layout1'
import Layout2 from '../components/link-in-bio/create-page/layout2'
import Layout3 from '../components/link-in-bio/create-page/layout3'
import Layout4 from '../components/link-in-bio/create-page/layout4'
import { useCookies } from 'react-cookie'
import MainPreview from '../components/qr-codes/create-qr-code/qr-code-previews/mainPreview'

const LinkInBioPageMapper = ({ values }) => {
	const { template } = values
	const TemplatesMapper = {
		0: <Layout0 values={values} />,
		1: <Layout1 values={values} />,
		2: <Layout2 values={values} />,
		3: <Layout3 values={values} />,
		4: <Layout4 values={values} />,
	}
	return TemplatesMapper[template] || null
}

const Page = () => {
	const [linkInBioPage, setLinkInBioPage] = useState(null)
	const [specialQr, setSpecialQr] = useState(null)
	const [cookies, setCookie] = useCookies(['shortLink'])
	useEffect(() => {
		const redirectToNotFoundPage = () => {
			window.open('https://divsly.com/not-found-page', '_self')
		}

		const handleResponse = (data) => {
			if (!data.isActive || !data.success || !data.data) {
				redirectToNotFoundPage()
			} else {
				const { type, destinationUrl } = data.data
				if (type === 'linkInBio' && !destinationUrl) {
					setLinkInBioPage(data.data)
				} else if (destinationUrl) {
					window.open(destinationUrl, '_self')
				} else if (type === 'qr') {
					const fieldData = JSON.parse(data?.data?.fieldData)
					let updatedData = { ...data?.data, ...fieldData }
					console.log(updatedData, 'updbdsh')
					setSpecialQr(updatedData)
				} else {
					redirectToNotFoundPage()
				}
			}
		}

		const fetchDestinationUrl = async () => {
			try {
				const url = cookies.shortLink ? cookies.shortLink : window.location.href
				const response = await SharedApi.getReidrectUrl({
					shortlink: url,
					referrer: document?.referrer,
				})
				const { data } = response
				handleResponse(data)
			} catch (error) {
				console.error('Error while fetching redirect URL: ', error)
			}
		}

		fetchDestinationUrl()
	}, [])

	return linkInBioPage ? (
		<div style={{ height: '100vh' }}>
			<LinkInBioPageMapper values={linkInBioPage} />
		</div>
	) : specialQr ? (
		<MainPreview formData={specialQr} />
	) : null
}

export default Page

import httpMethods from '..'

const EmailTemplateAPI = {
	createEmailTemplate: async (token, values) => {
		return httpMethods
			.post(`/email-templates`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	createGalleryEmailTemplate: async (token, values) => {
		return httpMethods
			.post(`/email-templates/gallery`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getAllEmailTemplates: async (token, params) => {
		return httpMethods
			.get(`/email-templates`, {
				params,
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	updateEmailTemplates: async (token, id, values) => {
		return httpMethods
			.put(`/email-templates/${id}`, values, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	deleteEmailTemplates: async (token, id) => {
		return httpMethods
			.delete(`/email-templates/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
	},

	getEmailTemplateById: async (token, id) => {
		return httpMethods
			.get(`/email-templates/${id}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},

	fileUpload: async (token, body) => {
		return httpMethods
			.post(`/email-templates/file/upload`, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res)
			.catch((error) => {
				throw error
			})
	},
}

export default EmailTemplateAPI

import React, { useContext, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { Col, Row, Spinner, Tooltip } from 'reactstrap'
import { AuthContxt } from '../../../store/authContxt'

const AddNewButtons = ({ loading }) => {
	const { quota } = useContext(AuthContxt)
	const isLimitReached = quota?.shortlink === 0 || false

	const [tooltipOpen, setTooltipOpen] = useState(false)

	const toggle = () => {
		if (isLimitReached) {
			setTooltipOpen(!tooltipOpen)
		}
	}

	return (
		<Row>
			<Col className="ms-auto text-end">
				<div onMouseOver={toggle} onMouseLeave={toggle} id="shortlinkForm">
					<Button
						disabled={loading || isLimitReached}
						color="primary"
						className=" btn btn-info w-100"
						style={{ height: '55px', borderRadius: '5px' }}
						type="submit"
					>
						{loading ? <Spinner /> : 'Create Short Link'}
					</Button>
				</div>
				<Tooltip
					placement={'top'}
					isOpen={tooltipOpen}
					target={'shortlinkForm'}
					toggle={toggle}
				>
					{`Your Monthly Shortlink Limit Reached. Please Upgrade your Plan`}
				</Tooltip>
			</Col>
		</Row>
	)
}

export default AddNewButtons

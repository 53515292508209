import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'

const BarcodeForm = ({ formData, setFormData }) => {
	const handleInputChange = (e) => {
		const { id, value } = e.target
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}))
	}

	return (
		<Form>
			{/* GTIN Field */}
			<FormGroup>
				<Label
					className="form-label fw-bold fs-5 mb-2 mt-3"
					for="globalTradeItemNumber"
				>
					Global Trade Item Number (01):
				</Label>
				<Input
					type="text"
					id="globalTradeItemNumber"
					className="h-50px mb-6"
					value={formData?.globalTradeItemNumber || ''}
					onChange={handleInputChange}
					placeholder="Enter GSTIN"
				/>
			</FormGroup>

			{/* Destination URL Field */}
			<FormGroup>
				<Label className="form-label fw-bold fs-5 mb-2" for="destinationUrl">
					Destination URL:
				</Label>
				<div className="position-relative">
					<Input
						type="url"
						id="destinationUrl"
						className="h-50px"
						value={formData?.destinationUrl || ''}
						onChange={handleInputChange}
						placeholder="Enter Destination URL"
					/>
					{formData?.destinationUrl && (
						<i
							className={`fa ${!formData?.title ? 'fa-times' : 'fa-check'}`}
							style={{
								position: 'absolute',
								right: '10px',
								top: '50%',
								transform: 'translateY(-50%)',
								fontSize: '16px',
								color: !formData?.title ? 'red' : 'green',
							}}
						></i>
					)}
				</div>
			</FormGroup>
		</Form>
	)
}

export default BarcodeForm

import { useEffect, useState } from 'react'
import SharedApi from '../../../services/http/shared'
import Analytics from '../../common/analytics'

const ShortlinkAnalytics = ({ shortlink }) => {
	const [loading, setLoading] = useState(false)
	const [analytics, setAnalytics] = useState({})

	const fetchAnalytics = (
		startDate = new Date().setDate(new Date().getDate() - 30),
		endDate = new Date()
	) => {
		setLoading(true)
		const auth_token = localStorage.getItem('auth_token')
		SharedApi.getAnalytics(auth_token, {
			shortlink,
			startDate,
			endDate,
		})
			.then(({ data }) => {
				setAnalytics(data?.resultData)
			})
			.catch((error) => {
				setLoading(false)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		fetchAnalytics()
	}, [])

	return (
		<Analytics
			shortlink={shortlink}
			type={'shortlink'}
			loading={loading}
			analytics={analytics}
			analyticsHandler={fetchAnalytics}
		/>
	)
}

export default ShortlinkAnalytics

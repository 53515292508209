export function hexToRgb(hex) {
	let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
	hex = hex?.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

	let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
			}
		: null
}

export function isColorDark(hex) {
	const rgb = hexToRgb(hex)
	if (!rgb) return false

	const brightness = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255

	return brightness < 0.5
}

import React, { useEffect, useState } from 'react'
import _capitalize from 'lodash/capitalize'
import {
	Form,
	Input,
	InputGroup,
	FormGroup,
	Label,
	FormFeedback,
} from 'reactstrap'
import IntegrationsAPI from '../../services/http/integration'
import PropTypes from 'prop-types'

const Sender = ({ providerId, onSave, onCancel }) => {
	const [selectedProviderId, setSelectedProviderId] = useState()

	const [integrations, setIntegrations] = useState([])
	const [hasError, setHasError] = useState(false)

	const handleSelectedProviderChange = (e) => {
		setHasError(false)
		setSelectedProviderId(+e.target.value)
	}

	const loadWhatsappIntegrations = async () => {
		const response = await IntegrationsAPI.getAllWhatsappIntegrations()
		setIntegrations(response.data.integrations)
	}

	const handleSave = () => {
		if (selectedProviderId === undefined) {
			setHasError(true)
		} else {
			onSave(
				integrations.find(
					(integration) => integration.id === selectedProviderId
				)
			)
		}
	}

	useEffect(() => {
		loadWhatsappIntegrations()
	}, [])

	useEffect(() => {
		setSelectedProviderId(providerId)
	}, [providerId])

	return (
		<div>
			<Form>
				<FormGroup>
					<Label for="selectSender">Select Sender</Label>
					<InputGroup>
						<Input
							type="select"
							name="selectSender"
							id="selectSender"
							invalid={hasError}
							value={selectedProviderId}
							onChange={handleSelectedProviderChange}
						>
							<option value="">Choose...</option>
							{integrations?.map((integration) => (
								<option
									key={`whatsapp-integration-${integration.id}`}
									value={integration.id}
								>
									{`${_capitalize(integration.name)}: ${integration.credentials.fromNumber}`}
								</option>
							))}
						</Input>
						<FormFeedback>Please select a sender</FormFeedback>
					</InputGroup>
					<div className="d-flex justify-content-end mt-3">
						<button
							type="button"
							className="btn text-nowrap rounded-1 btn-secondary"
							onClick={onCancel}
						>
							Cancel
						</button>
						<button
							type="button"
							className="btn text-nowrap rounded-1 btn-info text-white"
							color="danger"
							onClick={handleSave}
							style={{ marginLeft: '10px' }}
						>
							Save
						</button>
					</div>
				</FormGroup>
			</Form>
		</div>
	)
}

Sender.propTypes = {
	providerId: PropTypes.number,
	onSave: PropTypes.func,
	onCancel: PropTypes.func,
}

export default Sender

import { useState, useEffect, useContext, useCallback } from 'react'
import { AuthContxt } from '../store/authContxt'
import { slides } from '../pages/onboarding/slidesData'

const useOnboarding = () => {
	const { profile } = useContext(AuthContxt)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [activeSlides, setActiveSlides] = useState([])

	const checkTourStatus = useCallback(() => {
		const sessionTourState = JSON.parse(
			sessionStorage.getItem('tourState') || '{}'
		)
		const userEvents = profile?.userEvents || {}

		// Determine active slides based on the updated state
		const newActiveSlides = slides.filter(
			(slide) =>
				!sessionTourState[slide.key] && !userEvents[`${slide.key}_created`]
		)

		// Update modal state based on the active slides
		setActiveSlides(newActiveSlides)
		setIsModalOpen(newActiveSlides.length > 0)
	}, [profile])

	useEffect(() => {
		if (profile) {
			checkTourStatus() // Check tour status when the profile updates
		}
	}, [profile, checkTourStatus])

	const handleOnboardingComplete = useCallback(() => {
		console.log('Onboarding completed!')
		setIsModalOpen(false) // Ensure modal closes when complete
	}, [])

	const toggleModal = useCallback(() => {
		setIsModalOpen((prev) => !prev) // Toggle modal visibility
	}, [])

	return {
		isModalOpen,
		setIsModalOpen, // Expose setIsModalOpen for external control
		handleOnboardingComplete,
		toggleModal,
		activeSlides, // Expose activeSlides
	}
}

export default useOnboarding

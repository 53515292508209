import React, { useState } from 'react'
import axios from 'axios'
import { Pencil } from 'lucide-react'

const LogoSelection = ({ newImagesAllowed = 3, handleDefaultLogos }) => {
	const [uploadedImages, setUploadedImages] = useState([])
	const [uploadStatus, setUploadStatus] = useState({})
	const [isUpdating, setIsUpdating] = useState(false)
	const [updateIndex, setUpdateIndex] = useState(null)
	const [imagePreview, setImagePreview] = useState(null)

	const handleFileUpload = async (file) => {
		const token = localStorage?.getItem('auth_token')
		const formData = new FormData()
		formData.append('qrCode', file)

		try {
			setUploadStatus((prev) => ({ ...prev, new: 'Uploading new image...' }))
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/upload-qr`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			const newImageUrl = response.data.qrCodes
			setUploadedImages((prev) => [...prev, newImageUrl])
			setUploadStatus((prev) => ({ ...prev, new: 'Upload Successful!' }))
			setImagePreview(null)

			setTimeout(() => {
				setUploadStatus((prev) => {
					const newStatus = { ...prev }
					delete newStatus.new
					return newStatus
				})
			}, 3000)
		} catch (error) {
			setUploadStatus((prev) => ({ ...prev, new: 'Upload Failed.' }))
			console.error('Error uploading file:', error)
			setImagePreview(null)

			setTimeout(() => {
				setUploadStatus((prev) => {
					const newStatus = { ...prev }
					delete newStatus.new
					return newStatus
				})
			}, 3000)
		}
	}

	const handleFileUpdate = async (file, oldImagePath, index) => {
		const token = localStorage?.getItem('auth_token')
		const formData = new FormData()
		formData.append('qrCode', file)
		formData.append('oldFilePath', oldImagePath)

		try {
			setUploadStatus((prev) => ({ ...prev, [oldImagePath]: 'Updating...' }))
			const response = await axios.put(
				`${process.env.REACT_APP_API_URL}/update-qr`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'multipart/form-data',
					},
				}
			)

			const updatedQrCodes = response.data.qrCodes
			setUploadedImages((prev) => {
				const updated = [...prev]
				updated[index] = updatedQrCodes
				return updated
			})

			setUploadStatus((prev) => ({
				...prev,
				[oldImagePath]: 'Update Successful!',
			}))
			setImagePreview(null)

			setTimeout(() => {
				setUploadStatus((prev) => {
					const newStatus = { ...prev }
					delete newStatus[oldImagePath]
					return newStatus
				})
				setIsUpdating(false)
				setUpdateIndex(null)
			}, 3000)
		} catch (error) {
			setUploadStatus((prev) => ({ ...prev, [oldImagePath]: 'Update Failed.' }))
			console.error('Error updating file:', error)
			setImagePreview(null)

			setTimeout(() => {
				setUploadStatus((prev) => {
					const newStatus = { ...prev }
					delete newStatus[oldImagePath]
					return newStatus
				})
				setIsUpdating(false)
				setUpdateIndex(null)
			}, 3000)
		}
	}

	const handleImageChange = async (e) => {
		const file = e.target.files[0]
		if (!file) return

		const reader = new FileReader()
		reader.onloadend = () => {
			if (isUpdating && updateIndex !== null) {
				const updatedImages = [...uploadedImages]
				updatedImages[updateIndex] = reader.result
				setUploadedImages(updatedImages)
			} else {
				setImagePreview(reader.result)
			}
		}
		reader.readAsDataURL(file)

		if (isUpdating && updateIndex !== null) {
			const oldImagePath = uploadedImages[updateIndex]
			await handleFileUpdate(file, oldImagePath, updateIndex)
		} else {
			if (uploadedImages.length >= newImagesAllowed) {
				setUploadStatus((prev) => ({
					...prev,
					new: `Maximum ${newImagesAllowed} images allowed`,
				}))
				setImagePreview(null)
				setTimeout(() => {
					setUploadStatus((prev) => {
						const newStatus = { ...prev }
						delete newStatus.new
						return newStatus
					})
				}, 3000)
				return
			}
			await handleFileUpload(file)
		}
	}

	return (
		<>
			{uploadedImages.map((logo, index) => (
				<div
					key={`uploaded-${index}`}
					className="bg-white p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn hover:shadow-lg transition-shadow"
					style={{ position: 'relative' }}
				>
					<div
						onClick={() => handleDefaultLogos(logo)}
						className="w-full h-full d-flex justify-content-center align-items-center"
					>
						<img
							className="w-100 h-100 object-contain"
							src={index === updateIndex && imagePreview ? imagePreview : logo}
							alt={`Uploaded Logo ${index + 1}`}
						/>
					</div>
					<div
						className="position-absolute top-0 right-0 p-1 cursor-pointer"
						onClick={() => {
							setIsUpdating(true)
							setUpdateIndex(index)
							document.getElementById('file-input').click()
						}}
					>
						<Pencil
							style={{
								width: '25px',
								height: '25px',
								color: 'gray',
								backgroundColor: 'white',
								padding: '5px',
								borderRadius: '50%',
								boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
								position: 'absolute',
								top: '-5',
								left: '30',
							}}
						/>
					</div>
					{uploadStatus[logo] && (
						<div
							className="position-absolute bottom-0 start-0 w-100 text-center text-white bg-dark bg-opacity-50 rounded-bottom"
							style={{ fontSize: '0.7rem', padding: '2px 0' }}
						>
							{uploadStatus[logo]}
						</div>
					)}
				</div>
			))}

			{uploadedImages.length < newImagesAllowed && (
				<div
					className="bg-white p-3 h-100px w-100px d-flex justify-content-center align-items-center me-5 mb-5 rounded-1 btn on-hvr-border bdr-qr-btn hover:shadow-lg transition-shadow"
					style={{ position: 'relative' }}
				>
					<label
						htmlFor="file-input"
						className="w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer"
						onClick={() => {
							setIsUpdating(false)
							setUpdateIndex(null)
						}}
					>
						{imagePreview && updateIndex === null ? (
							<img
								src={imagePreview}
								alt="Preview"
								className="w-100 h-100 object-contain"
							/>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-50 h-50 text-gray-500"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth="2"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
								/>
							</svg>
						)}
					</label>
					{uploadStatus.new && (
						<div
							className="position-absolute bottom-0 start-0 w-100 text-center text-white bg-dark bg-opacity-50 rounded-bottom"
							style={{ fontSize: '0.7rem', padding: '2px 0' }}
						>
							{uploadStatus.new}
						</div>
					)}
				</div>
			)}

			<input
				id="file-input"
				type="file"
				className="d-none"
				accept="image/*"
				onChange={handleImageChange}
			/>
		</>
	)
}

export default LogoSelection

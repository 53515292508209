import { useContext, useEffect, useState } from 'react'
import { GRADIENT_DIRECTION } from './background'
import { LinkInBioContxt } from '../linkInBioContxt'

const BottomBackground = () => {
	const { bioPageForm } = useContext(LinkInBioContxt)
	const { setValue, watch } = bioPageForm
	const { bGradientDir, bGradientClr1, bGradientClr2 } = watch()

	const [backgroundColor, setBackgroundColor] = useState('#000000')

	useEffect(() => {
		// setValue(
		//   "bottomBg",
		//   `linear-gradient(${bGradientDir}deg, ${bGradientClr1}, ${bGradientClr2})`
		// );
		// setValue("bottomBgType", `gradient`);
	}, [bGradientDir, bGradientClr1, bGradientClr2, setValue])

	return (
		<>
			<div>
				<div className="separator my-3 separator-dashed"></div>
				<div className="d-flex w-100 gap-3 mobile-hide-f">
					<p className="w-200px fs-4 fw-semibold">Select Bottom Gradient</p>
					<p className="fs-4 fw-semibold">Gradient Direction</p>
				</div>
				<div className="d-flex w-100 gap-10 mobile-hide-f">
					<div className="color-picker-container d-flex">
						<div className="color-picker-1">
							<input
								className="w-40px h-40px border-0"
								type="color"
								id="colorPickerLeft"
								onChange={(e) => {
									setValue('bGradientClr1', e.target.value)
									setValue('bottomBgType', `gradient`)
									setValue(
										'bottomBg',
										`linear-gradient(${bGradientDir}deg, ${e.target.value}, ${bGradientClr2})`
									)
								}}
								value={bGradientClr1}
							/>
						</div>
						<div
							style={{
								width: '100px',
								height: '40px',
								background: `linear-gradient(${bGradientDir}deg, ${bGradientClr1}, ${bGradientClr2})`,
							}}
							className="gradient-rectangle"
						></div>
						<div className="color-picker-2">
							<input
								className="w-40px h-40px border-0"
								type="color"
								id="colorPickerRight"
								onChange={(e) => {
									setValue('bGradientClr2', e.target.value)
									setValue('bottomBgType', `gradient`)
									setValue(
										'bottomBg',
										`linear-gradient(${bGradientDir}deg, ${bGradientClr1}, ${e.target.value})`
									)
								}}
								value={bGradientClr2}
							/>
						</div>
					</div>
					<div>
						<ul
							className="direction-list"
							id="directionList"
							style={{
								listStyle: 'none',
								display: 'flex',
								flexWrap: 'wrap',
								padding: '0px',
								gap: '15px',
								margin: '10px 0px',
							}}
						>
							{GRADIENT_DIRECTION?.map((item) => {
								return (
									<li
										onClick={() => {
											setValue('bGradientDir', item)
										}}
										className="direction-list-item"
									>
										<i
											style={{ transform: `rotate(${item}deg)` }}
											className="fas fa-circle-arrow-up fs-2 direction-icon"
										></i>
									</li>
								)
							})}
						</ul>
					</div>
				</div>

				<div className="d-flex flex-column w-100 gap-6 desktop-hide-f">
					<div>
						<p className="w-200px fs-4 fw-semibold">Select Bottom Gradient</p>
						<div className="color-picker-container d-flex">
							<div className="color-picker-1">
								<input
									className="w-40px h-40px border-0"
									type="color"
									id="colorPickerLeft"
									onChange={(e) => {
										setValue('bGradientClr1', e.target.value)
									}}
									value={bGradientClr1}
								/>
							</div>
							<div
								style={{
									width: '100px',
									height: '40px',
									background: `linear-gradient(${bGradientDir}deg, ${bGradientClr1}, ${bGradientClr2})`,
								}}
								className="gradient-rectangle"
							></div>
							<div className="color-picker-2">
								<input
									className="w-40px h-40px border-0"
									type="color"
									id="colorPickerRight"
									onChange={(e) => {
										setValue('bGradientClr2', e.target.value)
									}}
									value={bGradientClr2}
								/>
							</div>
						</div>
					</div>

					<div>
						<p className="fs-4 fw-semibold">Gradient Direction</p>

						<div>
							<ul
								className="direction-list"
								id="directionList"
								style={{
									listStyle: 'none',
									display: 'flex',
									flexWrap: 'wrap',
									padding: '0px',
									gap: '15px',
									margin: '10px 0px',
								}}
							>
								{GRADIENT_DIRECTION?.map((item) => {
									return (
										<li
											onClick={() => {
												setValue('bGradientDir', item)
											}}
											className="direction-list-item"
										>
											<i
												style={{ transform: `rotate(${item}deg)` }}
												className="fas fa-circle-arrow-up fs-2 direction-icon"
											></i>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div className="separator my-3 separator-dashed"></div>
				<div className="d-flex gap-10">
					{/* <div className="mb-5">
            <p className="fw-semibold fs-4 mb-4">Preset</p>
            <div className="d-flex">
              {PRESET.map((item) => {
                return (
                  <div
                    onClick={() => {
                      setBackgroundColor(item);
                      setPreset(item);
                      setValue("bottomBg", item);
                      setValue("bottomBgType", "flatColor");
                    }}
                    className={`p-1 rounded-1 on-hvr-border bdr-qr-btn me-5 ${
                      preset === item ? "selected" : ""
                    }`}
                  >
                    <div
                      className="p-3 rounded-1 w-40px h-40px"
                      style={{ backgroundColor: item }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div> */}
					<div className="d-flex gap-10">
						<div>
							<p className="fw-semibold fs-4">Bottom Background Color</p>
							<div className=" bdr-qr-btn p-1 color-picker-wrapper">
								<input
									className="color-picker h-40px w-40px"
									type="color"
									value={backgroundColor}
									onChange={(e) => {
										setValue('bottomBg', e.target.value)
										setValue('bottomBgType', 'flatColor')
										setBackgroundColor(e.target.value)
									}}
								/>
								<input
									className="hex-input form-control h-40px rounded-0"
									type="text"
									maxLength="7"
									value={backgroundColor}
									placeholder="#ffffff"
									onChange={(e) => {
										let value = e.target.value
										if (value.length === 6 && !value.startsWith('#')) {
											value = `#${value}`
										}
										if (value.length <= 7) {
											setValue('bottomBg', value)
											setValue('bottomBgType', 'flatColor')
											setBackgroundColor(value)
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default BottomBackground

import React from 'react'

const IconFrame5 = ({
	primary,
	secondary,
	fontSize,
	text,
	textColor,
	type,
	url,
	width,
	height,
	minFontSize,
	maxFontSize,
}) => {
	return (
		<>
			<svg
				width={width || 1392}
				height={height || 1900}
				viewBox="0 0 1392 1900"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_175_21)">
					{/* Background rectangle with rounded corners */}
					<rect
						width="1392"
						height="1900"
						rx="115"
						fill={primary || '#0E1B21'}
					/>

					{/* Bottom shape */}
					<path
						d="M0 1474H566L696 1340.5L825.5 1474H1392V1785C1392 1848.51 1340.51 1900 1277 1900H115C51.4873 1900 0 1848.51 0 1785V1474Z"
						fill={secondary || 'white'}
					/>

					{/* Text "SCAN ME" */}
					<text
						fill={textColor || 'black'}
						xmlSpace="preserve"
						style={{ whiteSpace: 'pre' }}
						fontFamily="Arial"
						fontSize={fontSize || 223}
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="50%" textAnchor="middle" y="1764.31">
							{text}
						</tspan>
					</text>

					{/* Image element */}
				</g>

				{/* Clip path definition */}
				<defs>
					<clipPath id="clip0_175_21">
						<rect width="1392" height="1900" fill="white" />
					</clipPath>
				</defs>

				<image href={url} x="124" y="124" width="1144" height="1144" />
			</svg>
		</>
	)
}

export default IconFrame5

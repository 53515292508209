import { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const CROP_AREA_ASPECT = 4 / 4

const LogoCropper = ({
	image,
	toggleHandler,
	cropHandler,
	cancelHandler,
	backgroundHandler,
	logoBackground,
}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)

	const [croppedArea, setCroppedArea] = useState(null)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const onCropComplete = (cropArea, cropAreaPixels) => {
		setCroppedArea(cropArea)
		setCroppedAreaPixels(cropAreaPixels)
	}

	return (
		<Modal
			className="qrmobileviewmodal modal-dialog-centered"
			isOpen={true}
			toggle={cancelHandler}
		>
			<ModalHeader>
				<div className="d-flex justify-content-between align-items-center w-100">
					<h5 className="fw-bold mb-0 fs-2x">Edit Logo</h5>
					<i
						onClick={cancelHandler}
						className="fal fa-times fs-2 cursor-pointer"
					/>
				</div>
			</ModalHeader>
			<ModalBody>
				<div
					style={{
						width: '100%',
						height: '300px',
						position: 'relative',
						background: '#333',
					}}
				>
					<Cropper
						image={image}
						crop={crop}
						zoom={zoom}
						aspect={CROP_AREA_ASPECT}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<button
					className="btn btn-info rounded-1 w-lg-100"
					type="button"
					onClick={() => {
						cropHandler(croppedArea, croppedAreaPixels)
					}}
				>
					Crop
				</button>
				<div className="d-flex justify-content-between mt-5 w-100">
					<div className="d-flex align-items-center me-2">
						<div className="form-check form-check-custom form-check-info form-check-solid form-check-primary me-4">
							<input
								className="form-check-input activate-checked"
								checked={logoBackground}
								type="checkbox"
								onChange={() => {}}
								onClick={(e) => backgroundHandler(!logoBackground)}
							/>
						</div>
						<div>
							<p
								className="d-flex align-items-center mb-0 fs-5 flex-wrap"
								style={{ whiteSpace: 'nowrap' }}
							>
								{'Add White Background'}
							</p>
						</div>
					</div>
					<button
						className="btn btn-text text-info rounded-1 p-0 ps-5"
						type="button"
						onClick={() => cropHandler(null, null)}
					>
						Skip Cropping
					</button>
				</div>
			</ModalFooter>
		</Modal>
	)
}

export default LogoCropper

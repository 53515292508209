import { useState } from 'react'
import LinkForm from './linkForm'
import Links from './links'

const Index = () => {
	const [edittedLink, setEdittedLink] = useState(null)

	return (
		<>
			<div class="tab-pane mb-20 pb-6 mb-lg-0" role="tabpanel">
				<LinkForm edittedLink={edittedLink} setEdittedLink={setEdittedLink} />
				<Links edittedLinkHandler={(value) => setEdittedLink(value)} />
			</div>
		</>
	)
}

export default Index

import QrCodes from './qrCodes'
import Sidebar from '../common/sidebar'
import React, { useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import OnBoarding from '../../pages/onboarding/OnBoarding'
import useOnboarding from '../../hooks/useOnboarding'

const Index = () => {
	const { isModalOpen, handleOnboardingComplete, toggleModal, setIsModalOpen } =
		useOnboarding()
	const [qrCodes, setQrCodes] = React.useState([]) // State to pass qrCodes to control modal visibility

	// Effect to close modal if QR codes exist
	useEffect(() => {
		if (qrCodes.length > 0 && isModalOpen) {
			setIsModalOpen(false)
		}
	}, [qrCodes, isModalOpen, setIsModalOpen])

	return (
		<div className="w-full d-flex ">
			<div className="mt-10 w-[280px] me-lg-5">
				<Sidebar />
			</div>
			<div className="customDashboardSize">
				{/* Pass qrCodes as a prop from QrCodes component */}
				<QrCodes setQrCodes={setQrCodes} />
			</div>
			<div className="onboarding-styles">
				{/* Conditionally render modal based on QR codes length */}
				<Modal
					isOpen={qrCodes.length === 0 && isModalOpen}
					toggle={toggleModal}
					className="custom-modal"
				>
					<ModalBody>
						<OnBoarding onComplete={handleOnboardingComplete} />
					</ModalBody>
				</Modal>
			</div>
		</div>
	)
}

export default Index

import { useContext, useState } from 'react'
import Layout1 from '../create-page/layout1'
import Layout2 from '../create-page/layout2'
import Layout3 from '../create-page/layout3'
import Layout4 from '../create-page/layout4'
import Layout0 from '../create-page/layout0'
import { LinkInBioContxt } from '../linkInBioContxt'

const Index = () => {
	const { bioPageForm } = useContext(LinkInBioContxt)
	const { watch } = bioPageForm
	const values = watch()
	const { template } = values
	const [isMobile, setIsMobile] = useState(true)

	const templatesMapper = {
		0: <Layout0 values={values} />,
		1: <Layout1 values={values} />,
		2: <Layout2 values={values} />,
		3: <Layout3 values={values} />,
		4: <Layout4 values={values} />,
	}
	console.log(template, 'bkjjg')
	return (
		<div
			className="rounded-1 h-fit-content pt-5 position-sticky mobile-hide"
			style={{
				border: '1px solid #e9e6f1',
				top: '50px',
				backgroundColor: '#F7F5FD',
			}}
		>
			<div className="d-flex justify-content-center pb-5">
				<button
					id="mobileview"
					type="button"
					onClick={() => setIsMobile(true)}
					className="btn btn-icon bg-white border rounded-1 me-4"
				>
					<i class="fa-solid fa-mobile-screen fs-2"></i>
				</button>
				<button
					id="desktopview"
					type="button"
					onClick={() => setIsMobile(false)}
					className="btn btn-icon bg-white border rounded-1"
				>
					<i className="fa fa-desktop fs-2"></i>
				</button>
			</div>
			<div className={!isMobile ? 'container' : ''}>
				<div className={isMobile ? 'mobile overflow-auto' : `device desktop`}>
					{templatesMapper[template]}
				</div>
			</div>
		</div>
	)
}

export default Index
